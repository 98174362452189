<template>
  <div class="n-pre">
    <div v-for="(item, index) in list" :key="index" class="break-all min-h-[22px]" :title="item">{{ item }}</div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  value: String,
});

const list = computed(() => props.value?.replace(/\r\n/g, '\n').split('\n'));
</script>
