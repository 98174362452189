export default {
  ADMIN_DUP_USERNAME: '该用户名已经存在',
  DOB_IS_BEFORE_TODAY: '出生日期必须在当前日期之前',
  BANK_CARD_DUP_NUMBER: '该银行卡卡号已经存在',
  PERMISSION_DENY_ADMIN_ONLY: '只有系统管理员才可以执行该操作',
  PERMISSION_DENY_ADMIN_OR_IT_ONLY: '只有系统管理员或者机构管理员才可以执行该操作',
  // 5.1 基础相关
  INVALID_ARGUMENT_BAD_AGENT: '账户信息错误[0010]',
  INVALID_ARGUMENT_MIN_DEPOSIT: '必须超过最小充值限额：{minDeposit}美元（或等值货币金额）',
  INVALID_ARGUMENT_MAX_DEPOSIT: '不能超过最大充值限额：{maxDeposit}美元（或等值货币金额）',
  INVALID_ARGUMENT_MIN_WITHDRAWAL: '必须超过最小取款限额：{minWithdrawal}美元（或等值货币金额）',
  INVALID_ARGUMENT_MAX_WITHDRAWAL: '不能超过最大取款限额：{maxWithdrawal}美元（或等值货币金额）',
  INVALID_ARGUMENT_MORE_THAN_2_SCALES: '小数位不能超过两位',
  INVALID_ARGUMENT_RESOURCE_SAVE: '资源保存参数错误',
  INVALID_ARGUMENT_NULL_PASSWORD: '密码不能为空',
  INVALID_ARGUMENT_CONFIRM_PASSWORD: '确认密码不匹配',
  INVALID_ARGUMENT_FORMAT_PASSWORD: '必须至少包含：一个大写字母、一个小写字母、一个数字，并且密码长度为8~32位字符',
  INVALID_ARGUMENT_PASSWORD_VER_MISMATCH: '密码版本不匹配',
  INVALID_ARGUMENT_MISSING_PASSWORD_VER_CLAIM: '缺少密码版本声明',
  INVALID_ARGUMENT_CAN_NOT_DELETE_FOR_VERIFIED: '已认证数据不能删除',
  INVALID_ARGUMENT_ENTER_BANK_NUM: '请输入银行卡号',
  INVALID_ARGUMENT_IT_DUP_ID: '该机构ID已经存在',
  INVALID_ARGUMENT_IT_DUP_APP_KEY: '用户名已经被使用，请更换用户名',
  INVALID_REGISTER: '禁止注册',
  INVALID_DATA_USED_CANNOT_BE_DELETED: '已使用的数据不能删除',
  INVALID_ARGUMENT_CONFIG_BANK_NOT_NULL: 'BANK信息不可为空,请去银行卡管理页面补充BANK信息',
  // 5.2 货币相关
  INVALID_ARGUMENT_CURRENCY_BASE_QUOTE_CAN_NOT_BE_THE_SAME: '两个货币不能相同',
  INVALID_ARGUMENT_FX_RATE_CAN_BE_NULL: '汇率不能为空',
  INVALID_ARGUMENT_FX_RATE_MUST_BE_POSITIVE: '汇率不能为零或者负数',
  INVALID_ARGUMENT_CURRENCY_DUP_CURRENCY_PAIR: '相同的货币对已经存在',
  INVALID_ARGUMENT_CURRENCY_AMOUNT_NOT_MATCH: '汇率转化金额有误',
  INVALID_ARGUMENT_CURRENCY_FX_RATE_NOT_FOUND: '未找到货币对所对应的汇率',
  INVALID_ARGUMENT_CURRENCY_FX_RATE_TEMPLATE_NOT_FOUND: '未找到汇率模板',
  INVALID_ARGUMENT_CURRENCY_INVALID_FOR_THIS_BANK: '当前银行不支持此货币',
  INVALID_ARGUMENT_CURRENCY_INVALID_FOR_THIS_RECEIVE_METHOD: '当前收款方式不支持此货币',
  // 5.3 用户相关
  INVALID_ARGUMENT_LOGIN_AUTH_BY_GOOGLE: '邮箱已经被GOOGLE授权使用，请用GOOGLE授权登录',
  INVALID_ARGUMENT_LOGIN_AUTH_BY_FACEBOOK: '邮箱已经被FACEBOOK授权使用，请用FACEBOOK授权登录',
  INVALID_ARGUMENT_LOGIN_AUTH_BY_APPLE: '邮箱已经被APPLE授权使用，请用APPLE授权登录',
  INVALID_ARGUMENT_LOGIN_AUTH_BY_PASSWORD: '邮箱使用密码方式授权使用，请用密码方式登录',
  INVALID_ARGUMENT_CUSTOMER_FORMAT: '用户名必须为邮箱地址',
  INVALID_ARGUMENT_CUSTOMER_DUP_USERNAME: '用户名已激活',
  INVALID_ARGUMENT_CUSTOMER_DUP_MOBILE: '手机号已存在',
  INVALID_ARGUMENT_CUSTOMER_NOT_VERIFIED: '客户信息未认证',
  INVALID_ARGUMENT_CUSTOMER_AUTH_NOT_EXIST: '客户认证信息不存在',
  INVALID_ARGUMENT_CUSTOMER_NOT_EXIST: '客户信息不存在',
  INVALID_ARGUMENT_EMAIL_CODE_NOT_EXIST: '链接不存在',
  INVALID_ARGUMENT_EMAIL_CODE_REPEAT: '链接已经失效',
  INVALID_ARGUMENT_EMAIL_CODE_TIMEOUT: '链接已经过期',
  INVALID_ARGUMENT_CUSTOMER_ACCOUNT_NOT_ACTIVATED: '客户交易账号未激活',
  INVALID_ARGUMENT_CUSTOMER_ID_EXPIRE_LIMIT: '身份信息过期日必须至少剩余{days}天',
  INVALID_ARGUMENT_CHANGE_AGENT_CAN_NOT_CHANGE_IT: '只能更换为同一机构下的不同代理',
  INVALID_ARGUMENT_CHANGE_AGENT_SAME_AGENT: '更换前与更换后的代理相同',
  INVALID_ARGUMENT_CHANGE_AGENT_HAS_POSITION_OR_ACTIVE_ORDER: '当前账户存在持仓或挂单，不能更换代理',
  // 5.4 业务流程相关
  INVALID_ARGUMENT_UNABLE_TO_LOCK_WITHDRAWAL_AMOUNT: '无法锁定额度，请刷新后再试',
  INVALID_ARGUMENT_PROCESS_SUBMIT_ILLEGAL_STATUS: '状态已经是提交',
  INVALID_ARGUMENT_PROCESS_RETRACT_ILLEGAL_STATUS: '不能被撤回',
  INVALID_ARGUMENT_PROCESS_APPROVAL_ILLEGAL_STATUS:
    '审核状态错误：审核状态为提交时才允许驳回或通过操作（数据可能已发生变化，请重新检索数据后再尝试）',
  INVALID_ARGUMENT_PROCESS_APPROVAL_ILLEGAL_PROCESSED:
    '处理状态错误：处理状态为未处理时才允许驳回或通过操作（数据可能已发生变化，请重新检索数据后再尝试）',
  INVALID_ARGUMENT_PROCESS_APPROVED_FLOW_ILLEGAL_STATUS:
    '审核状态错误：审核状态为通过时才允许完成处理操作（数据可能已发生变化，请重新检索数据后再尝试）',
  INVALID_ARGUMENT_PROCESS_APPROVED_FLOW_ILLEGAL_PROCESSED:
    '处理状态错误：处理状态为未处理时才允许完成处理操作（数据可能已发生变化，请重新检索数据后再尝试）',
  INVALID_ARGUMENT_PROCESS_REJECT_FLOW_ILLEGAL_STATUS:
    '审核状态错误：审核状态为驳回时才允许完成处理操作（数据可能已发生变化，请重新检索数据后再尝试）',
  INVALID_ARGUMENT_PROCESS_ILLEGAL_STATUS: '状态错误',
  // 5.5 KRAMPUS API相关
  INVALID_ARGUMENT_KRAMPUS_API_DUP_LOGIN: '交易系统用户名已存在',
  INVALID_ARGUMENT_KRAMPUS_API_WRONG_KEY_OR_SECRET: '交易系统用户名/密码错误',
  INVALID_ARGUMENT_KRAMPUS_API_LOGIN_FAILED: '交易系统登录失败',
  INVALID_ARGUMENT_DEMO_KRAMPUS_API_DUP_LOGIN: '模拟交易系统用户名已存在',
  INVALID_ARGUMENT_DEMO_KRAMPUS_API_WRONG_KEY_OR_SECRET: '模拟交易系统用户名/密码错误',
  INVALID_ARGUMENT_DEMO_KRAMPUS_API_LOGIN_FAILED: '模拟交易系统登录失败',
  INVALID_ARGUMENT_KRAMPUS_API_SYNC_IT_FAILED: '同步机构信息失败，未找到交易系统ID为{itId}的机构，请查看权限',
  INVALID_ARGUMENT_KRAMPUS_API_INVOKE_ERROR: '交易系统调用失败',
  INVALID_ARGUMENT_KRAMPUS_API_INSUFFICIENT_FUNDS: '账户余额不足',
  INVALID_ARGUMENT_KRAMPUS_API_PASSWORD_IS_EMPTY: '密码为空，请先设置密码',
  INVALID_ARGUMENT_KRAMPUS_API_TOKEN_FAILED: '登录过期，请重新登录',
  // token相关
  INVALID_ARGUMENT_INVALID_ISSUER_FORMAT: 'Issuer格式无效',
  INVALID_ARGUMENT_MISSING_LOGIN_CODE: '缺少登录代码',
  INVALID_ARGUMENT_CUSTOMER_NOT_FOUND: '找不到客户',
  INVALID_ARGUMENT_MISSING_PASSWORD_TIME_CLAIM: '缺少密码时间声明',
  INVALID_ARGUMENT_PASSWORD_TIME_MISMATCH: '密码时间不匹配',
  INVALID_ARGUMENT_TOKEN_EXPIRED: '令牌已过期',
  // 5.6 METHOD 相关
  INVALID_ARGUMENT_METHOD_BANK_USDT_INVALID: '银行的充值货币不能是USDT',
  INVALID_ARGUMENT_METHOD_BANK_BANK_CAN_NOT_NULL: '请选择银行',
  INVALID_ARGUMENT_METHOD_BANK_ACCOUNT_NAME_CAN_NOT_EMPTY: '银行帐号名称不可以为空',
  INVALID_ARGUMENT_METHOD_BANK_ACCOUNT_NUMBER_CAN_NOT_EMPTY: '银行帐号号码不可以为空',
  INVALID_ARGUMENT_METHOD_USDT_USDT_ONLY: 'USDT的充值货币必须是USDT',
  INVALID_ARGUMENT_METHOD_USDT_AT_LEAST_ONE_IS_REQUIRED: '地址和二维码至少需要填写一个',
  INVALID_ARGUMENT_METHOD_USDT_CRYPTO_NETWORK_REQUIRED: 'USDT的充值网络必须填写',
  INVALID_ARGUMENT_METHOD_WECHAT_CNY_ONLY: '微信的充值货币必须是人民币',
  INVALID_ARGUMENT_METHOD_REQUIRE_QR_CODE: '当前充值方式必须上传二维码',
  // 5.7 入出金相关
  INVALID_ARGUMENT_IT_TRANSFER_CONFIG_ERROR: '通道配置错误',
  INVALID_ARGUMENT_IT_TRANSFER_CAN_NOT_BE_NULL: '机构转账不可为空',
  INVALID_ARGUMENT_IT_TRANSFER_AMOUNT_CAN_NOT_BE_NULL: '机构转账金额不可为空',
  INVALID_ARGUMENT_IT_TRANSFER_RECEIVE_CAN_NOT_BE_NULL: '机构转账帐号不可为空',
  INVALID_ARGUMENT_DEPOSIT_AMOUNT_TRANSFER_AMOUNT_NOT_MATCH: '存入金额与转账金额不匹配',
  INVALID_ARGUMENT_PAYMENT_SAME_TYPE_PAYMENT_CHANNEL_ONLY_ONE: '相同类型的支付通道只能选择一个',
  INVALID_ARGUMENT_DUPLICATE_PAYMENT_CHANNEL_TYPE: '相同类型的支付网关只能选择一个',
  INVALID_ARGUMENT_PAYMENT_INVOKE_ERROR: '调用支付API接口失败',
  INVALID_ARGUMENT_PAYMENT_INVOKE_PARAM_ERROR: '调用支付参数错误',
  INVALID_ARGUMENT_TRANSACTION_AMOUNT: '入出金不满足限定额度',
  INVALID_ARGUMENT_CLOSE_ACCOUNT_ERROR: '注销账户失败，必须清空持仓并且资金为零',
  INVALID_ARGUMENT_CONFIG_PIX_NOT_NULL: 'PIX信息不能为空，请到银行卡管理页面填写您的PIX信息。',
  INVALID_ARGUMENT_CONFIG_IFSC_NOT_NULL: 'IFSC信息不能为空，请到银行卡管理页面填写您的IFSC信息。',
  // 6. 操作校验
  UNSUPPORTED_OPERATION_NO_IT_RECEIVE_FOUND: '该机构不支持该货币的入金',
  // 7. 邮箱验证
  THIRD_PARTY_AUTH_USER_CANNOT_RESET_PASSWORD: '三方认证账号无法重置密码',
  INVALID_THIRD_PARTY_ACCOUNT_UNBINDING_FAILED: '三方账号解除绑定失败',
  THIRD_PARTY_AUTH_USER_CANNOT_ACTIVE_EMAIL: '三方认证账号无需激活账户',
  INVALID_ACCOUNT_ALREADY_EXISTS_AND_LOCKED: '该账户已存在且被锁定',
  INVALID_EMAIL_CHECK_INVALID_USER: '无效用户',
  INVALID_EMAIL_CHECK_USER_NOT_EXIST: '用户不存在',
  INVALID_EMAIL_CHECK_EMAIL_VERIFIED: '邮箱已经验证',
  INVALID_EMAIL_CHECK_EMAIL_NOT_VERIFIED: '邮箱未经验证',
  INVALID_EMAIL_CHECK_INVALID_REQUEST_PARAMETERS: '操作失败,请求超时',
  INVALID_EMAIL_CHECK_REPEATED_REQUEST: '重复请求,请稍后尝试',
  INVALID_ARGUMENT_INVALID_PROVIDERTYPE: '无效的三方登陆类型',
  INVALID_ARGUMENT_ORIGINAL_PASSWORD_MISMATCH: '修改密码,原始密码不匹配',
  INVALID_ARGUMENT_NEW_PASSWORD_SAME_AS_OLD: '新密码不能和旧密码一样',
  INVALID_ARGUMENT_EXCLUSIVE_AGENT_LINK: '无法注册，该链接属于专属代理,禁止多次注册',
  // 注册
  REGISTER_DUP_USERNAME: '该用户名已被注册',
  REGISTER_DUP_MOBILE: '该手机号已被注册',
  USERNAME_WRONG_FORMAT: '用户名格式错误',
  MOBILE_WRONG_FORMAT: '手机号格式错误',
  ID_CARD_ID_WRONG_FORMAT: '身份证号码格式错误',

  // 登录
  LOGIN_FAILED: '登录失败，用户名或密码有误',
  LOGIN_NOT_ENABLED: '帐号已被禁用',
  LOGIN_LOCKED: '帐号已被锁定',
  // 密码
  PASSWORD_WRONG: '密码输入错误',
  PASSWORD_WRONG_FORMAT: '密码必须为6~16位字符，并且不能包含空格',
  PASSWORD_WRONG_TIME_LEFT: '密码输入错误，还剩{timeLeft}次机会',
  PASSWORD_CONFIRM_NOT_MATCH: '确认密码不匹配',
  PASSWORD_WRONG_AND_LOCK: '连续{failedAttempt}次密码输入错误，帐号被锁定',
  PASSWORD_NULL: '密码不能为空',
  CONFIRM_PASSWORD_NOT_MATCH: '确认密码不匹配',
  // 权限
  PERMISSION_DENY: '你没有访问权限',
  // Jwt Token
  JWT_INVALID_TOKEN: '登录过期，请重新登录',
  JWT_VERSION_NOT_MATCHED: '版本不匹配',
  // 手机验证码
  SMS_VERIFY_CODE_NOT_MATCH: '验证码不匹配',
  CAPTCHA_VERIFY_CODE_NOT_MATCH: '验证码不匹配',
  // 参数校验

  INVALID_ARGUMENT: '参数错误',
  INVALID_ARGUMENT_NULL_POINT: '参数错误：参数不能为空',
  // 其他
  DATA_NOT_FOUND: '数据未找到',
  STALE_VERSION: '数据改变，请刷新再试',
  INVALID_STATE: '无效状态，数据可能已发生改变',
  INVALID_VALUE: '参数内容错误',
  INVALID_FORMAT: '参数格式错误',
  INTERNAL_ERROR: '内部错误，请联系管理员',
  MULTI_ARGS_TEST: 'arg1 {arg1}，arg2 {arg2}，arg3 {arg3}',
  INVALID_ARGUMENT_NOT_BLANK: '不能是空白字符',

  // 第三方
  access_denied: '访问拒绝',
  invalid_token_response: '无效的令牌',
  authorization_request_not_found: '未找到认证请求',
};
