import { NMessage } from '@/components';
import { mySentry } from '@/monitor';

// assert
export function stringErrorHandler(err, vm, info) {
  if (typeof err !== 'string') return true;

  console.error(err, vm, info);
  mySentry?.captureErrorMessage(err);

  NMessage.error({ content: err } ?? { content: 'Unknown Error' });
  return false;
}
