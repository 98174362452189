export default {
  profile: 'Profile',
  customer: 'Customer',
  totalPeopleQuantity: 'Total: {quantity} people',
  verifiedPeopleQuantity: 'Verified: {quantity} people',
  enabledPeopleQuantity: 'Enabled: {quantity} people',
  unlockedPeopleQuantity: 'Unlocked: {quantity} people',
  unverifiedPeopleQuantity: 'Unverified: {quantity} people',
  unenabledPeopleQuantity: 'Unenabled: {quantity} people',
  lockedPeopleQuantity: 'Locked: {quantity} people',
  verify: 'Verify',
  identityVerify: 'Identity Verify',
  withdrawalVerify: 'Withdrawal Verify',
  pendingApproval: 'Pending Approval',
  pendingProcess: 'Pending Process',
  processed: 'Processed',
};
