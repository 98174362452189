<template>
  <AConfigProvider>
    <slot />
  </AConfigProvider>
</template>

<script setup>
import { ConfigProvider as AConfigProvider } from 'ant-design-vue/es';
</script>

<script>
export default {
  name: 'NConfigProvider',
};
</script>
