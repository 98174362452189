export * from './ant-design-vue';
export * from './base';
export * from './utils';

import * as Antd from './ant-design-vue';
import * as Base from './base';
import * as Common from './common';
import * as Template from './template';

const components = {
  ...Antd,
  ...Base,
  ...Common,
  ...Template,
};

function install(app) {
  Object.entries(components).forEach(([name, comp]) => {
    app.component(name, comp);
  });
}

export default { install };
