export default {
  ApprovalStatus: {
    SUBMIT: 'Submit',
    RETRACT: 'Retract',
    APPROVED: 'Approved',
    REJECT: 'Reject',
  },
  ConfigKey: {
    DEFAULT_AVATAR_PATH: 'Default Avatar Path',
    KRAMPUS_URL: 'Krampus URL',
    ADMIN_HOST: 'Admin Host',
    MIN_DEPOSIT: 'Min Deposit (USD)',
    MIN_WITHDRAWAL: 'Min Withdrawal (USD)',
    UPLOAD_PATH: 'Upload Path',
    FILE_PATH: 'File Path',
    REGISTER_URL_BASE: 'Register URL Base',
    JWT_VERSION_FRONT: 'JWT Version Front',
    JWT_VERSION_ADMIN: 'JWT Version Admin',
    MAX_DEPOSIT: 'Max Deposit Amount (USD)',
    MAX_WITHDRAWAL: 'Max Withdrawal Amount (USD)',
    SENTRY_ENABLED: 'Sentry Enabled',
    SENTRY_URL: 'Sentry URL',
  },
  Currency: {
    USDT: 'USDT',
    BRL: 'BRL',
    INR: 'INR',
    USD: 'USD',
    EUR: 'EUR',
    HKD: 'HKD',
  },
  CustomerField: {
    NAME: 'Name',
    MOBILE: 'Mobile',
    SEX: 'Sex',
    DOB: 'Date of Birth',
    DISTRICT: 'District',
  },
  FlowChartType: {
    VERIFIED: 'Verify Flow',
    DEPOSIT: 'Deposit Flow',
    WITHDRAWAL: 'Withdrawal Flow',
  },
  FlowInfoType: {
    VERIFIED: 'Verified',
    DEPOSIT: 'Deposit',
    WITHDRAWAL: 'Withdrawal',
  },
  IdentificationType: {
    ID_CARD: 'ID Card',
    PASSPORT: 'Passport',
    DRIVER_LICENSE: 'Driver License',
    SOCIAL_INSURANCE_CARD: 'Social Insurance Card',
    OTHER: 'Other',
  },
  ReceiveMethod: {
    BANK: 'Bank',
    USDT: 'USDT',
    NET_PAY: 'Net pay',
  },
  ResourceType: {
    ID: 'ID',
    CUSTOMER_AVATAR: 'Customer Avatar',
    IT_RECEIVE_AVATAR: 'IT Receive Avatar',
    PROCESS_FLOW: 'Process Flow',
    TRANSFER_PROOF: 'Transfer Proof',
  },
  Sex: {
    MALE: 'Male',
    FEMALE: 'Female',
    SECRET: 'Secret',
  },
  TransferTransactionStatus: {
    TRANSFERRING: 'Transferring',
    TRANSFERRED: 'Transferred',
    CONFIRMED: 'Confirmed',
    CANCELLED: 'Cancelled',
    FORCE_CONFIRMED: 'Confirmed (Force)',
    FORCE_CANCELLED: 'Cancelled (Force)',
    FAILED: 'Failed',
  },
  TransferRole: {
    CUSTOMER: 'Customer',
    IT: 'IT',
  },
  DurationType: {
    MINUTES: 'Minutes',
    HOURS: 'Hours',
  },
  Lang: {
    EN: 'English',
    ZH_CN: 'Chinese (Simplified)',
    ZH_HK: 'Chinese (Traditional)',
    JA: 'Japanese',
    KO: 'Korean',
    PT: 'Portuguese',
  },
  CryptoNetwork: {
    TRC20: 'TRC20',
    ERC20: 'ERC20',
  },
  RoleType: {
    SUPER_USER: 'Super User',
    ROLE_IT: 'Institution',
    ROLE_AGENT: 'Agent',
  },
  Country: {
    SOUTH_KOREA: 'South Korea',
    JAPAN: 'Japan',
    BRAZIL: 'Brazil',
    INDIA: 'India',
    TAIWAN: 'Taiwan',
  },
  QMaxInvestmentOption: {
    HKD_300000: '$300,000',
    HKD_100: '$100',
  },
  QTriggerOption: {
    AT_LATEST_PRICE: 'My position has been closed at the latest available price',
    STOP_LOSS_NOT_TRIGGERED: 'Stop loss cannot close my position',
  },
  AutoCloseOption: {
    INSUFFICIENT_CAPITAL:
      'The market is developing in a direction that is unfavorable for my position, and my account does not have enough capital to maintain my position',
    FAVORABLE_DIRECTION: 'The market is turning to my advantageous position',
  },
  QTradingCountOption: {
    COUNT_100: '51 to 100 transactions',
    COUNT_50: '20 to 50 transactions',
    COUNT_LESS_THAN_20: 'Less than 20 transactions',
    COUNT_NONE: 'Never traded',
  },
  QOtcLeverageOption: {
    BETWEEN_1_50_AND_1_100: 'Never traded between 1:50 and 1:100',
    BETWEEN_1_25_AND_1_50: 'Between 1:25 and 1:50',
    BETWEEN_1_2_AND_1_25: 'Between 1:2 and 1:25',
    NO_LEVERAGE: 'Not using leverage（1:1）',
  },
  QFinanceEducationOption: {
    WORKED_IN_FINANCIAL: 'Previously worked in the financial services industry',
    PROFESSIONAL: 'Professional qualifications or higher education',
  },
  QOccupationOption: {
    EMPLOYEE: 'Employee',
    SELF_EMPLOYED: 'Self employed',
    RETIRED: 'Retired',
    STUDENT: 'Student',
    PROPERTY: 'Property',
  },
  QIndustryOption: {
    FINANCIAL_SERVICES: 'Financial Services Industry',
    INFORMATION_TECHNOLOGY: 'Information technology industry',
    HEALTHCARE: 'Healthcare industry',
    RETAIL_AND_CONSUMER_GOODS: 'Retail and Consumer Goods Industry',
    MANUFACTURING: 'Manufacturing industry',
    MEDIA_AND_ENTERTAINMENT: 'Media and entertainment industry',
    CONSTRUCTION_AND_REAL_ESTATE: 'The construction and real estate industry',
    ENERGY_AND_UTILITIES: 'Energy and Utilities Industry',
    CONSULTING_AND_PROFESSIONAL_SERVICES: 'Consulting and Professional Services Industry',
    OTHER: 'Other',
  },
  QFinancialAmountOption: {
    FIVE_M_PLUS: '$5,000,000+',
    FIVE_M: '$2,000,000 - 5,000,000',
    TWO_M: '$1,000,000 - 2,000,000',
    ONE_M: '$500,000 - 1,000,000',
    FIVE_HUNDRED_K: '$200,000 - 500,000',
    TWO_HUNDRED_K: '$50,000 - 200,000',
    FIFTY_K: '$0 - 50,000',
  },
  QIncomeSourceOption: {
    EMPLOYMENT_INCOME: 'Income from working or sole employment',
    PENSION_OR_GOVERNMENT_BENEFITS: 'Pension or government benefits',
    INVESTMENT_INCOME: 'Income from investment',
    INHERITANCE: 'Inheritance',
    PROPERTY_SALE: 'Property sale',
    BUSINESS_SALE: 'Business sale',
  },
  QTradingFrequencyOption: {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
  },
  QDepositMethodOption: {
    CREDIT_CARD: 'Credit Card',
    BANK_TRANSFER: 'Bank Transfer',
  },
  PaymentGateway: {
    PPAY: 'PPAY',
    CASH_PAY: 'CASHPAY',
    UAT_EPAY: 'UAT EPAY',
    PRO_EPAY: 'PRO EPAY',
    BETCAT_PAY: 'BETCAT PAY',
    UAT_BETCAT_PAY: 'UAT BETCAT PAY',
  },
  PayType: {
    RECEIVE: 'RECEIVE',
    PAYMENT: 'PAYMENT',
  },
  TransactionModel: {
    C2C: 'C2C',
    B2C: 'B2C',
    B2B: 'B2B',
    C2B: 'C2B',
  },
  TransactionCategory: {
    BANK: 'Bank',
    TRANSFER: 'Transfer',
    IFSC: 'India Payments-IFSC',
    PIX: 'Brazil Payment-PIX',
  },
  FeeModel: {
    FIXED: 'FIXED',
    PERCENTAGE: 'PERCENTAGE',
  },
  RateMode: {
    USD_TO_TARGET: 'US Dollar To Other Currency',
    TARGET_TO_USD: 'Other Currency To US Dollar',
  },
  PaymentOrderStatus: {
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    CANCELED: 'CANCELED',
    PROCESSING: 'PROCESSING',
  },
  DepositPaymentOrderStatus: {
    FAILED: 'DEPOSIT FAILED',
    SUCCESS: 'DEPOSIT SUCCESSFUL',
    PENDING: 'PENDING',
    CANCELED: 'CANCELED',
    PROCESSING: 'DEPOSIT PROCESSING',
  },
  WithdrawPaymentOrderStatus: {
    FAILED: 'WITHDRAW FAILED',
    SUCCESS: 'WITHDRAW SUCCESSFUL',
    PENDING: 'PENDING',
    CANCELED: 'CANCELED',
    PROCESSING: 'DEPOSIT PROCESSING',
  },
  PaySystemOrderStatus: {
    GATEWAY_CREATE: 'GATEWAY_CREATE',
    CONFIRM: 'CONFIRM',
    CANCEL: 'CANCEL',
    FAIL: 'FAIL',
    SUCCESS: 'SUCCESS',
    CLOSE: 'CLOSE',
    FREEZE: 'FREEZE',
    WITHDRAWN: 'WITHDRAWN',
    NO_REFUND_OF_HANDLING_FEE: 'NO_REFUND_OF_HANDLING_FEE',
  },
  ProviderType: {
    GOOGLE: 'Google',
    FACEBOOK: 'Facebook',
    APPLE: 'Apple',
    PASSWORD: 'Password',
  },
  PaymentChannel: {
    INDIA_UPI_1: 'PPAY-INDIA_UPI_1',
    INDIA_NATIVE_1: 'PPAY-INDIA_NATIVE_1',
    INDIA_UPI_2: 'PPAY-INDIA_UPI_2',
    INDIA_NATIVE_2: 'PPAY-INDIA_NATIVE_2',
    BRAZIL_PIX_1: 'PPAY-BRAZIL_PIX_1',
    BRAZIL_PIX_2: 'PPAY-BRAZIL_PIX_2',
    BRAZIL_PIX_FAST: 'PPAY-BRAZIL_PIX_FAST',
    EPAY_BRAZIL_TS_B2C: 'EPAY-BRAZIL-TS-B2C',
    EPAY_BRAZIL_TS_B2B: 'EPAY-BRAZIL-TS-B2B',
    EPAY_INDIA_IY_B2C: 'EPAY-INDIA-IY',
    NONE: 'NONE',
  },
  BankInfoExtType: {
    NORMAL: 'NORMAL',
    PIX_EXT: 'PIX_EXT',
    IFSC_EXT: 'IFSC_EXT',
    BANK_EXT: 'BANK_EXT',
  },
  PixType: {
    CPF: 'Personal Identification Number (PIN)',
    PHONE: 'Mobile Phone Number',
    EMAIL: 'Email Address',
    EVP: 'Virtual Payment Address',
  },
  EpayCountryCode: {
    BR: 'Brazil',
  },
  EpayGender: {
    MALE: 'Male',
    FEMALE: 'Female',
  },
  EpayIdType: {
    ID_CARD: 'ID card',
    PASSPORT: 'Passport',
  },
  EpayPurposeOfRemittance: {
    CorporatePayment: 'Corporate Payment',
    Marketplace: 'Market Transaction',
    ProfessionalServices: 'Professional Services',
    Payroll: 'Salary payment',
    ExpensesReimbursement: 'Expenses Reimbursement',
    RepresentationExpenses: 'Business Payment',
    HiredPersonnel: 'Hired person',
    TraineesScholarships: 'Trainees/Scholarships',
    ProductivityOrQualityPrizes: 'Productivity or Quality Awards',
    LayOffPayment: 'Layoff payment',
    FamilySupport: 'Family Support',
    Education: 'Education',
    GiftAndOtherDonations: 'Gifts and other donations',
    MedicalTreatment: 'Medical Treatment',
    MaintenanceOrOtherExpenses: 'Maintenance or other expenses',
    CarDriver: 'Driver',
    SoftwareLicense: 'Software License',
    HotelAccommodation: 'Hotel Accommodation',
    AdvertisingAndOrPublicRelationsRelatedExpenses: 'Advertising and/or public relations related expenses',
    FeesForAdvisoryOrConsultingService: 'Consulting or consulting service fees',
    BusinessRelatedInsurancePayment: 'Business related insurance payment',
    InsuranceClaimsPayment: 'Insurance Claims Payment',
  },
  Gender: {
    MALE: 'Male',
    FEMALE: 'Female',
    UNKNOWN: 'Unknown',
    NOT_APPLICABLE: 'Not_Applicable',
  },
  BeneficiaryRelationShip: {
    BOYFRIEND: 'Boyfriend',
    BUSINESS_PARTNER: 'Business Partner',
    CHILDREN: 'Children',
    COLLEAGUE: 'Colleague',
    EX_SPOUSE: 'Ex-spouse',
    FIANCE: 'Fiance',
    FIANCEES: 'Fiancees',
    FRIEND: 'Friend',
    GIRLFRIEND: 'Girlfriend',
    GRAND_PARENTS: 'Grand Parents',
    PARENTS: 'Parents',
    RELATIVE_UNCLE_AUNTIE_COUSIN: 'Relative/uncle/auntie/cousin',
    SELF: 'Self',
    SIBLING_BROTHER_SISTER: 'Sibling/brother/sister',
    SPOUSE: 'Spouse',
    SUBSIDIARY_COMPANY: 'Subsidiary company',
  },
  PayPurpose: {
    DEFAULT: 'Default',
    ENTRY_FEE: 'Entry Fee',
    SERVICE_FEE: 'Service Fee',
    OTHER: 'Other',
  },
};
