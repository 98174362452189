import { isEmpty } from 'lodash-es';
import { mySentry } from '@/monitor';
import enums from '@/i18n/cn/enums';
import errorCode from '@/i18n/cn/errorCode';

const localEnums = { ...enums, ErrorCode: {} };
for (const key in errorCode) {
  localEnums.ErrorCode[key] = errorCode[key];
}

// 只是admin做这个check
export function checkEnums(serverEnums) {
  const local = {};

  // local missing
  for (const enumName in serverEnums) {
    if (['ConfigKey'].includes(enumName)) continue;

    const localEnum = localEnums[enumName];
    const serverEnum = serverEnums[enumName];

    if (!localEnum) {
      local[enumName] = '-';
      continue;
    }

    for (const serverEnumItem of serverEnum) {
      const serverKey = serverEnumItem.name;
      if (!localEnum[serverKey]) {
        local[enumName] = local[enumName] || {};
        local[enumName][serverKey] = '-';
      }
    }
  }

  // local remain
  for (const enumName in localEnums) {
    const localEnum = localEnums[enumName];
    const serverEnum = serverEnums[enumName];

    if (!serverEnum) {
      local[enumName] = '+';
      continue;
    }

    for (const enumItemKey in localEnum) {
      if (!serverEnum.find(o => o.name === enumItemKey)) {
        local[enumName] = local[enumName] || {};
        local[enumName][enumItemKey] = '+';
      }
    }
  }

  if (!isEmpty(local)) {
    console.error(`Enum Not Match, local = `, { local });
    mySentry?.captureErrorMessage('Enum Not Match', { extra: { local, desc: '+表示 增加; -表示 缺少' } });
  } else {
    console.info('%c枚举校验成功', 'color: green;');
  }
}
