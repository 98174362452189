import menus from '@admin/menus';
import { useRouter } from '@/hooks';
import { storage } from '@/services';
import dashboard from '@admin/menus/dashboard';
import { ref, computed, toRaw, watch } from 'vue';
import { useBootstrap, useMenus } from '@admin/hooks';
import { isEmpty, last, drop, dropRight, findIndex } from 'lodash-es';

const tabsVisible = ref();

const curTabs = ref();

const curTab = ref();

const curTabLimit = ref();

export const useTabs = () => {
  const { userProfile } = useBootstrap();

  const TABS_KEY = `tabs_${userProfile.value.id}`;

  tabsVisible.value = storage.get(TABS_KEY)?.tabsVisible ?? false;

  let sTabs = storage.get(TABS_KEY)?.curTabs ?? [];

  curTabs.value = isEmpty(sTabs) ? [dashboard] : [...sTabs];

  curTab.value = storage.get(TABS_KEY)?.curTab ?? curTabs.value?.[0] ?? dashboard;

  curTabLimit.value = storage.get(TABS_KEY)?.curTabLimit ?? 8;

  const { flatMenus } = useMenus(menus);

  const { router, routerTo } = useRouter();

  const redundant = computed(() => curTabs.value?.length - curTabLimit.value);

  const setTabsVisible = visible => {
    tabsVisible.value = visible;
    const currentRoute = router.currentRoute.value;
    const cacheName = currentRoute.meta?.cacheName;
    if (Array.isArray(cacheName)) {
      const parent = flatMenus.value?.find(o => o.meta.cacheName === cacheName[0]);
      setTab(parent.name, currentRoute.fullPath);
    } else setTab(currentRoute.name);
  };

  const setTabs = tabs => {
    curTabs.value = [...tabs];
    save();
  };

  const setTab = (name, path) => {
    let tab = curTabs.value?.find(o => o.name === name);
    if (!tab) {
      tab = name === dashboard.name ? dashboard : flatMenus.value?.find(o => o.name === name);
      curTabs.value = [...curTabs.value, tab];
      if (redundant.value > 0) {
        const f = curTabs.value?.filter(o => o.path !== dashboard.path);
        curTabs.value = [dashboard, ...drop(f)];
      }
    }
    if (path) tab.path = path;
    curTab.value = tab;
    routerTo(curTab.value.path);
    save();
  };

  const removeTab = name => {
    const removeTab = curTabs.value?.find(o => o.name === name);
    if (removeTab) {
      const filterTabs = curTabs.value?.filter(o => o.name !== removeTab.name);
      curTabs.value = [...filterTabs];
      if (removeTab.name === curTab.value.name) {
        curTab.value = last(curTabs.value);
        routerTo(curTab.value?.path);
      }
      save();
    }
  };

  const setTabLimit = tabLimit => {
    curTabLimit.value = tabLimit;
    if (redundant.value > 0) {
      const dropRightTabs = dropRight(curTabs.value, redundant.value);
      curTabs.value = [...dropRightTabs];
      if (!curTabs.value?.find(o => o.name === curTab.value.name)) {
        curTab.value = last(curTabs.value);
        routerTo(curTab.value);
      }
    }
    save();
  };

  const save = () => {
    storage.set(TABS_KEY, {
      tabsVisible: tabsVisible.value,
      curTabs: curTabs.value,
      curTab: curTab.value,
      curTabLimit: curTabLimit.value,
    });
  };

  const tabLimitOptions = computed(() => {
    let options = [];
    for (let i = 2; i < 11; i++) {
      options.push({ value: i, label: `${i}` });
    }
    return options;
  });

  const keepAlives = computed(() => {
    if (!tabsVisible.value) return [];
    let keepAlives = [];
    curTabs.value?.forEach(o => {
      if (o.meta.keepAlive) {
        keepAlives.push(o.meta.cacheName);
        const sub = flatMenus.value
          ?.filter(f => f.isSub && f.meta.keepAlive)
          ?.find(f => f.meta.cacheName[0] === o.meta.cacheName);
        if (sub) keepAlives.push(sub.meta.cacheName[1]);
      }
    });
    return toRaw(keepAlives);
  });

  const closeCurrentTab = () => removeTab(curTab.value.name);

  const closeOtherTabs = () => {
    curTabs.value = curTab.value.name === dashboard.name ? [dashboard] : [dashboard, curTab.value];
    save();
  };

  const closeRightTabs = () => {
    const curIndex = findIndex(curTabs.value, o => o.name === curTab.value.name);
    const dropRightTabs = dropRight(curTabs.value, curTabs.value.length - curIndex - 1);
    curTabs.value = [...dropRightTabs];
    save();
  };

  const closeAllTabs = () => {
    curTabs.value = [dashboard];
    curTab.value = dashboard;
    routerTo(dashboard.path);
    save();
  };

  const clearTabsCache = () => storage.remove(TABS_KEY);

  watch(
    () => router.currentRoute.value,
    to => {
      if (to.name === 'login') return;
      // dashboard直接切换
      if (to.name === dashboard.name) setTab(dashboard.name);
      else {
        const isSub = Array.isArray(to.meta.cacheName);
        const current = flatMenus.value.find(o => o.name === to.name);
        if (isSub) {
          const parent = flatMenus.value?.find(o => o.meta.cacheName === to.meta.cacheName[0]);
          // 处理没有权限的模块
          if (!flatMenus.value?.find(o => o.name === parent.name)) routerTo(curTab.value.path);
          // 处理url请求的模块
          else if (parent.name !== curTab.value.name) setTab(parent.name, to.fullPath);
          // 从父模块切换到子模块
          else if (current.meta.cacheName[0] === curTab.value.meta.cacheName) {
            curTab.value.path = to.fullPath;
            curTabs.value.find(o => o.name === curTab.value.name).path = to.fullPath;
            save();
          }
        } else {
          // 处理没有权限的模块
          if (!current) routerTo(curTab.value.path);
          // 处理url请求的模块
          else if (current.name !== curTab.value.name) setTab(current.name, to.fullPath);
          // 处理子模块切换到父模块时
          else if (current.meta.cacheName === curTab.value.meta.cacheName) {
            curTab.value.path = to.fullPath;
            curTabs.value.find(o => o.name === curTab.value.name).path = to.fullPath;
            save();
          }
        }
      }
    },
    { deep: true },
  );

  return {
    tabsVisible,
    keepAlives,
    curTabs,
    curTab,
    curTabLimit,
    tabLimitOptions,
    setTabsVisible,
    setTabs,
    setTab,
    removeTab,
    setTabLimit,
    closeCurrentTab,
    closeOtherTabs,
    closeRightTabs,
    closeAllTabs,
    clearTabsCache,
  };
};
