export const RESPONSE_RESULT = {
  DATA: 'DATA',
  CSV: 'text/csv',
  MESSAGE: 'MESSAGE',
  SUCCESS: 'SUCCESS',
  VALIDATION: 'VALIDATION',
  REPORT: 'application/octet-stream',
  APPLICATION_JSON: 'application/json',
};

export const RESPONSE_LEVEL = {
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
};
