<template>
  <NFormItem class="n-form-item-step" v-bind="formItemBaseAttrs">
    <NRadioGroup :value="value" v-bind="componentAttrs">
      <NRadio v-for="item in options" :key="item.value" class="n-form-item-step-radio" :value="item.value">
        {{ item.label }}
      </NRadio>
    </NRadioGroup>
  </NFormItem>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';

export default defineComponent({
  name: 'NSimpleRadioGroup',

  inheritAttrs: false,

  props: {
    name: [String, Array],
    label: String,
    customLabel: String,
    value: undefined,
    options: { type: Array, default: () => [] },
  },

  setup(props, { attrs }) {
    const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);

    const componentAttrs = computed(() => ({
      ...componentBaseAttrs.value,
      ...attrs,
    }));

    return { formItemBaseAttrs, componentAttrs };
  },
});
</script>

<style lang="less"></style>
