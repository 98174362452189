export default {
  rolePos: {
    ROLE_IT: '机构管理员',
    ROLE_AGENT: '代理管理员',
  },
  booleanOptions: {
    YES: '是',
    NO: '否',
  },
};
