<template>
  <ACollapse v-model:activeKey="activeKey" class="text-center" ghost>
    <ACollapsePanel key="1" :header="text">
      <slot />
    </ACollapsePanel>
  </ACollapse>
</template>

<script setup>
import { Collapse as ACollapse } from 'ant-design-vue/es';
import { CollapsePanel as ACollapsePanel } from 'ant-design-vue/es';
import {computed, ref, watch} from 'vue';

const props = defineProps({
  colLabel: undefined,
});

const text = computed(() => (props.colLabel));
const activeKey = ref(['0']);

watch(activeKey, val => {
});
</script>

<style lang="less">
</style>
