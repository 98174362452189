<template>
  <div :ref="setTeleportTarget" class="n-table-container">
    <Table
      :data-source="dataSource"
      :columns="columns"
      :loading="loading"
      :pagination="false"
      :sticky="sticky"
      :bordered="bordered"
      class="n-table ant-table-striped"
      :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      @change="onChangeTable"
    >
      <template #headerCell="scope">
        <template v-for="name in headerCellSlotNames">
          <template v-if="false" />
          <slot v-else-if="name === scope.column.headerSlot" :name="name" v-bind="scope || {}">
            <span :key="name" />
          </slot>
        </template>
      </template>
      <template #bodyCell="scope">
        <template v-for="name in bodyCellSlotNames">
          <template v-if="false" />
          <slot v-else-if="name === scope.column.bodySlot" :name="name" v-bind="scope || {}">
            <span :key="name" />
          </slot>
        </template>
      </template>
      <template v-for="name in otherSlotNames" #[name]="scope">
        <slot :name="name" v-bind="scope || {}">
          <span :key="name" />
        </slot>
      </template>
      <template #emptyText>
        <span v-if="!loading"><info-circle-outlined class="n-warn mr-2" />{{ $tl('noDataFound') }}</span>
      </template>
    </Table>
    <Teleport v-if="teleportTarget" :to="teleportTarget">
      <div v-if="!!pagination" class="c-pagination-container">
        <div class="c-pagination-float-box">
          <NPagination v-bind="pagination" class="n-pagination hidden sm:block" @change="onChangePagination" />
          <NPagination v-bind="pagination" class="n-pagination block sm:hidden" simple @change="onChangePagination" />
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es';
import { useTableConfig } from '@/hooks';
import { Table } from 'ant-design-vue/es';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'NTable',

  components: { Table },

  inheritAttrs: false,

  props: {
    dataSource: { type: Array, default: () => [] },
    columns: { type: Array, default: () => [] },
    loading: Boolean,
    pagination: [Object, Boolean],
    sticky: { type: Boolean, default: true },
  },

  setup(props, { attrs, slots, emit }) {
    const headerCellSlotNames = [];

    const bodyCellSlotNames = [];

    const otherSlotNames = [];

    const teleportTarget = ref();

    const { curBordered } = useTableConfig();

    Object.keys(slots).forEach(name => {
      if (name === 'bodyCell') throw 'NTable: bodyCell is reserved word';
      if (name.startsWith('body')) bodyCellSlotNames.push(name);
      else if (name.startsWith('header')) headerCellSlotNames.push(name);
      else otherSlotNames.push(name);
    });

    const bordered = computed(() => {
      return attrs.bordered !== undefined ? attrs.bordered : curBordered.value;
    });

    const onChangeTable = (_, __, sorter) => {
      // sorter
      if (!isEmpty(sorter)) {
        const { field, order } = sorter;
        emit('sort', { field, order });
      }
    };

    const onChangePagination = (current, pageSize) => emit('pagination', { current, pageSize });

    const setTeleportTarget = dom => {
      if (dom) {
        teleportTarget.value = dom.querySelector('.n-table');
      }
    };

    return {
      onChangeTable,
      onChangePagination,
      headerCellSlotNames,
      bodyCellSlotNames,
      otherSlotNames,
      bordered,
      teleportTarget,
      setTeleportTarget,
    };
  },
});
</script>

<style lang="less">
.n-table-container {
  width: 100%;

  .ant-table-thead tr th.ant-table-column-has-sorters {
    user-select: none;
  }

  .ant-table-striped .table-striped td {
    background-color: #fafafa;
  }

  .ant-table-tbody tr:hover td {
    background-color: #e8eaec;
  }

  .c-pagination-container {
    width: 100%;
    text-align: right;

    .c-pagination-float-box {
      display: inline-block;
      position: sticky;
      right: 0;

      .n-pagination {
        width: fit-content;
        margin: 16px 0;
      }
    }
  }
}

.ant-modal-body .n-table-container {
  overflow: auto;
}
</style>
