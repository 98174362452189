import { assign, cloneDeep } from 'lodash-es';
import { getCurrentInstance, h, render, reactive, onBeforeUnmount, ref, computed } from 'vue';
import { ModalResult } from '@/models';

const MODAL_DATA_KEY = '_modalData';

export function useModal(config = {}) {
  const currentInstance = getCurrentInstance();
  const { onReload = () => {} } = config;

  if (!currentInstance) {
    throw 'useModal.ts: no currentInstance';
  }

  const createModal = (tag, props = {}) => {
    const clonedProps = cloneDeep(assign({ model: {} }, props));

    const parentDom = document.body;
    const container = document.createElement('div');
    container.setAttribute('class', 'n-modal-container');
    parentDom.appendChild(container);

    let destroyed = false; // HMR 会触发onUnmounted

    const destroy = () => {
      if (destroyed) return;
      destroyed = true;
      render(null, container);
      parentDom.removeChild(container);
    };

    const modalData = {
      model: reactive(clonedProps.model),
      isAdd: clonedProps.action === 'add',
      isEdit: clonedProps.action === 'edit',
      isDetail: clonedProps.action === 'detail',
      isInModal: true,
      ...useButtonHooks(clonedProps),
      destroy,
      onReload,
    };

    onBeforeUnmount(() => {
      destroy();
    }, currentInstance);

    const vnode = h(tag, { ...clonedProps, destroy });

    let modalComponent;
    Object.defineProperty(vnode, 'component', {
      get() {
        return modalComponent;
      },
      set(component) {
        // copy 'provides'
        let provides = currentInstance.provides;
        const parentProvides = currentInstance.parent?.provides;
        if (parentProvides === provides) {
          provides = currentInstance.provides = Object.create(parentProvides);
        }
        component.provides = provides;
        component.parent = currentInstance; // error链
        component[MODAL_DATA_KEY] = modalData;
        modalComponent = component;
      },
    });

    vnode.appContext = currentInstance.appContext; // 上下文
    render(vnode, container);

    return {
      destroy,
    };
  };

  return {
    createModal,
  };
}

export function useInjectModalData() {
  const currentInstance = getCurrentInstance();
  if (!currentInstance) {
    throw 'useInjectModalData: no currentInstance';
  }

  let { parent } = currentInstance;
  let modalData = currentInstance[MODAL_DATA_KEY];
  while (!modalData && parent) {
    modalData = parent[MODAL_DATA_KEY];
    parent = parent.parent;
  }
  // Ken 为了方便, 支持随意调用(将下方代码注释)
  // if (!modalData) {
  //   throw 'useInjectModalData only used under Modal which created by useModal';
  // }
  return modalData;
}

function useButtonHooks(props) {
  let okHandlers = [];
  let cancelHandlers = [];

  const loading = ref(false);
  const formItemDisabled = computed(() => (props.action === 'detail' ? true : loading.value));
  const addDisabled = computed(() => props.action === 'add' || formItemDisabled.value);
  const editDisabled = computed(() => props.action === 'edit' || formItemDisabled.value);

  const defaultResult = new ModalResult({ close: true });

  function onOk(handler) {
    okHandlers.push(handler);
  }

  function onCancel(handler) {
    cancelHandlers.push(handler);
  }

  async function _execute(params, handlers) {
    let ret = defaultResult;
    try {
      loading.value = true;
      for (const handler of handlers) {
        ret = (await handler(params)) || defaultResult;
      }
    } finally {
      loading.value = false;
    }
    return ret;
  }

  async function emitOk(params) {
    return _execute(params, okHandlers);
  }

  async function emitCancel(params) {
    return _execute(params, cancelHandlers);
  }

  function clear() {
    okHandlers = [];
    cancelHandlers = [];
  }

  return {
    loading,
    formItemDisabled,
    addDisabled,
    editDisabled,
    onOk,
    onCancel,
    emitOk,
    emitCancel,
    clear,
  };
}
