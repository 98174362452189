export default {
  dashboard: '主頁',
  member: {
    title: '客戶管理',
    customer: '客戶列表',
    customerDetail: '詳情',
    bankCard: '銀行卡管理',
    identification: '身份管理',
    customerVerifyFlow: '身份認證',
  },
  cash: {
    title: '資金管理',
    itReceive: 'USDT充值賬號',
    usdtErc20Config: 'USDT ERC20 配置',
    usdtTrc20Config: 'USDT TRC20 配置',
    fxRateTemplate: '匯率模板',
    fxRateDetail: '詳情',
    customerDepositFlow: '充值管理',
    customerDepositDetails: '詳情',
    customerWithdrawalFlow: '提現管理',
    customerWithdrawalDetails: '詳情',
    transferTransaction: '轉賬管理',
    paymentChannel: '系統支付渠道',
    paymentChannelDetails: '詳情',
    paymentGateway: 'IT支付網關',
    paymentGatewayDetails: '詳情',
    paymentRouting: 'IT支付渠道路由',
  },
  role: {
    title: '角色管理',
    user: '操作員管理',
    role: '角色列表',
    it: '機構管理',
    itCreateAccounts: '創建賬戶',
    agent: '代理管理',
  },
  system: {
    title: '系統管理',
    config: '變數管理',
    operation: '系統操作',
    cache: '緩存管理',
  },
  demo: {
    title: 'Demo',
    demo: 'Demo',
  },
};
