import { computed, useAttrs } from 'vue';
import { useInjectModalData, useLanguage } from '@/hooks';

export function useFormItemBaseAttrs(props) {
  const attrs = useAttrs();

  const { tl } = useLanguage();

  const modalData = useInjectModalData();

  const isInModal = !!modalData;

  if (props.span2 || attrs.span2) {
    console.log('userFormItemBaseAttrs, modalData', modalData);
  }

  const formItemBaseAttrs = computed(() => ({
    label: props.customLabel ?? (props.label ? tl(props.label) : undefined),
    name: props.name ?? props.label,
    class: attrs.class,
    span2: props.span2 ?? attrs.span2,
    help: attrs.help,
    validateTrigger: attrs['validate-trigger'] || attrs.validateTrigger,
    validateStatus: attrs['validate-status'] || attrs.validateStatus,
    rules: attrs.rules,
  }));

  const componentBaseAttrs = computed(() => ({
    disabled: modalData?.formItemDisabled?.value,
  }));

  return { isInModal, formItemBaseAttrs, componentBaseAttrs };
}
