import { isNumber } from 'lodash-es';
import BigNumber from 'bignumber.js';
import { decimalRegex } from '@/constant';

export const validValueRegex = /^(-?[0-9]*\.?[0-9]*?)0*$/;
const formatRegex = /\B(?=(\d{3})+(?!\d))/g;

export const DEFAULT_FORMAT = {
  groupSize: 3, // the primary grouping size of the integer part
  groupSeparator: ',', // the grouping separator of the integer part
  decimalSeparator: '.', // the decimal separator
  fractionGroupSize: 0, // the grouping size of the fraction part
  secondaryGroupSize: 0, // the secondary grouping size of the integer part
  fractionGroupSeparator: ' ', // the grouping separator of the fraction part
};

const DEFAULT_BASE = 10; // 十进制
const ILLEGALS = [undefined, null, NaN, Infinity, -Infinity];

// Global Config
BigNumber.config({
  FORMAT: DEFAULT_FORMAT,
  ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
  EXPONENTIAL_AT: 1e9, // Almost never return exponential notation
});

/**
 * Basic Operation
 */
export function adds(...values) {
  return operate('plus', values);
}

export function subs(...values) {
  return operate('minus', values);
}

export function muls(...values) {
  return operate('multipliedBy', values);
}

export function isValid(n) {
  return !ILLEGALS.includes(n) && !new BigNumber(n).isNaN();
}

export function operate(method, values, filter = false) {
  const valids = filter ? values.filter(isValid) : values;
  let i = 1;
  let r = new BigNumber(valids[0]);
  for (; i < valids.length; ) r = r[method](valids[i++]);
  return r;
}

/**
 * @Author Ken
 * @CreateDate 2019-12-17 14:29
 * @LastUpdateDate 2019-12-17 14:29
 * @desc
 *  比较两个number的大小, 考虑精度
 *  (对于PriceCell组件, 如果不考虑scale, 可能会出现显示的price没变, 但trend会有变化)
 *  如: scale = 3时, 0.100001 与 0.1 一样大
 */
export function gt(v1, v2, scale) {
  const BN = isNumber(scale) ? BigNumber.clone({ DECIMAL_PLACES: scale }) : BigNumber;
  return new BN(v1, DEFAULT_BASE).gt(new BN(v2, DEFAULT_BASE));
}

export function lt(v1, v2, scale) {
  const BN = isNumber(scale) ? BigNumber.clone({ DECIMAL_PLACES: scale }) : BigNumber;
  return new BN(v1, DEFAULT_BASE).lt(new BN(v2, DEFAULT_BASE));
}

export function gte(v1, v2, scale) {
  const BN = isNumber(scale) ? BigNumber.clone({ DECIMAL_PLACES: scale }) : BigNumber;
  return new BN(v1, DEFAULT_BASE).gte(new BN(v2, DEFAULT_BASE));
}

export function plus(v1, v2) {
  return new BigNumber(v1).plus(new BigNumber(v2)).toString();
}

export function minus(v1, v2) {
  return new BigNumber(v1).minus(new BigNumber(v2)).toString();
}

export function multipliedBy(v1, v2) {
  return new BigNumber(v1).multipliedBy(new BigNumber(v2)).toString();
}

export function dividedBy(v1, v2) {
  return new BigNumber(v1).dividedBy(new BigNumber(v2)).toString();
}

export function abs(v) {
  return new BigNumber(v).abs();
}

export function toFixed(v, scale, mode) {
  return mode ? new BigNumber(v).toFixed(scale, mode) : new BigNumber(v).toFixed(scale);
}

export const ROUND_DOWN = BigNumber.ROUND_DOWN;

export function getRoundingMode(javaMode) {
  const modes = {
    _default: BigNumber.ROUND_DOWN,
    UP: BigNumber.ROUND_UP,
    DOWN: BigNumber.ROUND_DOWN,
    FLOOR: BigNumber.ROUND_FLOOR,
    CEILING: BigNumber.ROUND_CEIL,
    HALF_UP: BigNumber.ROUND_HALF_UP,
    HALF_DOWN: BigNumber.ROUND_HALF_DOWN,
  };
  const r = modes[javaMode];
  return r === undefined ? modes._default : r;
}

export function getDecimalInfo(value = '', scale) {
  if (typeof value === 'number') {
    // for '1e-8'
    value = new BigNumber(value).toString();
  } else {
    value = String(value);

    // For: '-000'
    if (new BigNumber(value).toString() === '0') {
      value = '0';
    }
  }
  if (!decimalRegex.test(value)) return null;

  // @ts-ignore
  let [, validValue] = validValueRegex.exec(value);
  // '123.' => '123'
  if (validValue[validValue.length - 1] === '.') {
    validValue = validValue.substring(0, validValue.length - 1);
  }

  const [integerValue, validDecimalValue = ''] = validValue.split('.');
  const validScale = validDecimalValue?.length ?? 0;

  const [, decimalValue] = value.split('.');
  const decimalScale = decimalValue?.length ?? 0;

  scale = scale ?? decimalScale;
  scale = Math.max(validScale, scale); // won't round number ever

  /**
   /\B(?=(\d{3})+(?!\d))/g：正则匹配边界\B，边界后面必须跟着(\d{3})+(?!\d);
   (\d{3})+：必须是1个或多个的3个连续数字;
   (?!\d)：第2步中的3个数字不允许后面跟着数字;
   (\d{3})+(?!\d)：所以匹配的边界后面必须跟着3*n（n>=1）的数字。
   ?=: 前瞻
   */
  const formattedInteger = integerValue.replace(formatRegex, ',');
  const formattedValidValue = `${formattedInteger}${validScale > 0 ? '.' : ''}${validDecimalValue}`;
  const formattedValue = `${formattedInteger}${scale > 0 ? '.' : ''}${validDecimalValue.padEnd(scale, '0')}`;
  const scaledValue = formattedValue.replace(/,/g, '');

  return { scale, validValue, validScale, formattedValidValue, formattedValue, scaledValue };
}

export function toPercent(value, scale = 0) {
  const percentValue = muls(value, 100).toString();
  const { formattedValue } = getDecimalInfo(percentValue, scale) || {};
  return formattedValue ? formattedValue + ' %' : '';
}
