export default {
  success: {
    successfully: '{key}成功',
    copyToClipboard: '已复制到剪切板',
    approved: '{key}已获批准',
    processed: '{key}处理已完成',
    uploaded: '{key}上传已完成',
    register: '注册成功, 请登陆',
  },
  warning: {
    rejected: '{key}已被驳回',
    removeFxRateTemplate: '注意：同时会从客户、机构和代理中将使用「{name}」的模板移除。',
    transferFromIt: '你确定要将全部剩余金额 [ 预估：{amount} ] 从 {from} 转账到 {to} 吗？',
    clearBrowserCache: '此操作会清除浏览器缓存，并恢复为以下默认设置：',
    forcedRefresh: '*注意：当前页面将会强制刷新！',
    resetPassword1: '即将发送邮件到',
    resetPassword2: '{email}',
    resetPassword3: '帮助您找回账户',
  },
  error: {
    required: '必填项',
    duplicatedBy: '不能与{target}相同',
    symbolExisted: '当前币对已经存在',
    failed: '{key}失败',
    invalidFormat: '格式无效',
    serverUnavailable: '服务器当前不可用，请稍后再试',
    uploadFileType: '您只能上传{types}文件',
    uploadFileSize: '容量不能超过{maxSize}MB',
    uploadFailed: '上传失败',
    underLength: '长度不足 [{min}]',
    overLength: '长度超出范围 [{max}]',
    decimalOverLength: '长度超出范围 [{precision}, {scale}]',
    underflow: '低于最小值 [{min}]',
    overflow: '超过最大值 [{max}]',
    invalidMailFormat: '无效的邮件格式',
    notOnlyNumber: '不能只有数字',
    notOnlyLetter: '不能只有字母',
    atLeastOneLowercase: '至少有一个小写字母',
    atLeastOneUppercase: '至少有一个大写字母',
    atLeastOneNumber: '至少有一个数字',
    onlyAlphanumerical: '必须是数字与字母组合',
    cannotBeSuperuser: '不能是超级用户',
    mustBeSuperuser: '必须是超级用户',
    mustBeGreaterThan: '必须大于{number}',
    ranges: '取值范围:{min}~{max}',
    lengthLimit: '长度应为 {length}',
    notSame: '两次密码输入不匹配',
    notBlank: '不能是空白字符',
    containsSpace: '不能含有空格',
  },
  confirm: {
    title: '你确定要{action}吗？',
    resourceNotFoundTitle: '资源过时',
    resourceNotFoundContent: '你想要刷新页面, 以更新最新的资源吗?',
  },
  modal: {
    add: '添加 {name}',
    edit: '编辑 {name}',
    detail: '{name} 详情',
  },
  pagination: {
    showTotal: `{start}-{end} 共 {total} 条数据`,
  },
  tooltip: {
    settings: '此设置保存在浏览器缓存中',
  },
  message: {
    itReceiveCheck: '机构收款账号验证通过。',
    needToCreateUsdReceiveAccount: '机构收款账号验证失败，需要创建‘美元’收款账号。',
  },
};
