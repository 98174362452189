import { isString } from 'lodash-es';

export class ErrorModel {
  constructor() {}
}

// {"key":"INVALID_CONTRACT","level":"ERROR","type":"MESSAGE"}
// {"key": "error.overlength", level: "ERROR", "type": "MESSAGE"}

/**
 * Example:
 {
      data: {
        scale: {
          key: 'error.typeMismatch.byte',
          level: 'INFO',
          args: [{ codes: ['currencypairPo.scale', 'scale'], defaultMessage: 'scale', code: 'scale' }],
          type: 'MESSAGE',
        },
      },
      type: 'VALIDATION',
    }
 * */
export class ValidationError extends ErrorModel {
  constructor(data = {}) {
    super();
    this.type = 'VALIDATION';
    this.data = data;
  }
}
