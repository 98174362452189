// BUSINESS MESSAGE PACKAGE

/**
 * @Author Ken
 * @CreateDate 2021-10-27 18:21
 * @desc for navigation
 * @params
 * @return
 */
export class NavigatorMessage {
  constructor({ path, type = 'to', data = {} }) {
    this.name = 'NavigatorMessage';
    this.path = path;
    this.type = type; // to | replace
    this.data = data;
  }
}

/**
 * @Author Ken
 * @CreateDate 2021-11-18 16:26
 * @desc 模态对话框返回的数据
 * @params
 * @return
 */
export class ModalResult {
  constructor({ close, reload = close }) {
    this.close = close;
    this.reload = reload;
  }
}

/**
 * @Author Ken
 * @CreateDate 2022-01-14 10:32
 * @desc ignore this message
 * @params
 * @return
 */
export class IgnoredMessage {
  constructor(reason = null) {
    this.name = 'IgnoredMessage';
    this.reason = reason;
  }
}

/**
 * @Author Ken
 * @CreateDate 2022-12-20 16:15
 * @desc for unauthorized
 * @params
 * @return
 */
export class NoAuthMessage {
  constructor({ data = {} }) {
    this.name = 'NoAuthMessage';
    this.data = data;
  }
}
