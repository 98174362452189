export default [
  {
    name: 'menu.member.title',
    path: '/member',
    icon: 'fas fa-user-tie',
    children: [
      {
        name: 'menu.member.customer',
        path: '/customer',
        meta: {
          keepAlive: true,
          cacheName: 'MemberCustomer',
        },
        component: () => import('../views/index/member/customer/Customer.vue'),
      },
      {
        name: 'menu.member.bankCard',
        path: '/bank-card',
        meta: {
          keepAlive: true,
          cacheName: 'MemberBankCard',
        },
        component: () => import('../views/index/member/bank_card/BankCard.vue'),
      },
      {
        name: 'menu.member.identification',
        path: '/identification',
        meta: {
          keepAlive: true,
          cacheName: 'MemberIdentification',
        },
        component: () => import('../views/index/member/identification/Identification.vue'),
      },
      {
        name: 'menu.member.customerVerifyFlow',
        path: '/customer-verify-flow',
        meta: {
          keepAlive: true,
          cacheName: 'MemberCustomerVerifyFlow',
        },
        component: () => import('../views/index/member/customer_verify_flow/CustomerVerifyFlow.vue'),
      },
    ],
  },
];
