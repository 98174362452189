import { mySentry } from '@/monitor';
import { NavigatorMessage } from '@/models';
import { useRouter } from '@/hooks';

export function navigatorHandler(err, vm, info) {
  if (!(err instanceof NavigatorMessage)) return true;

  const { type, path } = err;
  const { routerTo, routerReplace } = useRouter();

  switch (type) {
    case 'to':
      routerTo(path);
      break;
    case 'replace':
      routerReplace(path);
      break;
    default:
      const msg = 'navigatorHandler: wrong type, type=' + type;
      console.error(msg);
      mySentry?.captureErrorMessage(msg);
  }
  return false;
}
