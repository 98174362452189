<template>
  <div class="n-span-copy">
    <template v-if="label">
      <span :title="label" @dblclick="toClipboard(label)">{{ label }}</span>
      <copy-outlined :title="$tl('copy')" @click="toClipboard(label)" />
    </template>
    <span v-else>-</span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useClipboard } from '@/hooks';

const props = defineProps({
  value: { type: [String, Number], default: '' },
});

const { toClipboard } = useClipboard();
const label = computed(() => (![undefined, null, '', NaN].includes(props.value) ? `${props.value}` : ''));
</script>

<style lang="less">
.n-span-copy {
  & > span.anticon-copy {
    display: none;
    margin-left: 4px;
    cursor: pointer;
  }

  &:hover {
    & > span.anticon-copy {
      display: inline-block;
    }
  }
}
</style>
