<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <BaseSelect
      class="n-select"
      v-bind="componentAttrs"
      @dropdown-visible-change="onDropdownVisibleChange"
      @input-key-down="onInputKeyDown"
    />
  </NFormItem>
</template>

<script setup>
import { SELECT } from './constant';
import { computed, useAttrs, ref } from 'vue';
import { findNodeUpward } from '@/components/utils';
import { useInjectModalData, useLanguage } from '@/hooks';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';

const { tl } = useLanguage();

const attrs = useAttrs();

const emits = defineEmits(['dropdownVisibleChange', 'inputKeyDown', 'pressEnter']);

const modalData = useInjectModalData();

const isInModal = !!modalData;

const isOpen = ref(false);

const props = defineProps({
  label: String,
  name: [String, Array],
  customLabel: String,
  options: { type: Array, default: () => [] },
  allOption: { type: Boolean, default: undefined },
  showSearch: { type: Boolean, default: true },
  getPopupContainer: { type: Function, default: node => findNodeUpward(node, 'n-form-item') ?? document.body },
});

const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);

const mAllOption = computed(() => props.allOption ?? !isInModal);

const options = computed(() => {
  const ret = [...props.options];
  if (mAllOption.value) {
    ret.unshift({ label: tl('all'), value: SELECT.ALL_OPTION_VALUE });
  }
  return ret;
});

const componentAttrs = computed(() => ({
  showSearch: props.showSearch,
  filterOption: (inputValue, option) => option.label?.toLowerCase().includes?.(inputValue?.toLowerCase()),
  ...componentBaseAttrs.value,
  ...attrs,
  getPopupContainer: props.getPopupContainer,
  options: options.value,
}));

const onDropdownVisibleChange = open => {
  emits('dropdownVisibleChange', open);
  isOpen.value = open;
};

const onInputKeyDown = $event => {
  emits('inputKeyDown', $event);
  if ($event.keyCode === 13 && isOpen.value === false) emits('pressEnter', $event);
};
</script>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style lang="less">
.n-select {
  .ant-select-selection-item {
    transition: none !important; // fix select 与 input disabled时动画不同步的问题
  }
}
</style>
