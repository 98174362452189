<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <ATreeSelect
      multiple
      show-arrow
      tree-default-expand-all
      :max-tag-count="0"
      tree-checkable
      tree-check-strictly
      tree-node-filter-prop="title"
      :max-tag-placeholder="getTagPlaceholder"
      class="n-tree-multiple-select"
      v-bind="componentAttrs"
      v-on="componentListeners"
    />
  </NFormItem>
</template>

<script>
import { useLanguage } from '@/hooks';
import { computed, defineComponent } from 'vue';
import { TreeSelect as ATreeSelect } from 'ant-design-vue/es';
import { useFormItemBaseAttrs } from '@/components/common/hooks/useFormItemBaseAttrs';

export default defineComponent({
  name: 'NTreeMultipleSelect',

  components: { ATreeSelect },

  inheritAttrs: false,

  props: {
    value: { type: Array, default: () => [] },
    treeData: { type: Array, default: () => [] },
    label: String,
    customLabel: String,
    name: [String, Array],
  },

  setup(props, { attrs, emit }) {
    const { tl } = useLanguage();

    const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);

    const componentAttrs = computed(() => {
      return {
        ...componentBaseAttrs.value,
        ...attrs,
        value: props.value,
        treeData: props.treeData,
        dropdownStyle: { maxHeight: '400px', overflow: 'auto' },
      };
    });

    const componentListeners = computed(() => ({
      change: opts => {
        const value = opts.map(o => o.value);
        emit('update:value', value);
        emit('change', value);
      },
    }));

    const getTagPlaceholder = omittedValues => {
      return omittedValues.length === 1 ? omittedValues[0].label : tl('selectedCount', { count: omittedValues.length });
    };

    return { formItemBaseAttrs, componentAttrs, componentListeners, getTagPlaceholder };
  },
});
</script>

<style lang="less">
.n-tree-multiple-select {
}
</style>
