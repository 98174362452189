<template>
  <AInput
    class="n-base-input"
    v-bind="$attrs"
    :value="isFocus ? value : displayPattern(value)"
    @update:value="onUpdateValue"
    @blur="onBlur"
    @focus="onFocus"
  >
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </AInput>
</template>

<script setup>
import { ref } from 'vue';
import { isFunction } from 'lodash-es';
import { Input as AInput } from 'ant-design-vue/es';

const props = defineProps({
  value: undefined,
  valuePattern: [RegExp, Function],
  displayPattern: { type: Function, default: v => v },
});

const emits = defineEmits(['update:value', 'blur', 'focus']);
const isFocus = ref(false);

const onUpdateValue = v => {
  if (!v || !props.valuePattern) {
    emits('update:value', v);
  } else if (props.valuePattern instanceof RegExp && props.valuePattern.test(v)) {
    emits('update:value', v);
  } else if (isFunction(props.valuePattern) && props.valuePattern(v)) {
    emits('update:value', v);
  } else {
    emits('update:value', props.value ?? ''); // 规则不匹配时emit空，解决初次加载props.value是undefined时可以输入英文问题
  }
};

function onBlur(e) {
  isFocus.value = false;
  emits('blur', e);
}

function onFocus(e) {
  isFocus.value = true;
  emits('focus', e);
}
</script>

<script>
export default {
  name: 'BaseInput',
  inheritAttrs: false,
};
</script>
