export default {
  dashboard: '主页',
  member: {
    title: '客户管理',
    customer: '客户列表',
    customerDetail: '详情',
    bankCard: '银行卡管理',
    identification: '身份管理',
    customerVerifyFlow: '身份认证',
  },
  cash: {
    title: '资金管理',
    itReceive: 'USDT充值账号',
    usdtErc20Config: 'USDT ERC20 配置',
    usdtTrc20Config: 'USDT TRC20 配置',
    fxRateTemplate: '汇率模板',
    fxRateDetail: '详情',
    customerDepositFlow: '充值管理',
    customerDepositDetails: '详情',
    customerWithdrawalFlow: '提现管理',
    customerWithdrawalDetails: '详情',
    transferTransaction: '转账管理',
    paymentChannel: '系统支付渠道',
    paymentChannelDetails: '详情',
    paymentGateway: 'IT支付网关',
    paymentGatewayDetails: '详情',
    paymentRouting: 'IT支付渠道路由',
  },
  role: {
    title: '角色管理',
    user: '操作员管理',
    role: '角色列表',
    it: '机构管理',
    itCreateAccounts: '创建账户',
    agent: '代理管理',
  },
  system: {
    title: '系统管理',
    config: '变量管理',
    operation: '系统操作',
    cache: '缓存管理',
  },
  demo: {
    title: 'Demo',
    demo: 'Demo',
  },
};
