<template>
  <Modal v-bind="attributes" class="n-modal">
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </Modal>
</template>

<script>
import Modal from 'ant-design-vue/es/modal';
import { reactive, defineComponent, computed } from 'vue';
import { ModalResult, ValidationError } from '../../models';
import { useInjectModalData, useLanguage } from '../../hooks';

export default defineComponent({
  name: 'NModal',

  components: { Modal },

  inheritAttrs: false,

  props: {
    customTitle: String,
    title: String,
    width: { type: [Number, String], default: 900 },
    action: { type: String, default: 'add', validator: v => ['add', 'edit', 'detail'].includes(v) },
    okBtnDisabled: { type: Boolean, default: false },
  },

  setup(props, { attrs, slots }) {
    const { tl, t, PREFIX } = useLanguage();

    const { emitOk, emitCancel, destroy, isDetail, onReload } = useInjectModalData();

    const modalListeners = {
      async onOk() {
        okButtonProps.loading = true;
        let ret;
        try {
          ret = await emitOk();
          if (ret instanceof ModalResult) {
            ret.close && destroy();
            ret.reload && onReload();
          } else {
            destroy();
            onReload();
          }
        } catch (e) {
          if (e instanceof ValidationError) {
            // ignore, no require for now
          } else throw e;
        } finally {
          okButtonProps.loading = false;
        }
        return ret;
      },
      async onCancel() {
        cancelButtonProps.loading = true;
        let ret;
        try {
          ret = await emitCancel();
          if (ret instanceof ModalResult) {
            ret.close && destroy();
          } else {
            destroy();
          }
        } catch (e) {
          if (e instanceof ValidationError) {
            // ignore, no require for now
          } else throw e;
        } finally {
          cancelButtonProps.loading = false;
        }
        return ret;
      },
    };

    const easyClose = isDetail;

    const okButtonProps = reactive({ loading: false, style: { display: isDetail ? 'none' : undefined } });

    const cancelButtonProps = reactive({ loading: false });

    const attributes = computed(() => ({
      wrapClassName: 'n-modal-wrapper',
      class: 'n-modal',
      width: props.width,
      keyboard: easyClose,
      maskClosable: easyClose,
      ...attrs,
      visible: true,
      ...modalListeners,
      okButtonProps: { ...okButtonProps, disabled: props.okBtnDisabled },
      okText: tl('confirm'),
      cancelButtonProps,
      cancelText: props.action === 'detail' ? tl('close') : tl('cancel'),
      title: slots.title ? undefined : props.customTitle ?? t(props.action, PREFIX.MODAL, { name: props.title }),
    }));

    return { attributes };
  },
});
</script>

<style lang="less">
.n-modal {
  .ant-modal-header {
    height: 42px;
    line-height: 42px;
    padding: 0 16px;

    .ant-modal-title {
      height: 42px;
      line-height: 42px;
      font-size: 15px;
    }
  }

  .ant-modal-close {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin: 8px 8px;

    .ant-modal-close-x {
      width: 24px;
      height: 24px;
      line-height: 24px;

      span.ant-modal-close-icon {
        vertical-align: middle;
      }
    }
  }

  .ant-modal-body {
    overflow: auto;
  }
}
</style>
