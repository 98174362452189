import axios from 'axios';
import filenamify from 'filenamify';
import { paramFilter } from '@/utils';

export function download(csvUrl, parameters, option = { strict: true }) {
  if (typeof csvUrl !== 'string') return Promise.resolve({});

  const responseType = 'blob';
  const params = option.strict ? paramFilter(parameters) : parameters;
  const url = `${csvUrl.startsWith('/') ? '' : '/'}${csvUrl}`;

  return axios.request({ url, params, responseType }).then(async res => {
    let link = document.createElement('a');
    const blob = new Blob([res.data], { type: res.data.type });
    const filename = getCsvFileName(res.headers);

    if (window.navigator?.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
      return;
    }

    const objectURL = window.URL.createObjectURL(blob);
    link.style.display = 'none';
    link.setAttribute('href', objectURL);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(objectURL);
    document.body.removeChild(link);
    link = null;
  });
}

function getCsvFileName(headers) {
  const disposition = decodeURI(headers['content-disposition']);
  let fileName = disposition?.match(/filename=(.+)/)?.[1];
  return fileName ? filenamify(fileName, { replacement: '-' }) : `${Date.now()}.csv`;
}
