<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <AInput v-bind="passwordAttrs" type="password" class="n-input-password" v-on="passwordListeners">
      <template #addonAfter>
        <NButton v-if="!!disabled" disabled>
          <template #icon><LockOutlined /></template>
        </NButton>
        <NButton v-else-if="!!lockDisabled" disabled>
          <template #icon>
            <LockOutlined v-show="mLock" />
            <UnlockOutlined v-show="!mLock" />
          </template>
        </NButton>
        <NButton v-else @click="onToggleLock">
          <template #icon>
            <LockOutlined v-show="mLock" />
            <UnlockOutlined v-show="!mLock" />
          </template>
        </NButton>
      </template>
    </AInput>
  </NFormItem>
</template>

<script>
import { Input as AInput } from 'ant-design-vue/es';
import { computed, onMounted, ref, defineComponent } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';

export default defineComponent({
  name: 'NInputPassword',

  components: { AInput },

  inheritAttrs: false,

  props: {
    label: { type: String, default: 'password' },
    name: [String, Array],
    customLabel: String,
    lock: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    lockDisabled: { type: Boolean, default: false },
  },

  setup(props, { attrs, emit }) {
    const { formItemBaseAttrs } = useFormItemBaseAttrs(props);

    const mLock = ref(props.lock);

    const passwordAttrs = computed(() => {
      return {
        ...attrs,
        visibilityToggle: false,
        disabled: !!props.disabled || !!mLock.value,
        autocomplete: 'new-password',
      };
    });

    const passwordListeners = computed(() => {
      return {
        change: e => {
          emit('update:value', e?.target?.value ?? e);
        },
      };
    });

    onMounted(() => {
      if (mLock.value === false && attrs.value !== '') emit('update:value', ''); // add时需要给定''值，避免直接触发validate
    });

    const onToggleLock = async () => {
      mLock.value = !mLock.value;
      await emit('toggleLock', mLock.value); // 必须先emit toggleLock，然后再emit update:value，避免rule还没有切换完成，值已经改变，就无法正确触发validate
      await emit('update:value', mLock.value ? undefined : '');
    };

    return { formItemBaseAttrs, passwordAttrs, passwordListeners, mLock, onToggleLock };
  },
});
</script>

<style lang="less">
.n-input-password {
  .ant-input-group-addon {
    width: 32px;
    padding: 0;
    background-color: #fafafa;

    & > button.ant-btn {
      width: 32px;
      height: 100%;
      border: 0;
      background-color: #fafafa;

      span.anticon {
        font-size: 13px;
        margin-left: 0;
      }

      &[disabled] {
        color: @disabled-color;
        background-color: @disabled-bg;
      }
    }
  }
}

.ant-form-item-has-error .n-input-password .ant-input-disabled {
  color: @error-color !important;
  border-color: @error-color !important;
}
</style>
