import { NMessage } from '@/components';
import { mySentry } from '@/monitor';

export function defaultHandler(err, vm, info) {
  const message = 'ErrorHandler: Unknown Error';
  console.error(message, err, vm, info);
  mySentry?.captureErrorMessage(message, err);

  NMessage.error({ content: 'Unknown Error' });
  return false;
}
