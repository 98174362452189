<template>
  <div id="n-panel-box">
    <component :is="item.name" v-for="item in panelList" :id="item.id" :key="item.id" :extra="item.extra" />
  </div>
</template>

<script setup>
import { usePanel } from '@/hooks';

const { panelList } = usePanel();
</script>

<script>
export default {
  name: 'NPanelBox',
};
</script>
