<template>
  <div class="n-channel-switch-btn">
    <div class="c-icon">
      <i class="fa fa-link" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NChannelSwitchBtn',
};
</script>

<style lang="less">
.n-channel-switch-btn {
  @apply grid grid-flow-col items-center gap-1 cursor-pointer auto-cols-max;

  .c-icon {
    @apply flex items-center justify-center bg-gray-600 text-white rounded;
    font-size: 12px;
    width: 24px;
    height: 20px;
  }

  &.theme-white {
    .c-icon {
      @apply !bg-white !text-gray-800;
    }
  }
}
</style>
