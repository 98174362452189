import { default as _useClipboard } from 'vue-clipboard3';
import { NMessage } from '@/components/ant-design-vue';
import { useLanguage } from './useLanguage';

export function useClipboard(config = {}) {
  const { toClipboard: _toClipboard } = _useClipboard();
  const { t, PREFIX } = useLanguage();

  async function toClipboard(text, showToast = true) {
    await _toClipboard(text);
    if (showToast) {
      NMessage.success({
        content: t('copyToClipboard', PREFIX.SUCCESS),
      });
    }
  }

  return {
    toClipboard,
  };
}
