<template>
  <NInput class="n-input-number" :value-pattern="inputableNumberRegex" v-bind="$attrs" />
</template>

<script setup>
import { inputableNumberRegex } from '@/constant';
</script>

<script>
export default {
  name: 'NInputNumber',
};
</script>
