import { computed, ref } from 'vue';
import { storage } from '@/services';
import { BACK_TOP_KEY } from '@/constant';
import { useLanguage } from './useLanguage';

const backTopVisible = ref();

const backTopCoordinate = ref();

const backTopVisibilityHeight = ref();

export const useBackTop = (config = {}) => {
  backTopVisible.value = storage.get(BACK_TOP_KEY)?.visible ?? true;

  backTopCoordinate.value = storage.get(BACK_TOP_KEY)?.coordinate ?? { x: 130, y: 160 };

  backTopVisibilityHeight.value = storage.get(BACK_TOP_KEY)?.visibilityHeight ?? 400;

  const { tl } = useLanguage();

  const backTopVisibilityHeightOptions = computed(() => {
    const px = tl('px');
    return [
      { value: 100, label: `100 ${px}` },
      { value: 400, label: `400 ${px}` },
      { value: 800, label: `800 ${px}` },
    ];
  });

  const setBackTopVisible = visible => {
    backTopVisible.value = visible;
    saveBackTop();
  };

  const setBackTopCoordinate = coordinate => {
    backTopCoordinate.value = coordinate;
    saveBackTop();
  };

  const setBackTopVisibilityHeight = visibilityHeight => {
    backTopVisibilityHeight.value = visibilityHeight;
    saveBackTop();
  };

  const saveBackTop = () => {
    storage.set(BACK_TOP_KEY, {
      visible: backTopVisible.value,
      coordinate: backTopCoordinate.value,
      visibilityHeight: backTopVisibilityHeight.value,
    });
  };

  const clearBackTopCache = () => storage.remove(BACK_TOP_KEY);

  return {
    backTopVisible,
    backTopCoordinate,
    backTopVisibilityHeight,
    backTopVisibilityHeightOptions,
    setBackTopVisible,
    setBackTopCoordinate,
    setBackTopVisibilityHeight,
    clearBackTopCache,
  };
};
