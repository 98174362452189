export default {
  /* A */
  add: 'Add',
  all: 'ALL',
  admin: 'Admin',
  apply: 'Apply',
  agent: 'Agent',
  agents: 'Agents',
  appKey: 'App Key',
  avatar: 'Avatar',
  amount: 'Amount',
  action: 'Action',
  agentId: 'Agent',
  approve: 'Approve',
  activate: 'Activate',
  autoSync: 'Auto Sync',
  approval: 'Approval',
  approved: 'Approved',
  allCache: 'All Cache',
  activated: 'Activated',
  accountId: 'Account ID',
  appSecret: 'App Secret',
  agentName: 'Agent Name',
  amountInfo: 'Amount Info',
  addressInfo: 'Address Info',
  autoChannel: 'Auto',
  addressDetail: 'Address',
  accountAmount: 'Account Amount',
  approvalStatus: 'Approval Status',
  accountCurrency: 'Account Currency',
  alreadyLoggedIn: 'Already Logged In',
  approvalProcess: 'Approval Process',
  approveAndWithdraw: 'Approve And Withdraw',
  alreadyHaveAccount: 'Already have account?',
  appliedDate: 'Submitted Date',
  approvalComment: 'Approval Comment',
  allSelectedCount: 'ALL ({count})',
  accountActivate: 'Account Activate',
  accountActivated: 'Account Activated',
  actualAccountAmount: 'Actual Account Amount',
  actualDepositAmount: 'Actual Deposit Amount',
  accountDepositAmount: 'Deposit Amount',
  accountWithdrawalAmount: 'Withdrawal Amount',
  apiResultMsg: 'Api Result Msg',
  android: 'Android',
  androidLink: 'Android Link',
  accountInfo: 'Account Info',
  activateRealAccount: 'Activate real trading account',

  /* B */
  back: 'Back',
  bank: 'Bank',
  branch: 'Branch',
  backTo: 'Back to',
  bankNo: 'Bank No',
  backTop: 'Back Top',
  balance: 'Balance',
  bankCard: 'Bank Card',
  bordered: 'Bordered',
  basicInfo: 'Basic Info',
  batchSize: 'Batch Size',
  backToLogin: 'Back to login',
  backToPrevious: 'Back to Previous',
  backToPreviousStep: 'Previous Step',
  backToRegister: 'Back to register',
  bankBranch: 'Bank Branch',
  bankAccount: 'Bank Account',
  baseCurrency: 'Base Currency',
  branchAddress: 'Branch Address',
  bankAccountName: 'Bank Account Name',
  bankNameOrNetwork: 'Bank Name / Network',
  bankCardInfo: 'Bank Card Info',
  bankCardId: 'Bank Card ID',

  /* C */
  csv: 'CSV',
  copy: 'Copy',
  check: 'Check',
  close: 'Close',
  cancel: 'Cancel',
  create: 'Create',
  confirm: 'Confirm',
  checked: 'Checked',
  comment: 'Comment',
  countryOrRegion: 'Country / Region',
  created: 'Created',
  channel: 'Line',
  closeAll: 'Close All',
  customer: 'Customer',
  copyLink: 'Copy Link',
  countries: 'Countries',
  createdBy: 'Created By',
  childType: 'Contact Child Type',
  cancelDate: 'Cancel Date',
  cardholder: 'Cardholder',
  customerId: 'Customer ID',
  configName: 'Name',
  configDesc: 'Desc',
  cardDetail: 'Card Detail',
  conversion: 'Conversion',
  changeAgent: 'Change Agent',
  cashflowKid: 'Cashflow K-ID',
  channelName: 'Channel Name',
  configValue: 'Value',
  createdDate: 'Created Date',
  currencyType: 'Currency Type',
  channelError: 'Line Error',
  customerInfo: 'Customer Info',
  customerData: 'Customer Data',
  currentCache: 'Current Cache',
  closeAllTabs: 'Close All Tabs',
  createdBefore: 'Created Before',
  cryptoNetwork: 'Network',
  clearAllCache: 'Clear All Cache',
  contactContent: 'Contact Content',
  forgotPassword: 'Forgot Password',
  changePassword: 'Change Password',
  customerVerify: 'Customer Verify',
  closeOtherTabs: 'Close Other Tabs',
  closeRightTabs: 'Close Right Tabs',
  createAccounts: 'Create Accounts',
  certificateNo: 'Certificate No',
  confirmationMin: 'Confirmation Min',
  confirmPassword: 'Confirm Password',
  closeCurrentTab: 'Close Current Tab',
  chineseSimplified: '中文（简体）',
  customerFeeAmount: 'Fee',
  customerCurrencies: 'Customer Currencies',
  changePwdSucceess: 'Password is successfully changed',
  customerDepositFlow: 'Customer Deposit Flow',
  customerVerifyFlowId: 'Customer Verify Flow Id',
  customerIdentificationId: 'Identification ID',
  cancelTransaction: 'Cancel Transaction',
  cancelTransactionPopupTitle: 'This action is irreversible. Are you sure to completed this transfer?',
  captchaImageErrorMsg: 'Error occurred, click to reload',
  captchaText: 'Check Code',
  completed: 'Completed',
  confirmDetail: 'Confirm Detail',
  confirmProof: 'Confirm Proof',
  confirmComplete: 'Confirm Complete',
  confirmRecvPopupTitle: 'This action is irreversible. Please confirm you have received this transfer',
  confirmTransaction: 'Confirm Transaction',
  confirmTransactionPopupTitle: 'This action is irreversible. Please confirm you have completed this transfer?',
  currentChannelNotAvailableAndSetAsAuto: 'Current line is not available, aready switch to Auto',

  /*D*/
  dob: 'Date of Birth',
  desc: 'Desc',
  detail: 'Detail',
  details: 'Details',
  deposit: 'Deposit',
  depositHistory: 'Deposit History',
  download: 'Download',
  demoSystem: 'Demo',
  demoAppKey: 'Demo App Key',
  description: 'Description',
  demoAppSecret: 'Demo App Secret',
  depositAmount: 'Deposit Amount',
  depositDetail: 'Deposit Detail',
  depositVerify: 'Deposit Verify',
  depositRemark: 'Deposit Remark',
  depositFlowId: 'Deposit ID',
  depositCurrency: 'Deposit Currency',
  depositApproval: 'Deposit Approval',
  depositInfo: 'Deposit Info',

  /* E */
  en: 'English',
  env: 'Env',
  edit: 'Edit',
  enum: 'Enum',
  empty: 'Empty',
  enable: 'Enable',
  enabled: 'Enabled',
  existing: 'Existing',
  equalZero: 'Equal zero',
  expandAll: 'Expand All',
  emailDetail: 'Email Detail',
  engFullName: 'English Full Name',
  engShortName: 'English Short Name',
  emailVerify: 'Email Verify',
  emailVerified: 'Email Verified',
  encryptedId: 'Encrypted ID',
  enableVerification: 'Enable Verification',
  exclusiveAccountId: 'Exclusive Account ID',
  expectedWithdrawalAmount: 'Expected Withdrawal Amount',

  /* F */
  fee: 'Fee',
  force: 'Force',
  fxRate: 'FX Rate',
  failure: 'Failure',
  feeInfo: 'Fee Info',
  flowInfo: 'Flow Info',
  fullName: 'Full Name',
  forceCancel: 'Force Cancel',
  familyNameEn: 'Family Name',
  forceConfirm: 'Force Confirm',
  frozenAmount: 'Frozen Amount',
  fxCurrencyPair: 'FX Currency Pair',
  fxRateTemplate: 'FX Rate Template',
  fatcaSignedDate: 'Fatca Signed Date',
  fxRateTemplateId: 'FX Rate Template ID',
  forgotNoticeTitle1: 'Password retrieval link has been sent to email',
  forgotNoticeTitle2: 'Please log in to the email to retrieve it！',
  forgotNoticeTitle3: 'Retrieve the logged in email and click on Connect ? ',

  /* G */

  goRegister: 'Go to register',
  gotoVerify: 'Goto Verify',
  goDeposit: 'Go to deposit',
  goLogin: 'Please go to the application platform to log in',
  giinTaxNo: 'GIIN Tax No',
  guestForbidden: 'Disable guest login',
  givenNameEn: 'Given Name',
  greaterThan: 'Greater than {number}',
  greaterThanZero: 'Greater than zero',
  gatewayLoginUsername: 'Gateway Login Username',
  gatewayFeeAmount: 'Gateway Estimate Fee',

  /* H */
  hide: 'Hide',
  help: 'Help',
  hours: 'Hours',
  h5Link: 'H5 Trader Link',
  hasRemainingAmount: 'Has Remaining Amount',

  /* I */
  id: 'ID',
  it: 'Institution',
  itId: 'Institution',
  items: 'Items',
  itName: 'IT Name',
  inviter: 'Inviter',
  identity: 'Identity',
  itReceive: 'IT Receive',
  invalidUrl: 'Invalid URL',
  inputAmount: 'Input Amount',
  inputPaymentInfo: 'Input Payment Information',
  inputPaymentMethod: 'Trial calculation of amount',
  itReceiveAccount: 'IT Receive Account',
  itReceiveId: 'IT Receive ID',
  internetUser: 'Internet User',
  institutionId: 'Institution ID',
  identifyDetail: 'Identify Detail',
  identification: 'Identification',
  identityVerify: 'Identity Verify',
  itReceiveMethod: 'IT Receive Method',
  itReceiveNumber: 'IT Receive Number',
  identificationId: 'Identification ID',
  identificationType: 'Type',
  identificationInfo: 'Identification Info',
  identificationNumber: 'Number',
  identificationStep1Title: 'Personal Information',
  identificationStep1Name: 'Please enter the name (pinyin) displayed on your ID card',
  identificationStep2OnlyTaxInCountry: 'I only pay taxes in the aforementioned countries',
  identificationStep2SameBirthNationality: 'My birthplace and nationality are the same as those of the aforementioned countries',
  identificationStep2NonUSResident: 'I am not a US citizen or a US tax resident',
  identificationStep4Title: 'Investment Knowledge',
  identificationStep4Desc:
    'We need to evaluate your level of understanding of financial products. If the answer you provide is incorrect, it may result in your assessment failing. Please carefully consider your answer.',
  identificationStep4KlMaxInvestment:
    'You have injected HK $3000. The leverage ratio is 1:100. What is the maximum position value that you can trade (after using leverage)?',
  identificationStep4KlTrigger: 'If a stop loss instruction is triggered',
  identificationStep4KlAutoClose:
    'My uncleared position may automatically close in the following situations (such as adding margin)',
  identificationStep5Title1: 'Appropriateness Assessment',
  identificationStep5Title2: 'Trading Experience',
  identificationStep5Desc:
    'We need to evaluate your understanding of financial products based on trading experience. If the answer you provide is incorrect, it may result in your assessment failing. Please carefully consider your answer.',
  identificationStep5AxTradingCount:
    'What trading experience have you had in the past year? (without professional advice) Stocks, bonds, commodities',
  identificationStep5AxOtcCount: 'OTC derivative instruments (leveraged CFDs, foreign exchange, spread, spread trading)',
  identificationStep5AxOtcLeverage:
    'On average, what is the leverage used to trade OTC over-the-counter derivatives mentioned above?',
  identificationStep6Title: 'Experiences',
  identificationStep6ExpHasFinanceEducation:
    'Have you had on-the-job education, professional qualifications, and/or work experience in the financial services industry in the past 3 years (in positions that require understanding of leveraged trading)?',
  identificationStep6ExpFinanceEducation:
    'Please provide more information about your educational background, professional qualifications, and/or work experience in the financial services industry',
  identificationStep7Title: 'Employment Information',
  identificationStep7Occupation: 'How is your employment situation?',
  identificationStep7Industry: 'What industry do you work in?',
  identificationStep8Title: 'Financial Information',
  identificationStep8finAnnualIncome: 'Yearly income',
  identificationStep8finSavingsInvestment: 'Savings and investments (excluding property)',
  identificationStep8finIncomeSource: 'The main source of transaction funds',
  identificationStep8maxInvestmentLoss: 'The approximate amount of trading that you can bear the risk of loss each year',
  identificationStep9Title: 'Transaction Overview',
  identificationStep9TradingFrequency: 'How often do you expect to trade?',
  identificationStep9DepositMethod: 'How will you fund your account?',
  identificationStep9AcknowledgeTradingRisk:
    'I understand and confirm that the nature of my trading at G.H.F is to buy and sell contract for difference products of various underlying assets. I understand that checking prices is not suitable for everyone, only someone like me with high risk tolerance can conduct transactions.',
  identificationStep10Title: 'Terms And Conditions',
  identificationStep10ConsentAgreement: "I have carefully read and accept G.H.F's usage agreement",
  identificationStep10Agreement1: 'Summary Order Execution Policy',
  identificationStep10Agreement2: 'Risk Disclosure Notice',
  identificationStep10Agreement3: 'Website And Platform Privacy Policy',
  identificationStep10Agreement4: 'Key Information Document',
  isExclusive: 'Is Exclusive',
  ios: 'IOS',
  iosLink: 'IOS Link',
  ifsc: 'IFSC Key',

  /* J */
  jurisdiction: 'Jurisdiction',

  /* K */
  kv: 'KV',
  key: 'Key',

  /* L */
  list: 'List',
  lock: 'Lock',
  line1: 'Line 1',
  line2: 'Line 2',
  login: 'Login',
  logout: 'Logout',
  locked: 'Locked',
  loginId: 'Login ID',
  language: 'Language',
  lockTime: 'Lock Time',
  liveSystem: 'Live',
  loginQuick: 'Login With',
  loginUsername: 'Enter Your Account (Email)',
  loginPassword: 'Enter Your Password',
  lastModifiedBy: 'Last Modified By',
  lastModifiedDate: 'Last Modified Date',
  lastAutoSyncTime: 'Last Auto Sync Time',
  lookBackInterval: 'Look Back Interval',
  localDepositAmount: 'Deposit Amount (Local)',

  /* M */
  mark: 'Mark',
  more: 'More',
  mobile: 'Mobile',
  minute: 'Minute',
  minutes: 'Minutes',
  merchantName: 'Merchant Name',
  middleNameEn: 'Middle Name',
  mobileDetail: 'Mobile Detail',
  manualRefresh: 'Manual Refresh',
  myReceiveAccount: 'My Receive Account',
  myReceiveBank: 'My Receive Bank',
  myReceiveNumber: 'My Receive Number',
  maxAutoConfirmAmount: 'Max Auto Confirm Amount',
  minTransactionAmount: 'Min Transaction Amount',
  maxTransactionAmount: 'Max Transaction Amount',
  macX86Desktop: 'Mac(x86) Desktop',
  macX86DesktopLink: 'Mac(x86) Desktop Link',
  macArmDesktop: 'Mac(Arm) Desktop',
  macArmDesktopLink: 'Mac(Arm) Desktop Link',

  /* N */
  no: 'No',
  name: 'Name',
  number: 'Number',
  notEnabled: 'Not Enabled',
  notifyLang: 'Notify Language',
  notNecessary: 'Not Necessary',
  networkUrls: 'Network URLs',
  newPassword: 'New Password',
  neverExpire: 'Never Expire',
  noDataFound: 'No Data Found',
  notifyEmail: 'Notify Email',
  nextStep: 'Next',
  noAccount: 'No account yet?',
  noTransactionHint: 'No transaction for now, please be patient',
  noAvailableChannel: 'No available line, please try later',

  /* O */
  on: 'ON',
  or: 'or',
  off: 'OFF',
  operator: 'Operator',
  operation: 'Operation',
  oldPassword: 'Old Password',
  openRealAccount: 'Open a real trading account',
  openVirtualAccount: 'Open a virtual trading account and deposit',

  /* P */
  px: 'px',
  proof: 'Proof',
  people: 'People',
  profile: 'Profile',
  process: 'Process',
  payType: 'Pay Type',
  pageSize: 'Page Size',
  province: 'Province',
  password: 'Password',
  postCode: 'Postcode',
  processed: 'Processed',
  processes: 'Processes',
  profession: 'Profession',
  plzRelogin: 'Please re-login',
  phoneDetail: 'Phone',
  parentAgent: 'Parent Agent',
  preconditions: 'Preconditions',
  paymentChannel: 'Payment Channel',
  paymentGateway: 'Payment Gateway',
  paymentRouting: 'Payment Routing',
  processComplete: 'Process Complete',
  paymentRoutingId: 'Payment Routing ID',
  passwordResetNoticeTitle: 'Password reset done',
  passwordResetFailTitle: 'Password reset fail, forward to',
  paymentCurrency: 'Payment Currency',
  pleaseChooseItReceiveAccount: 'Receive Account',
  pleaseChoosePayment: 'Payment Account',
  pleaseSignIn: 'Please Sign In',
  pleaseSignUp: 'Please Sign Up',
  pleaseVerifySoon: 'Please verify your account soon',
  previousStep: 'Previous',
  planDepositAmount: 'Plan Deposit Amount',
  paymentFeeModel: 'Payment Fee Model',
  paymentFeeValue: 'Payment Fee Value',
  paymentFeeCurrency: 'Payment Fee Currency',
  plzReloginWithNewPwd: 'Please re-login with new password',
  paymentFeeInfo: 'Payment Fee Info',
  paymentOrderStatus: 'Payment Order Status',
  providerType: 'Provider Type',
  pleaseSelectApprovalStatus: 'Please select approvalStatus',
  paymentFeeAmount: 'Payment Estimate Fee',
  processing: 'Processing',
  pixType: 'PIX Type',
  pixKey: 'PIX Key',
  payPurpose: 'Pay Purpose',

  /* Q */
  qrCode: 'QR Code',
  quoteCurrency: 'Quote Currency',

  /* R */
  rate: 'Rate',
  role: 'Role',
  roles: 'Roles',
  reset: 'Reset',
  region: 'Region',
  result: 'Result',
  roleId: 'Role',
  remove: 'Remove',
  reject: 'Reject',
  reason: 'Reason',
  receive: 'Receive',
  refresh: 'Refresh',
  retract: 'Retract',
  regDate: 'Reg Date',
  register: 'Register',
  receiver: 'Receiver',
  resource: 'Resource',
  retryMail: 'ReSend',
  receiverId: 'Receiver ID',
  refreshAll: 'Refresh All',
  regCustomer: 'Register Customer',
  rateDetails: 'Rate Details',
  receiverName: 'Receiver Name',
  receiverRole: 'Receiver Role',
  receiveMethod: 'Receive Method',
  receiveNumber: 'Number',
  receiverNumberCpf: 'Number',
  receiverNumberPhone: 'Phone',
  receiverNumberEmail: 'Email',
  receiverNumberEvp: 'Virtual Payment Address',
  resetPassword: 'Reset Password',
  receiverAccount: 'Receiver Account',
  receiverNumber: 'Receiver Number',
  receiveCurrency: 'Currency',
  receiverNetwork: 'Receiver Network',
  remainingAmount: 'Remaining Amount',
  recoverPassword: 'Recover Password',
  receivingCurrency: 'Receiving Currency',
  registerNoAgentAlert: 'Please Contact Your Agent For Register Link',
  registerNoticeTitle: 'Please activate your account through email',
  registerVerifyTitle1: 'Register was successful',
  registerVerifyTitle2: 'Please',
  resendActiveMail: 'Resend Active Mail',
  recoverPasswordEnter: 'Enter your email',
  restoreDefaultSettings: 'Restore Default Settings',
  receiverNumberOrAddress: 'Receiver Number / Address',
  registerOtherWay: 'Register other way',
  registerAgreementTitle1: 'I have agree',
  registerAgreementTitle2: 'User Agreement',
  registerPleaseAgree: 'Please agree to the agreement',
  receiverExtConfig: 'Receiver Extra Config',
  relatedId: 'Related ID',

  /* S */
  sex: 'Sex',
  show: 'Show',
  step: 'Step',
  sync: 'Sync',
  send: 'Send',
  synced: 'Synced',
  source: 'Source',
  status: 'Status',
  search: 'Search',
  sender: 'Sender',
  social: 'Social',
  signUp: 'Sign Up',
  safety: 'Safety',
  stopHinting: 'Stop Reminding',
  submit: 'Submit',
  setting: 'Setting',
  success: 'Success',
  settings: 'Settings',
  senderId: 'Sender ID',
  secretKey: 'Secret Key',
  selectAll: 'Select ALL',
  superUser: 'Super User',
  shortName: 'Short Name',
  searchKey: 'Search Key',
  shareCode: 'Share Code',
  subAgents: 'Sub-Agents',
  syncAgent: 'Sync Agent',
  senderRole: 'Sender Role',
  successMin: 'Success Min',
  signedDate: 'Signed Date',
  signedStatus: 'Signed Status',
  socialDetail: 'Social Detail',
  systemConfig: 'System Config',
  selectedCount: 'Selected ({count})',
  systemFeeModel: 'System Fee Model',
  systemFeeValue: 'System Fee Value',
  syncInstitution: 'Sync Institution',
  settlementCurrency: 'Settlement Currency',
  statusInfo: 'Status Info',
  sourceAgentId: 'Source Agent',
  senderExtConfig: 'Sender Extra Config',

  /* T */
  tw: '中文（繁體）',
  tabs: 'Tabs',
  type: 'Type',
  table: 'Table',
  token: 'Token',
  total: 'Total',
  target: 'Target',
  taxNo: 'Tax No',
  txHash: 'Transaction Hash',
  toRefId: 'To Ref ID',
  tabLimit: 'Tab Limit',
  transfer: 'Transfer',
  tinTaxNo: 'Tin Tax No',
  toRefBank: 'To Ref Bank',
  testNetwork: 'Test Network',
  tax: 'Tax',
  taxDetail: 'Tax Detail',
  taxFormType: 'Tax Form Type',
  taxFormDate: 'Tax Form Date',
  taxNoExpireDate: 'TaxNo Expire Date',
  transaction: 'Transaction',
  transactionProof: 'Transaction Proof',
  tronScanUrl: 'Tron Scan URL',
  toRefNumber: 'To Ref Number',
  timedRefresh: 'Timed Refresh',
  tokenExpired: 'Token expired',
  targetAgentId: 'Target Agent',
  transferAmount: 'Transfer Amount',
  transferFromIt: 'Transfer from institution',
  tradingPassword: 'Trading Password',
  transferConfirm: 'Confirm',
  transferDetails: 'Transfer Details',
  transactionModel: 'Transaction Model',
  transactedAmount: 'Transacted Amount',
  transactionCategory: 'Transaction Category',
  transferNotifyTimeLimit: 'Transfer Notify Time Limit',

  /* U */
  userId: 'User ID',
  upload: 'Upload',
  unitCny: 'CNY',
  username: 'User Name',
  unlocked: 'Unlocked',
  unverify: 'Unverify',
  unchecked: 'Unchecked',
  unprocess: 'Unprocess',
  uncreated: 'Uncreated',
  unapprove: 'Unapprove',
  usCitizen: 'Us Citizen',
  usResident: 'Us Resident',
  usTaxPayers: 'Us TaxPayers',
  usdFeeAmount: 'System Fee',
  usBeneficiary: 'Us Beneficiary',
  usCompany: 'Us Company',
  unprocessed: 'Unprocessed',
  unactivated: 'Unactivated',
  uploadImage: 'Upload Image',
  uploadProof: 'Upload Proof',
  uploadLimitation: '{types} not more than {maxSize}M',
  upstreamPaymentChannel: 'Upstream Payment Channel',
  unsupportedBrowserTitle: 'Unsupported Browser',
  unsupportedBrowserContent1: 'Please upgrade your browser to latest.',
  unsupportedBrowserContent2: '(Suggestion: Chrome 90+, Safari 14+, Firefox 80+)',

  /* V */
  value: 'Value',
  verify: 'Verify',
  verify2: 'Verify',
  version: 'Version',
  validUrl: 'Valid URL',
  verified: 'Verified',
  verifyId: 'Verify ID',
  verifyInfo: 'Verify Info',
  verifyName: 'Verify Name',
  verifyApproval: 'Verify Approval',
  visibilityHeight: 'Visibility Height',

  /* W */
  welcome: 'Welcome',
  withdraw: 'Withdraw',
  withdrawHistory: 'Withdraw History',
  workflow: 'Workflow',
  withdrawal: 'Withdrawal',
  waiverType: 'Waiver Type',
  withdrawalVerify: 'Withdrawal Verify',
  withdrawalAmount: 'Withdrawal Amount',
  withdrawalAmountLocal: 'Withdrawal Amount (Local)',
  withdrawApproval: 'Withdraw Approval',
  withdrawalDetail: 'Withdrawal Detail',
  withdrawalFlowId: 'Withdrawal ID',
  withdrawalInfo: 'Withdrawal Info',
  windowsDesktop: 'Windows Desktop',
  windowsDesktopLink: 'Windows Desktop Link',
  web: 'Web Trader',
  webLink: 'Web Trader Link',

  /* X */

  /* Y */
  yes: 'Yes',
  youWillTransferForAboveAccountsSeparately: 'You will transfer for above accounts separately',

  /* Z */
  cn: '中文（简体）',

  /* sender info */
  receiverInfo: 'Receiver Info',
  surName: 'Sur Name',
  givName: 'Giv Name',
  middleName: 'Middle Name',
  otherName: 'Other Name',
  address: 'Address',
  area: 'Area',
  phone: 'Phone',
  nationality: 'Nationality',
  idType: 'ID Type',
  idNumber: 'ID Number',
  locationId: 'Location ID',
  bankId: 'Bank ID',
  bankName: 'Bank Name',
  bankBranchName: 'Bank Branch Name',
  bankBranchCode: 'Bank Branch Code',
  bankBranchId: 'Bank Branch ID',
  accountNo: 'Account No',
  occupation: 'Occupation',
  bankMobile: 'Bank Mobile',
  issueDate: 'Issue Date',
  expireDate: 'Expire Date',
  licIssuAutho: 'Lic Issu Autho',
  remark: 'Remark',
  accountType: 'Account Type',
  city: 'City',
  states: 'States',
  zipCode: 'Zip Code',
  country: 'Country',
  email: 'Email',
  accountNumber: 'Account Number',
  phoneCountry: 'Phone Country',
  accountCity: 'Account City',
  accountCountry: 'Account Country',
  currency: 'Currency',
  userName: 'User Name',
  birthday: 'Birthday',
  postalCode: 'Postal Code',
  provinceState: 'Province State',
  cardNumber: 'Card Number',
  nativeFirstName: 'Native First Name',
  nativeLastName: 'Native Last Name',
  remitterType: 'Remitter Type',
  ibanNo: 'Iban No',
  accountAddress: 'Account Address',
  routingType: 'Routing Type',
  senderBusiness: 'Sender Business',
  registeredName: 'Registered Name',
  tradingName: 'Trading Name',
  msisdn: 'Msisdn',
  registrationNumber: 'Registration Number',
  dateOfIncorporation: 'Date Of Incorporation',
  purposeOfRemittance: 'Purpose Of Remittance',
  documentReferenceNumber: 'Document Reference Number',
  taxId: 'Tax ID',
  sourceOfFund: 'Source Of Fund',
  countryCode: 'Country code',
  transactionType: 'Transaction Mode',
  channelCode: 'Channel number',
  beneficiaryRelationShip: 'Beneficiary RelationShip',
  gender: 'Gender',
  otherDownloads: 'Other Downloads',
  verifyNotice1: 'Email verification successful! ',
  verifyNotice2:
    'Thank you for registering on our product---GHF. You can now use this account to log in to the login interface. If you have any questions, please feel free to contact our customer service team at https://wa.me/13234169593',
  verifyNotice3: 'Wish you good use !',
  depositNotice1: 'Tip:',
  depositNotice2: 'A minimum top-up of',
  depositNotice3: 'R$300',
  depositNotice4: ' is required to trade, other small amounts are for replenishment purposes',
  depositNotice5: ' WhatsApp:+5519995357056',
  liveRoom: 'Watch live broadcasts to learn how to make money',
};
