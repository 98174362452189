import { i18n } from '@/glue';
import { computed } from 'vue';
import { storage } from '@/services';
import { LANG_KEY, LANGUAGES } from '@/constant';

import en from 'ant-design-vue/es/locale/en_US';
import cn from 'ant-design-vue/es/locale/zh_CN';
import tw from 'ant-design-vue/es/locale/zh_TW';
import ja from 'ant-design-vue/es/locale/ja_JP';
import ko from 'ant-design-vue/es/locale/ko_KR';
import pt from 'ant-design-vue/es/locale/pt_BR';

import 'dayjs/locale/en';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/pt-br';

const SYSTEM = { ADMIN: 'ADMIN', FRONT: 'FRONT' };
const LOCALE_MAP = { cn, en, ja, ko, pt, tw };

const BROWSER_LANG_CN = { 'zh': 'cn', 'zh-hans': 'cn', 'zh-cn': 'cn', 'zh-sg': 'cn' };
const BROWSER_LANG_EN = { 'en': 'en' };
const BROWSER_LANG_JA = { 'ja': 'ja', 'ja-jp': 'ja' };
const BROWSER_LANG_KO = { 'ko': 'ko', 'ko-kr': 'ko', 'ko-kp': 'ko' };
const BROWSER_LANG_PT = { 'pt': 'pt', 'pt-br': 'pt' };
const BROWSER_LANG_TW = { 'zh-hant': 'tw', 'zh-tw': 'tw', 'zh-hk': 'tw', 'zh-mo': 'tw' };

const BROWSER_MAP = {
  [SYSTEM.ADMIN]: {
    ...BROWSER_LANG_CN,
    ...BROWSER_LANG_EN,
    ...BROWSER_LANG_TW,
  },
  [SYSTEM.FRONT]: {
    ...BROWSER_LANG_CN,
    ...BROWSER_LANG_EN,
    ...BROWSER_LANG_JA,
    ...BROWSER_LANG_KO,
    ...BROWSER_LANG_PT,
    ...BROWSER_LANG_TW,
  },
};

const PREFIX = {
  MENU: 'menu',
  LABEL: 'label',
  ENUMS: 'enums',
  SELECTION: 'selection',
  CONFIRM: 'confirm',
  MODAL: 'modal',
  PANEL: 'panel',
  PAGINATION: 'pagination',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  ERROR_CODE: 'errorCode',
  DASHBOARD: 'dashboard',
  MESSAGE: 'message',
};

// TODO Ken 2023-02-27 16:34 都是全局的, 不应该写成hook
export const useLanguage = () => {
  const options = LANGUAGES;

  // 只用于设置Antd [App.vue -> NConfigProvider]
  const curLocale = computed(() => LOCALE_MAP[i18n.global.locale || 'en']);

  const curLanguage = computed(() => options.find(o => o.value === i18n.global.locale) || options[0]);

  const curLang = computed(() => curLanguage.value.value);

  const isEn = computed(() => curLang.value === 'en');
  const isJa = computed(() => curLang.value === 'ja');
  const isKo = computed(() => curLang.value === 'ko');
  const isPt = computed(() => curLang.value === 'pt');

  const changeLanguage = _locale => {
    i18n.global.locale = _locale;
    storage.set(LANG_KEY, _locale);
  };

  const initLanguage = system => {
    const browserLanguage = navigator.language || navigator['browserLanguage'];
    const bl = storage.get(LANG_KEY) || BROWSER_MAP[system][browserLanguage.toLowerCase()] || 'en';
    i18n.global.locale = bl;
  };

  /**
   * i18n.te(type ? `${type}.${key}` : key)
   * example: 1) te('error.required')
   *          2) te('required', PREFIX.ERROR)
   *
   * @param key
   * @param type
   * @returns {*}
   */
  const te = (key, type) => i18n.global.te(type ? `${type}.${key}` : key);

  /**
   * i18n.tl(`label.${key}`, params)
   * example: 1) tl('name')
   *          2) tl('selectedCount', { count: 10 })
   *
   * @param key
   * @param params
   * @returns {*}
   */
  const tl = (key, params = {}) => translate(`label.${key}`, params);

  /**
   * i18n.t(type ? `${type}.${key}` : key)
   * example: 1) t('error.required')
   *          2) t('required', PREFIX.ERROR)
   *          3) t('duplicatedBy', PREFIX.ERROR, { target: 'name' })
   *
   * @param key
   * @param type
   * @param params
   * @returns {*}
   */
  const t = (key, type, params = {}) => translate(type ? `${type}.${key}` : key, params);

  const translate = (key, params) => (i18n.global.te(key) ? i18n.global.t(key, params) : key);

  const clearLanguageCache = () => storage.remove(LANG_KEY);

  return {
    SYSTEM,
    options,
    curLocale,
    curLanguage,
    curLang,
    isEn,
    isJa,
    isKo,
    isPt,
    changeLanguage,
    initLanguage,
    PREFIX,
    te,
    tl,
    t,
    clearLanguageCache,
  };
};
