import { NNotice } from '@/components';
import { mySentry } from '@/monitor';
import { SentryMessage } from '@/models';

export function sentryMessageHandler(err, vm, info) {
  if (!(err instanceof SentryMessage)) return true;

  console.warn('SentryMessage', err);
  mySentry?.captureMessage(err.message, { extra: err.extra }, err.level);

  if (import.meta.env.MODE === 'local_dev') {
    NNotice.warn({
      duration: null,
      message: `SentryMessage: ${err.message} [${err.level}]`,
      description: JSON.stringify(err.extra),
    });
  }
  return false;
}
