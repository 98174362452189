<template>
  <NButton class="n-add" ghost danger>
    <template #icon><PlusOutlined /></template>
    {{ $tl(text) }}
  </NButton>
</template>

<script setup>
defineProps({
  text: { type: String, default: 'add' },
});
</script>

<script>
export default {
  name: 'NAdd',
};
</script>

<style lang="less">
.n-add {
  &:hover {
    background: @error-color !important;
    color: @white !important;
  }
}
</style>
