import { h } from 'vue';
import { Modal } from 'ant-design-vue/es';
import { i18n } from '@/glue';
import { IgnoredMessage } from '@/models';

const tl = (key, params) => i18n.global.t(`label.${key}`, params);

export async function checkBrowser() {
  if (typeof Promise.any !== 'function') {
    Modal.error({
      title: tl('unsupportedBrowserTitle'),
      content: () => [h('div', tl('unsupportedBrowserContent1')), h('div', tl('unsupportedBrowserContent2'))],
      keyboard: false,
    });
    throw new IgnoredMessage('checkBrowserFailed');
  }
}
