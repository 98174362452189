<template>
  <span class="n-refresh-time">
    <div class="refresh-switch">
      <NCheckbox :checked="checked" @change="onChecked">
        {{ $tl('timedRefresh') }}
      </NCheckbox>
      <span v-if="checked && interval > 0">[{{ zeroFilling(countDown) }}]</span>
    </div>
    <span v-if="checked" class="refresh-time">
      <clock-circle-outlined />
      <div class="time">{{ time }}</div>
    </span>
  </span>
</template>

<script setup>
import { ref, watch, onUnmounted } from 'vue';

const props = defineProps({
  checked: { type: Boolean, default: false },
  interval: { type: Number, default: 0 },
});

const emit = defineEmits(['update:checked', 'refresh']);

let timer = null;
const time = ref();
let countDownTimer = null;
let countDown = ref(0);
const refreshInterval = ref(props.interval);

watch(
  () => props.checked,
  to => onRefreshInterval(to),
  { immediate: true },
);

onUnmounted(() => {
  clearInterval(countDownTimer);
  clearInterval(timer);
});

function onChecked($event) {
  const checked = $event.target.checked;
  refreshInterval.value = checked ? props.interval : 0;
  emit('update:checked', checked);
  if (checked) emit('refresh');
  onRefreshInterval();
}

function onRefreshInterval(checked = false) {
  if (checked && refreshInterval.value > 0) {
    countDown.value = refreshInterval.value / 1000;
    countDownTimer = setInterval(() => {
      countDown.value = countDown.value === 1 ? (countDown.value = refreshInterval.value / 1000) : countDown.value - 1;
    }, 1000);
    time.value = getNow();
    timer = setInterval(() => {
      time.value = getNow();
      emit('refresh');
    }, refreshInterval.value);
  } else {
    clearInterval(countDownTimer);
    countDown.value = 0;
    clearInterval(timer);
    time.value = '-';
  }
}

function getNow() {
  const now = new Date();
  const year = now.getFullYear();
  const month = zeroFilling(now.getMonth() + 1);
  const day = zeroFilling(now.getDay());
  const hour = zeroFilling(now.getHours());
  const minute = zeroFilling(now.getMinutes());
  const second = zeroFilling(now.getSeconds());
  return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
}

function zeroFilling(str) {
  return `${str}`.length === 1 ? `0${str}` : str;
}
</script>

<style lang="less">
.n-refresh-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;

  & > .refresh-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 6px;
    border-radius: 4px;

    &:hover {
      background-color: rgb(226 232 240);
    }

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      height: 100%;

      .ant-checkbox {
        display: flex;
        align-items: center;
        top: 0;
      }

      span {
        font-size: 12px;
      }
    }
  }

  & > .refresh-time {
    display: flex;
    align-items: center;
    height: 24px;

    svg {
      margin-right: 4px;
    }

    div.time {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 12px;
    }
  }
}
</style>
