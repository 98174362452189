import { ref, reactive } from 'vue';
import { Modal } from 'ant-design-vue/es';
import { API_PREFIX, pingChannel, i18n } from '@/glue';
import { CHANNEL_STORAGE_KEY } from '@/constant';
import { NNotice } from '@/components';
import { storage } from './storage';
import { setAxiosConfig } from './axios';
import { IgnoredMessage } from '@/models';

const tl = (key, params) => i18n.global.t(`label.${key}`, params);

export const AUTO_CHANNEL = 'auto';
export const channels = ref([]);
export const curChannel = reactive({ value: AUTO_CHANNEL, url: null });
export const isChannelsLoaded = ref(false);
export const isProxyMode = ref(false); // true: 显示切换线路功能, false: 不显示

/**
 * 初始化channel list时调用
 * 若当前channel在list中不存在, 则变为auto
 */
export async function setChannels(data = {}) {
  isChannelsLoaded.value = false;
  isProxyMode.value = false;
  const portal = data.portal;
  channels.value = data.channels || [];
  // 如果channel为空 或者 当前site与portal不一致(), 则不显示切换channel功能, 并且使用当前site做为proxy, 跳过后续逻辑
  const { hostname } = window.location;
  if (channels.value.length === 0 || (portal !== hostname && hostname !== 'localhost')) {
    setAxiosConfig({ baseURL: getBaseURL(null) });
  } else {
    isProxyMode.value = true;
    let channel = storage.get(CHANNEL_STORAGE_KEY) || AUTO_CHANNEL;
    // 以前保存的channel若无效, 则设置为 '自动'
    if (!channels.value.includes(channel)) {
      channel = AUTO_CHANNEL;
    }
    // 验证所选线路是否可用, 不可用时, 变为自动
    if (channel !== AUTO_CHANNEL) {
      try {
        await pingChannel(channel);
      } catch (error) {
        NNotice.warn({
          duration: null,
          message: tl('channelError'),
          description: tl('currentChannelNotAvailableAndSetAsAuto'),
        });
        channel = AUTO_CHANNEL;
      }
    }
    let url = channel;
    if (channel === AUTO_CHANNEL) {
      try {
        url = await getFastestChannel();
      } catch (error) {
        Modal.error({
          title: tl('channelError'),
          content: tl('noAvailableChannel'),
          keyboard: false,
        });
        throw new IgnoredMessage('noAvailableChannel');
      }
    }
    setChannel(channel, url);
  }
  isChannelsLoaded.value = true;
}

export function setChannel(channel, url = channel) {
  curChannel.value = channel;
  curChannel.url = url;
  storage.set(CHANNEL_STORAGE_KEY, channel);
  setAxiosConfig({ baseURL: getBaseURL(url) });
}

export async function getFastestChannel() {
  const promiseArr = channels.value.map(channel => pingChannel(channel).then(() => channel));
  return await Promise.any(promiseArr);
}

export function getBaseURL(url) {
  return (url ? (url.startsWith('http') ? url : `https://${url}`) : '') + API_PREFIX;
}
