<template>
  <NFormItem v-bind="formItemAttrs" class="n-image-edit-view">
    <div :class="{ disabled: imageDisabled }">
      <ASpin v-if="loading" class="upload-loading" />
      <NImage v-else v-bind="resourceAttrs" />
    </div>
    <AUpload v-bind="componentAttrs" class="n-image-upload" @change="handleChange">
      <NButton block>
        <template #icon><upload-outlined /></template>
        {{ $tl('upload') }}
      </NButton>
    </AUpload>
  </NFormItem>
</template>

<script>
import { isEmpty } from 'lodash-es';
import { useValidate } from '@/hooks';
import { onUploadError } from '@admin/utils';
import { errorHandler, storage } from '@/services';
import { ref, computed, defineComponent } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';
import { Upload as AUpload, Spin as ASpin } from 'ant-design-vue/es';

export default defineComponent({
  name: 'NImageEdit',

  components: { AUpload, ASpin },

  inheritAttrs: false,

  props: {
    label: { type: String, default: '' },
    name: [String, Array],
    customLabel: String,
    action: { type: String, default: '/api/admin/resource' },
    type: String,
    disabled: { type: Boolean, default: false },
  },

  setup(props, { attrs, emit }) {
    const { checkFileSuffixAndSize } = useValidate();

    const { formItemBaseAttrs } = useFormItemBaseAttrs(props);

    const uploadRef = ref();

    const loading = ref(false);

    const formItemAttrs = computed(() => ({
      ...formItemBaseAttrs.value,
      validateTrigger: '',
    }));

    const resourceAttrs = computed(() => ({
      src: attrs.value ? attrs.value : '/images/fallback_image.webp',
      fallback: '/images/fallback_image.webp',
      width: '100%',
      height: '100%',
    }));

    const componentAttrs = computed(() => ({
      ref: uploadRef,
      name: 'file',
      action: props.action,
      beforeUpload,
      headers: {
        token: storage.get('token'),
      },
      data: {
        type: props.type,
      },
      showUploadList: false,
      disabled: props.disabled,
    }));

    const imageDisabled = computed(() => isEmpty(attrs.value));

    const beforeUpload = file => {
      try {
        const idValid = checkFileSuffixAndSize(file);
        if (idValid) loading.value = true;
        return idValid;
      } catch (error) {
        uploadRef.value.$el.querySelector('input[type="file"]').value = '';
        errorHandler(error);
        return false;
      }
    };

    const handleChange = e => {
      try {
        const data = e?.file;
        if (data?.status === 'done') {
          const response = data?.response;
          loading.value = false;
          if (response?.success === false) onUploadError(response);
          else emit('done', data);
        }
      } catch (error) {
        errorHandler(error);
      }
    };

    return { formItemAttrs, resourceAttrs, componentAttrs, loading, imageDisabled, handleChange };
  },
});
</script>

<style lang="less">
.n-image-edit-view {
  .ant-image {
    display: block;
    border: 1px solid @border-color-base;
    border-bottom: 0 !important;
    padding: 4px;
  }

  .upload-loading {
    width: 100%;
    text-align: center;
    height: fit-content;
    font-size: 30px;
    border: 1px solid @border-color-base;
    border-bottom: 0 !important;
  }

  .disabled > .ant-image {
    pointer-events: none !important;
  }

  .n-image-upload {
    width: 100%;

    .ant-upload {
      width: 100%;

      button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &.ant-upload-disabled button {
        cursor: not-allowed;
        opacity: 0.6;

        &:hover {
          color: unset;
          border-color: unset;
        }
      }
    }
  }

  &.ant-form-item-has-error .ant-form-item-control-input-content {
    border: 1px solid red;
  }
}
</style>
