export default [
  {
    name: 'menu.system.title',
    path: '/system',
    icon: 'fas fa-cog',
    children: [
      {
        name: 'menu.system.config',
        path: '/config',
        meta: {
          keepAlive: true,
          cacheName: 'SystemConfig',
          hidden: ['ROLE_IT', 'ROLE_AGENT'],
        },
        component: () => import('../views/index/system/config/Config.vue'),
      },
      {
        name: 'menu.system.operation',
        path: '/operation',
        meta: {
          keepAlive: true,
          cacheName: 'SystemOperation',
          hidden: ['ROLE_IT', 'ROLE_AGENT'],
        },
        component: () => import('../views/index/system/operation/Operation.vue'),
      },
      {
        name: 'menu.system.cache',
        path: '/cache',
        meta: {
          keepAlive: true,
          cacheName: 'SystemCache',
          hidden: ['ROLE_IT', 'ROLE_AGENT'],
        },
        component: () => import('../views/index/system/cache/Cache.vue'),
      },
    ],
  },
];
