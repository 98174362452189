<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <BaseTextarea class="n-textarea" v-bind="componentAttrs" />
  </NFormItem>
</template>

<script setup>
import { computed, useAttrs } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';

const attrs = useAttrs();

const props = defineProps({
  label: String,
  name: [String, Array],
  customLabel: String,
  autoSize: { type: Object, default: () => ({ minRows: 2, maxRows: 5 }) },
});

const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);

const componentAttrs = computed(() => ({
  ...componentBaseAttrs.value,
  ...attrs,
  autoSize: props.autoSize,
}));
</script>

<script>
export default {
  inheritAttrs: false,
};
</script>
