<template>
  <div :id="`n-panel-${id}`">
    <!--window-->
    <div class="n-panel" :class="{ 'n-no-select n-will-change': isDragging }" :style="panelStyle" @click="onClickPanel">
      <div ref="headerRef" class="n-panel-header" @dblclick="onToggleFullscreen" @mousedown="onDragMouseDown">
        <NRow type="flex">
          <NCol style="flex: 1">
            <div v-if="!$slots.header" class="n-panel-header-title">
              {{ title }}
            </div>
            <slot name="header"></slot>
          </NCol>
          <NCol v-if="hasHeaderAction">
            <NRow type="flex">
              <NCol v-if="config.minimizable">
                <div class="n-panel-menu-action" @click="onMinimize">
                  <i class="far fa-window-minimize"></i>
                </div>
              </NCol>
              <NCol v-for="item in headerActions" :key="item.icon">
                <div class="n-panel-menu-action" @click="item.click">
                  <i :class="item.icon"></i>
                </div>
              </NCol>
              <NCol v-if="config.maximizable">
                <div class="n-panel-menu-action" @click="onToggleFullscreen">
                  <i class="fas" :class="isFullscreen ? 'fa-compress' : 'fa-expand'"></i>
                </div>
              </NCol>
              <NCol v-if="config.closeable">
                <div class="n-panel-menu-action" @click.stop="onClose()">
                  <i class="fas fa-times"></i>
                </div>
              </NCol>
            </NRow>
          </NCol>
        </NRow>
      </div>

      <div class="n-panel-body" :style="bodyStyle">
        <slot></slot>
      </div>

      <div
        v-if="hasFooter"
        ref="footerRef"
        class="n-panel-footer text-right"
        @dblclick="onToggleFullscreen"
        @mousedown="onDragMouseDown"
      >
        <slot name="footer"></slot>
      </div>

      <div v-if="config.resizable" class="resize-handler">
        <div class="n-panel-resize-handler n-panel-resize-handler-t" @mousedown.stop="onResizeMouseDown($event, 't')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-r" @mousedown.stop="onResizeMouseDown($event, 'r')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-l" @mousedown.stop="onResizeMouseDown($event, 'l')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-b" @mousedown.stop="onResizeMouseDown($event, 'b')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-tl" @mousedown.stop="onResizeMouseDown($event, 'tl')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-tr" @mousedown.stop="onResizeMouseDown($event, 'tr')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-bl" @mousedown.stop="onResizeMouseDown($event, 'bl')"></div>
        <div class="n-panel-resize-handler n-panel-resize-handler-br" @mousedown.stop="onResizeMouseDown($event, 'br')"></div>
      </div>
    </div>

    <!--minimized window  Ken 2020-01-07 19:54 be above of panel -->
    <NPanelMinimizedWindow v-if="config.minimized" :id="id" :style="{ left: `${leftOrder * 202}px`, zIndex: BASE_ZINDEX }">
      <div v-if="!hasHeader" class="n-panel-header-title">
        {{ title }}
      </div>
      <slot name="header" />
    </NPanelMinimizedWindow>
  </div>
</template>

<script setup>
import { findIndex, filter, sortBy } from 'lodash-es';
import { computed, useSlots, ref } from 'vue';
import { usePanel } from '@/hooks';
import NPanelMinimizedWindow from './NPanelMinimizedWindow.vue';

const props = defineProps({
  id: String,
  title: String,
  hasHeaderAction: { type: Boolean, default: true },
  headerActions: Array,
});

const slots = useSlots();

function GetClientBounding() {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return { width, height };
}

const VectorPair = [
  ['top', 'y'],
  ['left', 'x'],
  ['width', 'x'],
  ['height', 'y'],
];

// array order: top, left, width, height
// 1: add, 0: ignore, -1: mines
const DirectionConfig = {
  t: [1, 0, 0, -1],
  tl: [1, 1, -1, -1],
  tr: [1, 0, 1, -1],
  l: [0, 1, -1, 0],
  r: [0, 0, 1, 0],
  b: [0, 0, 0, 1],
  bl: [0, 1, -1, 1],
  br: [0, 0, 1, 1],
};

const OFFSET = 40; // limitation to edge

let isDragging = ref(false);
let isFullscreen = ref(false);
const BASE_ZINDEX = 800;

const { findPanel, panelList, closePanel, setAttrs: _setAttrs, moveToTop: _moveToTop } = usePanel();

const config = computed(() => findPanel({ id: props.id }) || {});
const leftOrder = computed(() => {
  const minimizedList = filter(panelList.value, { minimized: true });
  const sortedList = sortBy(minimizedList, ['displayOrder'], ['asc']);
  return findIndex(sortedList, { id: props.id });
});
const panelStyle = computed(() => ({
  left: `${config.value.left}px`,
  top: `${config.value.top}px`,
  display: config.value.minimized ? 'none' : 'block',
  zIndex: BASE_ZINDEX + config.value.displayOrder,
}));
const bodyStyle = computed(() => ({
  width: `${config.value.width}px`,
  height: `${config.value.height}px`,
}));
const hasHeader = computed(() => slots.header);
const hasFooter = computed(() => slots.footer);
const headerRef = ref(null);
const footerRef = ref(null);
const headerHeight = computed(() => headerRef.value?.clientHeight ?? 0);
const footerHeight = computed(() => footerRef.value?.clientHeight ?? 0);

function moveToTop() {
  _moveToTop(props.id);
}

function setAttrs(config) {
  _setAttrs({ id: props.id, ...config });
}

function onDragMouseDown(downEvent) {
  document.body.classList.add('n-no-select');
  moveToTop();

  isDragging.value = true;

  const offset = {
    x: downEvent.clientX - (config.value.left || 0),
    y: downEvent.clientY - (config.value.top || 0),
  };

  /**
   * Ken: Reason put logic here, not panel.js in store:
   *  prevent `reflow` from calling `window.innerWidth` everytime
   * */
  const SPACE_LIMITATION = {
    TOP: 0,
    RIGHT: window.innerWidth - OFFSET,
    LEFT: OFFSET - config.value.width,
    BOTTOM: window.innerHeight - OFFSET,
  };

  const onMouseMove = moveEvent => {
    let left = moveEvent.clientX - offset.x;
    let top = moveEvent.clientY - offset.y;

    top = Math.max(SPACE_LIMITATION.TOP, Math.min(top, SPACE_LIMITATION.BOTTOM));
    left = Math.max(SPACE_LIMITATION.LEFT, Math.min(left, SPACE_LIMITATION.RIGHT));

    setAttrs({ top, left });
  };

  const onMouseUp = upEvent => {
    isDragging.value = false;

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
    document.body.classList.remove('n-no-select');
  };

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
}

function onResizeMouseDown(downEvent, direction) {
  isDragging.value = true;

  const origin = {
    x: downEvent.clientX,
    y: downEvent.clientY,
    top: config.value.top || 0,
    left: config.value.left || 0,
    width: config.value.width || 0,
    height: config.value.height || 0,
  };

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const onMouseMove = moveEvent => {
    const mouseX = Math.max(Math.min(moveEvent.clientX, windowWidth - OFFSET), OFFSET);
    const mouseY = Math.max(Math.min(moveEvent.clientY, windowHeight - OFFSET), OFFSET);

    const offset = {
      x: mouseX - origin.x,
      y: mouseY - origin.y,
    };

    const config = {};

    const directionConfig = DirectionConfig[direction];

    VectorPair.forEach((vector, index) => {
      if (directionConfig[index]) {
        config[vector[0]] = origin[vector[0]] + offset[vector[1]] * directionConfig[index];
      }
    });

    setAttrs(config);
  };

  const onMouseUp = upEvent => {
    isDragging.value = false;

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
}

function onClickPanel() {
  moveToTop();
}

function onClose() {
  closePanel(props.id);
}

function onMinimize() {
  setAttrs({ minimized: true });
}

function onToggleFullscreen() {
  if (!config.value.maximizable) {
    return;
  }
  isFullscreen.value = !isFullscreen.value;

  const _config = {};
  if (isFullscreen.value) {
    const { height: clientHeight } = GetClientBounding();

    Object.assign(_config, {
      previousLeft: config.value.left,
      previousTop: config.value.top,
      previousWidth: config.value.width,
      previousHeight: config.value.height,
      left: 0,
      top: 0,
      width: document.body.clientWidth,
      height: clientHeight - headerHeight.value - footerHeight.value - 4,
    });
  } else {
    Object.assign(_config, {
      left: config.value.previousLeft,
      top: config.value.previousTop,
      width: config.value.previousWidth,
      height: config.value.previousHeight,
    });
  }
  setAttrs(_config);
}
</script>

<script>
export default {
  name: 'NPanel',
};
</script>

<style lang="less">
@panelRadius: 0.4rem;

.n-panel-menu-action {
  border-radius: 5px;
  padding: 1px 3px;
  cursor: pointer;
  min-width: 25px;
  text-align: center;
}

.n-panel-menu-action:hover {
  background-color: #ddd;
}

.n-panel-header-title {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 2px;
  padding-left: 4px;
}

.n-panel {
  position: fixed;
  border: 1px solid #ccc;
  border-radius: @panelRadius;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  font-size: 0.7rem;

  &-header {
    border-top-left-radius: @panelRadius;
    border-top-right-radius: @panelRadius;
    border-bottom: 1px solid #ddd;
    background-color: #f4f3f3;
    padding: 0.2rem 0.4rem;
    font-size: 0.9rem;
    cursor: move;
  }

  &-body {
    padding: 0.5rem;
    overflow: auto;
    position: relative;
  }

  &-footer {
    min-height: 1rem;
    border-top: 1px solid #ddd;
    border-bottom-left-radius: @panelRadius;
    border-bottom-right-radius: @panelRadius;
    background-color: #f4f3f3;
    padding: 0.2rem 0.4rem;
    cursor: move;
  }

  @offset: 0.4rem;

  &-resize-handler {
    position: absolute;
    background-color: #777777;

    &-t {
      cursor: n-resize;
      top: -@offset;
      left: @offset;
      right: @offset;
      height: 2 * @offset;
      background-color: transparent;
    }

    &-tl {
      cursor: nw-resize;
      top: -@offset;
      left: -@offset;
      width: 2 * @offset;
      height: 2 * @offset;
      background-color: transparent;
    }

    &-tr {
      cursor: ne-resize;
      top: -@offset;
      right: -@offset;
      width: 2 * @offset;
      height: 2 * @offset;
      background-color: transparent;
    }

    &-l {
      cursor: w-resize;
      top: @offset;
      left: -@offset;
      bottom: @offset;
      width: 2 * @offset;
      background-color: transparent;
    }

    &-r {
      cursor: e-resize;
      top: @offset;
      right: -@offset;
      bottom: @offset;
      width: 2 * @offset;
      background-color: transparent;
    }

    &-b {
      cursor: s-resize;
      bottom: -@offset;
      left: @offset;
      right: @offset;
      height: 2 * @offset;
      background-color: transparent;
    }

    &-bl {
      cursor: sw-resize;
      bottom: -@offset;
      left: -@offset;
      width: 2 * @offset;
      height: 2 * @offset;
      background-color: transparent;
    }

    &-br {
      cursor: se-resize;
      bottom: -@offset;
      right: -@offset;
      width: 2 * @offset;
      height: 2 * @offset;
      background-color: transparent;
    }
  }
}
</style>
