export default {
  profile: '个人信息',
  customer: '客户',
  totalPeopleQuantity: '合计：{quantity}人',
  verifiedPeopleQuantity: '已认证：{quantity}人',
  enabledPeopleQuantity: '已启用：{quantity}人',
  unlockedPeopleQuantity: '未锁定：{quantity}人',
  unverifiedPeopleQuantity: '未认证：{quantity}人',
  unenabledPeopleQuantity: '未启用：{quantity}人',
  lockedPeopleQuantity: '已锁定：{quantity}人',
  verify: '审核',
  identityVerify: '身份认证',
  withdrawalVerify: '提现审批',
  pendingApproval: '待审核',
  pendingProcess: '待处理',
  processed: '已处理',
};
