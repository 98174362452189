import { useLanguage } from '@/hooks';

export class MessageModel {
  constructor({ type, config, extra, isReport = false }) {
    this.type = type;
    this.config = config;
    this.extra = extra;
    this.isReport = isReport;
  }
}

export class WarnMessage extends MessageModel {
  constructor(config, extra) {
    super({ type: 'warn', config, extra });
  }
}

export class RequiredMessage extends WarnMessage {
  constructor(text) {
    const { tl, t, PREFIX } = useLanguage();
    super({ content: t('required', PREFIX.ERROR, { name: tl(text) }) });
  }
}

export class ErrorMessage extends MessageModel {
  constructor(config, extra, isReport = false) {
    super({ type: 'error', config, extra, isReport });
  }
}
