<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <BaseInput class="n-input" v-bind="componentAttrs" @press-enter="onPressEnter" />
  </NFormItem>
</template>

<script setup>
import { computed, useAttrs } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';

const attrs = useAttrs();

const emits = defineEmits(['pressEnter']);

const props = defineProps({
  label: String,
  name: [String, Array],
  customLabel: String,
});

const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);

const componentAttrs = computed(() => ({
  ...componentBaseAttrs.value,
  ...attrs,
}));

const onPressEnter = v => emits('pressEnter', v);
</script>

<script>
export default {
  name: 'NInput',
  inheritAttrs: false,
};
</script>
