import { ref, computed } from 'vue';
import { mySentry } from '@/monitor';
import { setToken } from '@/services';
import { useLanguage } from '@admin/hooks';
import { toReactive } from 'ant-design-vue/es/_util/toReactive';

const bootstrapData = ref({});

const ENUMS = computed(() => {
  const { enumMap } = bootstrapData.value.applicationVariable;
  const { t, te, PREFIX } = useLanguage();
  Object.entries(enumMap).forEach(([name, list]) => {
    list.forEach(o => {
      o.value = o.name;
      if (name !== 'ErrorCode') {
        const enumKey = `${name}.${o.name}`;
        o.label = te(enumKey, PREFIX.ENUMS) ? t(enumKey, PREFIX.ENUMS) : o.desc;
      }
    });
  });
  return enumMap;
});

const ENUM = computed(() => {
  const ret = {};
  Object.entries(ENUMS.value).forEach(([name, list = []]) => {
    ret[name] = {};
    list.forEach(o => {
      ret[name][o.name] = o.value;
    });
  });
  return ret;
});

const userProfile = computed(() => bootstrapData.value.userPo);

const userRoles = computed(() => bootstrapData.value.roleIds);

const token = computed(() => bootstrapData.value.token);

const agents = computed(() =>
  bootstrapData.value.agentPos?.map(o => {
    if (Object.prototype.hasOwnProperty.call(o, 'value')) {
      throw 'bootstrap agent SHOULD NOT have value';
    }
    return { ...o, label: o.name, value: o.id };
  }),
);

const its = computed(() =>
  bootstrapData.value.itPos?.map(o => {
    if (Object.prototype.hasOwnProperty.call(o, 'value')) {
      throw 'bootstrap it SHOULD NOT have value';
    }
    return { ...o, label: o.name, value: o.id };
  }),
);

const roles = computed(() =>
  bootstrapData.value.rolePos?.map(o => {
    if (Object.prototype.hasOwnProperty.call(o, 'value')) {
      throw 'bootstrap role SHOULD NOT have value';
    }
    const { t, PREFIX } = useLanguage();
    return { ...o, label: t(`rolePos.${o.name}`, PREFIX.SELECTION), value: o.id };
  }),
);

function _objectify(array = []) {
  return toReactive(
    computed(() => {
      const ret = {};
      array.value?.forEach(o => {
        ret[o.id] = o;
      });
      return ret;
    }),
  );
}

const It = _objectify(its);

const Agent = _objectify(agents);

const Role = _objectify(roles);

const RoleMap = computed(() => {
  let role = {};
  roles.value.forEach(o => (role[o.name] = o.value));
  return role;
});

const isSuperUser = computed(() => userProfile.value?.superUser);

const isItUser = computed(() => !isSuperUser.value && !!userRoles.value?.find(o => o === RoleMap.value?.['ROLE_IT']));

const isAgentUser = computed(() => !isSuperUser.value && !!userRoles.value?.find(o => o === RoleMap.value?.['ROLE_AGENT']));

// primary system
// const isPsUser = computed(() => !isSuperUser.value && userProfile.value.primarySystem);

// primary system
// const isPsData = (data = {}) => {
//   return data.primarySystem ?? data.value?.primarySystem;
// };

const userIt = computed(() => its.value?.find(o => o.value === userProfile.value.itId));

const userAgent = computed(() => agents.value?.find(o => o.value === userProfile.value.agentId));

function setBootstrapData(data) {
  bootstrapData.value = data;
  setToken(token.value);

  const { username, fullName, id, itId, agentId } = data.userPo;
  mySentry?.setUser({
    username,
    fullName,
    id,
    itId,
    agentId,
  });

  /*if (import.meta.env.VITE_APP_RELEASE === 'false') {
    checkEnums(data.applicationVariable.enumMap);
    checkI18n();
  }*/
}

export function useBootstrap() {
  return {
    setBootstrapData,
    ENUM,
    ENUMS,
    It,
    its,
    Agent,
    agents,
    Role,
    RoleMap,
    roles,
    token,
    userProfile,
    userRoles,
    isSuperUser,
    isItUser,
    isAgentUser,
    userIt,
    userAgent,
  };
}
