export default {
  ADMIN_DUP_USERNAME: 'The user name already exists',
  DOB_IS_BEFORE_TODAY: 'Date of birth must be before the current date',
  BANK_CARD_DUP_NUMBER: 'The receiver number already exists',
  PERMISSION_DENY_ADMIN_ONLY: 'Only system administrator can perform this operation',
  PERMISSION_DENY_ADMIN_OR_IT_ONLY: 'Only system administrator or institution administrator can perform this operation',
  // 5.1 基础相关
  INVALID_ARGUMENT_BAD_AGENT: 'Incorrect account information [0010]',
  INVALID_ARGUMENT_MIN_DEPOSIT: 'The minimum recharge limit {minDeposit} USD equivalent currency must be exceeded',
  INVALID_ARGUMENT_MAX_DEPOSIT: 'The maximum recharge limit {maxDeposit} USD equivalent currency cannot be exceeded',
  INVALID_ARGUMENT_MIN_WITHDRAWAL: 'The minimum withdrawal limit {minWithdrawal} USD equivalent currency must be exceeded',
  INVALID_ARGUMENT_MAX_WITHDRAWAL: 'The maximum withdrawal limit {maxWithdrawal} USD equivalent currency cannot be exceeded',
  INVALID_ARGUMENT_MORE_THAN_2_SCALES: 'Cannot exceed two decimal places',
  INVALID_ARGUMENT_RESOURCE_SAVE: 'Resource save parameter error',
  INVALID_ARGUMENT_NULL_PASSWORD: 'Password cannot be empty',
  INVALID_ARGUMENT_CONFIRM_PASSWORD: 'Confirm password not match',
  INVALID_ARGUMENT_FORMAT_PASSWORD: 'Invalid password format',
  INVALID_ARGUMENT_PASSWORD_VER_MISMATCH: 'Password version mismatch',
  INVALID_ARGUMENT_MISSING_PASSWORD_VER_CLAIM: 'Missing password version claim',
  INVALID_ARGUMENT_CAN_NOT_DELETE_FOR_VERIFIED: 'Verified data cannot be deleted',
  INVALID_ARGUMENT_ENTER_BANK_NUM: 'Please enter the bank card number',
  INVALID_ARGUMENT_IT_DUP_ID: 'The institution ID already exists',
  INVALID_ARGUMENT_IT_DUP_APP_KEY: 'The app key has been used, please change to the other app key',
  INVALID_REGISTER: 'Prohibit registration',
  INVALID_DATA_USED_CANNOT_BE_DELETED: 'Used data cannot be deleted',
  INVALID_ARGUMENT_CONFIG_BANK_NOT_NULL: 'BANK information cannot be empty. Please go to the bank card management page to add BANK information.',
  // 5.2 货币相关
  INVALID_ARGUMENT_CURRENCY_BASE_QUOTE_CAN_NOT_BE_THE_SAME: 'Base currency cannot be the same as quote currency',
  INVALID_ARGUMENT_FX_RATE_CAN_BE_NULL: 'Rate cannot be empty',
  INVALID_ARGUMENT_FX_RATE_MUST_BE_POSITIVE: 'Rate cannot be zero or negative number',
  INVALID_ARGUMENT_CURRENCY_DUP_CURRENCY_PAIR: 'The same currency pair already exists',
  INVALID_ARGUMENT_CURRENCY_AMOUNT_NOT_MATCH: 'Incorrect rate conversion amount',
  INVALID_ARGUMENT_CURRENCY_FX_RATE_NOT_FOUND: 'Rate corresponding to currency pair not found',
  INVALID_ARGUMENT_CURRENCY_FX_RATE_TEMPLATE_NOT_FOUND: 'Rate template not found',
  INVALID_ARGUMENT_CURRENCY_INVALID_FOR_THIS_BANK: 'The current bank does not support this currency',
  INVALID_ARGUMENT_CURRENCY_INVALID_FOR_THIS_RECEIVE_METHOD: 'The current receive method does not support this currency',
  // 5.3 用户相关
  INVALID_ARGUMENT_LOGIN_AUTH_BY_GOOGLE:
    'The email has already been authorized by GOOGLE, please login with GOOGLE authorization',
  INVALID_ARGUMENT_LOGIN_AUTH_BY_FACEBOOK:
    'The email has already been authorized by FACEBOOK, please login with FACEBOOK authorization',
  INVALID_ARGUMENT_LOGIN_AUTH_BY_APPLE: 'The email has already been authorized by APPLE, please login with APPLE authorization',
  INVALID_ARGUMENT_LOGIN_AUTH_BY_PASSWORD: 'The email has been authorized for use with a password, please login using a password',
  INVALID_ARGUMENT_CUSTOMER_FORMAT: 'Invalid customer format',
  INVALID_ARGUMENT_CUSTOMER_DUP_USERNAME: 'User name activated',
  INVALID_ARGUMENT_CUSTOMER_DUP_MOBILE: 'The mobile number already exists',
  INVALID_ARGUMENT_CUSTOMER_NOT_VERIFIED: 'Not verified',
  INVALID_ARGUMENT_CUSTOMER_AUTH_NOT_EXIST: 'Customer authentication information does not exist',
  INVALID_ARGUMENT_CUSTOMER_NOT_EXIST: 'Customer information does not exist',
  INVALID_ARGUMENT_EMAIL_CODE_NOT_EXIST: 'Link does not exist',
  INVALID_ARGUMENT_EMAIL_CODE_REPEAT: 'Link has invalid',
  INVALID_ARGUMENT_EMAIL_CODE_TIMEOUT: 'Link has expired',
  INVALID_ARGUMENT_CUSTOMER_ACCOUNT_NOT_ACTIVATED: 'Customer transaction account not activated',
  INVALID_ARGUMENT_CUSTOMER_ID_EXPIRE_LIMIT: 'Identification expire date must be at least {days} day left',
  INVALID_ARGUMENT_CHANGE_AGENT_CAN_NOT_CHANGE_IT: 'Can only be changed to a different agent under the same institution',
  INVALID_ARGUMENT_CHANGE_AGENT_SAME_AGENT: 'The agent before and after the change is the same',
  INVALID_ARGUMENT_CHANGE_AGENT_HAS_POSITION_OR_ACTIVE_ORDER:
    'The current account has positions or pending orders, and the agent cannot be changed',
  // 5.4 业务流程相关
  INVALID_ARGUMENT_UNABLE_TO_LOCK_WITHDRAWAL_AMOUNT: 'Unable to lock the amount, please refresh and try again',
  INVALID_ARGUMENT_PROCESS_SUBMIT_ILLEGAL_STATUS: 'Status already is submit',
  INVALID_ARGUMENT_PROCESS_RETRACT_ILLEGAL_STATUS: 'Cannot be retract',
  INVALID_ARGUMENT_PROCESS_APPROVAL_ILLEGAL_STATUS:
    'Approval Status Error: Only the approval status is submit, can allowed to reject or approve the operation (The data may have changed, please retrieve the data again and try again)',
  INVALID_ARGUMENT_PROCESS_APPROVAL_ILLEGAL_PROCESSED:
    'Process Status Error: Only the process status is unprocessed, can allowed to reject or approve the operation (The data may have changed, please retrieve the data again and try again)',
  INVALID_ARGUMENT_PROCESS_APPROVED_FLOW_ILLEGAL_STATUS:
    'Approval Status Error: Only the approval status is approved, can allowed to process complete the operation (The data may have changed, please retrieve the data again and try again)',
  INVALID_ARGUMENT_PROCESS_APPROVED_FLOW_ILLEGAL_PROCESSED:
    'Process Status Error: Only the process status is processed, can allowed to process complete the operation (The data may have changed, please retrieve the data again and try again)',
  INVALID_ARGUMENT_PROCESS_REJECT_FLOW_ILLEGAL_STATUS:
    'Approval Status Error: Only the approval status is reject, can allowed to process complete the operation (The data may have changed, please retrieve the data again and try again)',
  INVALID_ARGUMENT_PROCESS_ILLEGAL_STATUS: 'Status Error',
  // 5.5 KRAMPUS API相关
  INVALID_ARGUMENT_KRAMPUS_API_DUP_LOGIN: 'Trading system user name already exists',
  INVALID_ARGUMENT_KRAMPUS_API_WRONG_KEY_OR_SECRET: 'Trading system incorrect key or secret',
  INVALID_ARGUMENT_KRAMPUS_API_LOGIN_FAILED: 'Trading system login failed',
  INVALID_ARGUMENT_DEMO_KRAMPUS_API_DUP_LOGIN: 'Demo duplicate login',
  INVALID_ARGUMENT_DEMO_KRAMPUS_API_WRONG_KEY_OR_SECRET: 'Demo wrong keys',
  INVALID_ARGUMENT_DEMO_KRAMPUS_API_LOGIN_FAILED: 'Demo login failed',
  INVALID_ARGUMENT_KRAMPUS_API_SYNC_IT_FAILED:
    'Sync institution failed, no institution with trading system id is {itId} was found, please view permissions',
  INVALID_ARGUMENT_KRAMPUS_API_INVOKE_ERROR: 'Trading system call failed',
  INVALID_ARGUMENT_KRAMPUS_API_INSUFFICIENT_FUNDS: 'Insufficient funds in the account',
  INVALID_ARGUMENT_KRAMPUS_API_PASSWORD_IS_EMPTY: 'The password is empty, please set the password first',
  INVALID_ARGUMENT_KRAMPUS_API_TOKEN_FAILED: 'Login has expired, please log in again',
  // token相关
  INVALID_ARGUMENT_INVALID_ISSUER_FORMAT: 'Invalid issuer format',
  INVALID_ARGUMENT_MISSING_LOGIN_CODE: 'Missing login code',
  INVALID_ARGUMENT_CUSTOMER_NOT_FOUND: 'Customer not found',
  INVALID_ARGUMENT_MISSING_PASSWORD_TIME_CLAIM: 'Missing password time claim',
  INVALID_ARGUMENT_PASSWORD_TIME_MISMATCH: 'Password time mismatch',
  INVALID_ARGUMENT_TOKEN_EXPIRED: 'Token expired',
  // 5.6 METHOD 相关
  INVALID_ARGUMENT_METHOD_BANK_USDT_INVALID: "The bank's receive currency cannot be USDT",
  INVALID_ARGUMENT_METHOD_BANK_BANK_CAN_NOT_NULL: 'Please select a bank',
  INVALID_ARGUMENT_METHOD_BANK_ACCOUNT_NAME_CAN_NOT_EMPTY: 'Bank account name cannot be blank',
  INVALID_ARGUMENT_METHOD_BANK_ACCOUNT_NUMBER_CAN_NOT_EMPTY: 'Bank account number cannot be blank',
  INVALID_ARGUMENT_METHOD_USDT_USDT_ONLY: "The USDT's receive currency must be USDT",
  INVALID_ARGUMENT_METHOD_USDT_AT_LEAST_ONE_IS_REQUIRED: 'Fill in at least one of the address and QR code',
  INVALID_ARGUMENT_METHOD_USDT_CRYPTO_NETWORK_REQUIRED: 'USDT network cannot be empty',
  INVALID_ARGUMENT_METHOD_WECHAT_CNY_ONLY: "The WeChat's receive currency must be CNY",
  INVALID_ARGUMENT_METHOD_REQUIRE_QR_CODE: 'The current receive method must upload QR code',
  // 5.7 入出金相关
  INVALID_ARGUMENT_IT_TRANSFER_CONFIG_ERROR: 'Transfer config error',
  INVALID_ARGUMENT_IT_TRANSFER_CAN_NOT_BE_NULL: 'Institution transfer cannot be blank',
  INVALID_ARGUMENT_IT_TRANSFER_AMOUNT_CAN_NOT_BE_NULL: 'Institution transfer amount cannot be blank',
  INVALID_ARGUMENT_IT_TRANSFER_RECEIVE_CAN_NOT_BE_NULL: 'Institution transfer receiver cannot be blank',
  INVALID_ARGUMENT_DEPOSIT_AMOUNT_TRANSFER_AMOUNT_NOT_MATCH: 'The deposit amount does not match the transfer amount',
  INVALID_ARGUMENT_PAYMENT_SAME_TYPE_PAYMENT_CHANNEL_ONLY_ONE: 'Same type payment channel only one',
  INVALID_ARGUMENT_DUPLICATE_PAYMENT_CHANNEL_TYPE: 'Only one payment gateway of the same type can be selected',
  INVALID_ARGUMENT_PAYMENT_INVOKE_ERROR: 'Invoke error',
  INVALID_ARGUMENT_PAYMENT_INVOKE_PARAM_ERROR: 'Invoke param error',
  INVALID_ARGUMENT_TRANSACTION_AMOUNT: 'Transaction amount check failure',
  INVALID_ARGUMENT_CLOSE_ACCOUNT_ERROR: 'Account cancellation failed, positions must be cleared and funds are zero',
  INVALID_ARGUMENT_CONFIG_PIX_NOT_NULL: 'PIX information cannot be blank. Please update your PIX details on the bank card management page.',
  INVALID_ARGUMENT_CONFIG_IFSC_NOT_NULL: 'IFSC information cannot be blank. Please update your IFSC details on the bank card management page.',
  // 6. 操作校验
  UNSUPPORTED_OPERATION_NO_IT_RECEIVE_FOUND: 'The institution does not support the deposit of this currency',
  // 7. 邮箱验证
  THIRD_PARTY_AUTH_USER_CANNOT_RESET_PASSWORD: 'Unable to reset password for third-party authentication account',
  INVALID_THIRD_PARTY_ACCOUNT_UNBINDING_FAILED: 'Failed to unbind third-party account',
  THIRD_PARTY_AUTH_USER_CANNOT_ACTIVE_EMAIL: 'Third party authenticated user does not need to activate email',
  INVALID_ACCOUNT_ALREADY_EXISTS_AND_LOCKED: 'Account already exists and is locked',
  INVALID_EMAIL_CHECK_INVALID_USER: 'Invalid user',
  INVALID_EMAIL_CHECK_USER_NOT_EXIST: 'User not Exist',
  INVALID_EMAIL_CHECK_EMAIL_VERIFIED: 'Email had verified',
  INVALID_EMAIL_CHECK_EMAIL_NOT_VERIFIED: 'Email not verified',
  INVALID_EMAIL_CHECK_INVALID_REQUEST_PARAMETERS: 'Operation failed, request timed out',
  INVALID_EMAIL_CHECK_REPEATED_REQUEST: 'Duplicate request, please try again later',
  INVALID_ARGUMENT_INVALID_PROVIDERTYPE: 'Invalid third-party login type',
  INVALID_ARGUMENT_ORIGINAL_PASSWORD_MISMATCH: 'Original password does not match',
  INVALID_ARGUMENT_NEW_PASSWORD_SAME_AS_OLD: 'The new password cannot be the same as the old password',
  INVALID_ARGUMENT_EXCLUSIVE_AGENT_LINK:
    'Unable to register. This link belongs to an exclusive agent. Multiple registrations are prohibited',
  // 注册
  REGISTER_DUP_USERNAME: 'Duplicated Username',
  REGISTER_DUP_MOBILE: 'Duplicated Mobile',
  USERNAME_WRONG_FORMAT: 'Username wrong pattern',
  MOBILE_WRONG_FORMAT: 'Mobile wrong pattern',
  ID_CARD_ID_WRONG_FORMAT: 'ID card wrong pattern',
  // 登录
  LOGIN_FAILED: 'Login failed, incorrect user name or password',
  LOGIN_NOT_ENABLED: 'Account is not enabled',
  LOGIN_LOCKED: 'Account is locked',
  // 密码
  PASSWORD_WRONG: 'Password input error',
  PASSWORD_WRONG_FORMAT: 'Password must be 6-16 characters and cannot contain spaces',
  PASSWORD_WRONG_TIME_LEFT: 'Password input error, there are {timeLeft} chances left',
  PASSWORD_CONFIRM_NOT_MATCH: 'Confirm password not match',
  PASSWORD_WRONG_AND_LOCK: 'The password was entered incorrectly {failedAttempt} times in succession, and the account was locked',
  PASSWORD_NULL: 'Password must not null',
  CONFIRM_PASSWORD_NOT_MATCH: 'Confirm password not match',
  // 权限
  PERMISSION_DENY: 'You do not have access',
  // Jwt Token
  JWT_INVALID_TOKEN: 'Token expired, please login again',
  JWT_VERSION_NOT_MATCHED: 'Version mismatch',
  // 手机验证码
  SMS_VERIFY_CODE_NOT_MATCH: 'Verify code not match',
  CAPTCHA_VERIFY_CODE_NOT_MATCH: 'Verify code not match',
  // 参数校验
  INVALID_ARGUMENT: 'Parameter Error',
  INVALID_ARGUMENT_NULL_POINT: 'Parameter Error: Parameter cannot be empty',
  // 其他
  DATA_NOT_FOUND: 'Data Not Found',
  STALE_VERSION: 'The data has changed, please refresh and try again',
  INVALID_STATE: 'Invalid status, data may have changed',
  INVALID_VALUE: 'Parameter Content Error',
  INVALID_FORMAT: 'Parameter Format Error',
  INTERNAL_ERROR: 'Internal error, please contact administrator',
  MULTI_ARGS_TEST: 'arg1 {arg1}, arg2 {arg2}, arg3 {arg3}',
  INVALID_ARGUMENT_NOT_BLANK: 'Not Blank',

  // 第三方
  access_denied: 'Access Denied',
  invalid_token_response: 'Invalid Token Response',
  authorization_request_not_found: 'Authorization Request Not Found',
};
