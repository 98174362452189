<template>
  <div class="n-short-number">
    <n-span :value="maskNumber" :title="number" @dblclick="toClipboard(number)" />
    <copy-outlined v-if="!isShort" :title="$tl('copy')" @click="toClipboard(number)" />
  </div>
</template>

<script>
import { pad } from 'lodash-es';
import { useClipboard } from '@/hooks';
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'NShortNumber',

  inheritAttrs: false,

  props: {
    value: [String, Number],
    leadingDigit: { type: Number, default: 2 },
    trailingDigit: { type: Number, default: 2 },
    starCount: { type: Number, default: 4 },
  },

  setup(props) {
    const { toClipboard } = useClipboard();

    const number = computed(() => props.value?.toString());

    const length = computed(() => number.value?.length ?? 0);

    const isShort = computed(() => length.value < props.leadingDigit + props.trailingDigit);

    const maskNumber = computed(() => {
      if (!number.value) return undefined;
      return isShort.value
        ? number.value
        : `${number.value.substring(0, props.leadingDigit)}${pad('*', props.starCount, '*')}${number.value.substring(
            length.value - props.trailingDigit,
            number.value.length,
          )}`;
    });

    return { number, isShort, maskNumber, toClipboard };
  },
});
</script>

<style lang="less">
.n-short-number {
  width: auto;

  .anticon-copy {
    margin-left: 4px;
    display: none;
  }

  &:hover .anticon-copy {
    display: inline-block;
  }
}
</style>
