export const STATUS_COLOR = {
  YELLOW: '#CA8A04',
  BLUE: '#3B82F6',
  GREEN: '#22C55E',
  RED: '#EF4444',
  GREY: '#4B5563',
  PURPLE: '#9333EA',
};

export const ENUM_COLOR = {
  ApprovalStatus: {
    SUBMIT: STATUS_COLOR.BLUE,
    RETRACT: STATUS_COLOR.YELLOW,
    APPROVED: STATUS_COLOR.GREEN,
    REJECT: STATUS_COLOR.RED,
  },
  TransferTransactionStatus: {
    TRANSFERRING: STATUS_COLOR.YELLOW,
    TRANSFERRED: STATUS_COLOR.BLUE,
    CONFIRMED: STATUS_COLOR.GREEN,
    FORCE_CONFIRMED: STATUS_COLOR.GREEN,
    CANCELLED: STATUS_COLOR.RED,
    FORCE_CANCELLED: STATUS_COLOR.RED,
    FAILED: STATUS_COLOR.RED,
  },
  PaymentOrderStatus: {
    PENDING: STATUS_COLOR.YELLOW,
    PROCESSING: STATUS_COLOR.BLUE,
    CANCELED: STATUS_COLOR.RED,
    SUCCESS: STATUS_COLOR.GREEN,
    FAILED: STATUS_COLOR.RED,
  },
  DepositPaymentOrderStatus: {
    PENDING: STATUS_COLOR.YELLOW,
    PROCESSING: STATUS_COLOR.BLUE,
    CANCELED: STATUS_COLOR.RED,
    SUCCESS: STATUS_COLOR.GREEN,
    FAILED: STATUS_COLOR.RED,
  },
  WithdrawPaymentOrderStatus: {
    PENDING: STATUS_COLOR.YELLOW,
    PROCESSING: STATUS_COLOR.BLUE,
    CANCELED: STATUS_COLOR.RED,
    SUCCESS: STATUS_COLOR.GREEN,
    FAILED: STATUS_COLOR.RED,
  },
};

export const ENV_COLOR = {
  LOCAL: {
    primaryColor: '#336699',
  },
  DEV: {
    primaryColor: '#558B2F',
  },
  UAT: {
    primaryColor: '#00796B',
  },
  PROD: {
    primaryColor: '#311B92',
  },
};
