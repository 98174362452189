/**
 @Author Ken
 @desc localStorage 的读写操作, 支持版本更新与过期
 ```javascript
 const config = {
      ver: 2,
      transition: function (ver, key, value, option) {
        this.remove('tabs');
        // 1. will goto DEFAULT_PORTAL if login
        // 2. will refresh if already in some router
        window.location.assign('#/' + DEFAULT_PORTAL);
      },
    };
 ```
 */
export class Storage {
  constructor(config = {}) {
    this.MAX_LIFE = 1000 * 3600 * 24 * 365 * 10; // 10 years
    this.ver = config.ver || 1;
    this.prefix = config.prefix;
    this.transition = config.transition;
    this.set('ver', this.ver);
  }

  set(key, value, option = {}) {
    const newOption = { life: this.MAX_LIFE, postponable: false, ...option };
    newOption.expireOn = Date.now() + newOption.life;
    const obj = { ver: this.ver, option: newOption, value };
    localStorage.setItem(this.prefix + key, JSON.stringify(obj));
  }

  get(key) {
    const strValue = localStorage.getItem(this.prefix + key);
    if (!strValue) {
      return null;
    }

    let ver, option, value;
    try {
      const parsedObj = JSON.parse(strValue);
      ver = parsedObj.ver;
      option = parsedObj.option;
      value = parsedObj.value;
    } catch (err) {
      return null;
    }

    const currentTime = Date.now();
    if (currentTime >= option.expireOn) {
      this.remove(key);
      return null;
    }

    option.lastAccessTime = currentTime;
    if (option.postponable) option.expireOn = currentTime + option.life;

    this.set(key, value, option);

    if (ver !== this.ver && this.transition) {
      return this.transition(ver, key, value, option);
    }

    return value;
  }

  remove(key) {
    localStorage.removeItem(this.prefix + key);
  }

  /**
   * @comment 只清理本实例创建的, 避免误伤. 若要全部清理, 可直接使用localStorage.clear方法
   * */
  clear() {
    const removeKeys = [];
    for (let i = 0; i < localStorage.length; ++i) {
      const key = localStorage.key(i);
      if (key?.startsWith(this.prefix)) {
        removeKeys.push(key);
      }
    }
    removeKeys.forEach(v => localStorage.removeItem(v));
  }
}

export let storage;

export function setStorage(s) {
  storage = s;
}
