<template>
  <span :class="className" :title="title ?? value">{{ label }}</span>
</template>

<script setup>
import { computed } from 'vue';

const INVALIDS = [undefined, null, ''];

const props = defineProps({
  value: [String, Number],
  title: String,
  align: { type: String, default: 'left' },
  replacement: { type: String, default: '-' },
});

const className = computed(() => `n-ellipsis n-text-${props.align}`);

const label = computed(() => (INVALIDS.includes(props.value) ? props.replacement : props.value));
</script>
