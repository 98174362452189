export let projectName = '';
export let adminName = 'Admin';
export let X_VERSION = 'NA';
export let X_BRANCH_NAME = '';
export let isRelease = false;

export let i18n = null;
export let eventBus = null;
export let router = null;

export let DEFAULT_PORTAL = '/dashboard';
export let DEFAULT_GUEST_PORTAL = '/guest';
export let API_PREFIX = '/api';

export let pingChannel = null;

export function setConfig(config = {}) {
  projectName = config.projectName ?? projectName;
  adminName = config.adminName ?? adminName;
  X_VERSION = config.X_VERSION ?? X_VERSION;
  X_BRANCH_NAME = config.X_BRANCH_NAME ?? X_BRANCH_NAME;
  isRelease = config.isRelease ?? isRelease;

  i18n = config.i18n ?? i18n;
  eventBus = config.eventBus ?? eventBus;
  router = config.router ?? router;

  DEFAULT_PORTAL = config.DEFAULT_PORTAL ?? DEFAULT_PORTAL;
  API_PREFIX = config.API_PREFIX ?? API_PREFIX;
  pingChannel = config.pingChannel ?? pingChannel;
}
