import { isObject, isArray, isEmpty } from 'lodash-es';

export function paramFilter(params = {}) {
  if (!isObject(params) || isArray(params) || isEmpty(params)) return;
  let r = Object.keys(params).reduce((obj, k) => {
    let v = params[k];
    if (v instanceof Array) v = v.join(',');
    if (![undefined, null, NaN, ''].includes(v)) obj[k] = v;
    return obj;
  }, {});
  return !isEmpty(r) ? r : undefined;
}
