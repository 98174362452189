import { NMessage } from '@/components';
import { mySentry } from '@/monitor';
import { MessageModel } from '@/models';

const importantMessages = ['error.internal_error', 'error.unknown'];

export function messageHandler(err, vm, info) {
  if (!(err instanceof MessageModel)) return true;

  const { type, config, extra, isReport } = err;

  NMessage[type](config ?? { content: 'Unknown Error' });

  if (importantMessages.includes(config) || isReport) {
    const message = typeof config === 'object' ? config.content : config;
    mySentry?.captureErrorMessage(message, { extra });
  }
  return false;
}
