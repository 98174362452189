export default {
  dashboard: 'Home',
  member: {
    title: 'Customer',
    customer: 'Customer List',
    customerDetail: 'Detail',
    bankCard: 'Bank Card',
    identification: 'Identification',
    customerVerifyFlow: 'Verify Flow',
  },
  cash: {
    title: 'Cash',
    itReceive: 'USDT Receive',
    usdtErc20Config: 'USDT ERC20 Config',
    usdtTrc20Config: 'USDT TRC20 Config',
    fxRateTemplate: 'FX Rate Template',
    fxRateDetail: 'Details',
    customerDepositFlow: 'Deposit Flow',
    customerDepositDetails: 'Details',
    customerWithdrawalFlow: 'Withdrawal Flow',
    customerWithdrawalDetails: 'Details',
    transferTransaction: 'Transfer Transaction',
    paymentChannel: 'Payment Channel',
    paymentChannelDetails: 'Details',
    paymentGateway: 'Payment Gateway',
    paymentGatewayDetails: 'Details',
    paymentRouting: 'Payment Routing',
  },
  role: {
    title: 'Role',
    user: 'Operator',
    role: 'Role',
    it: 'Institution',
    itCreateAccounts: 'Create Accounts',
    agent: 'Agent',
  },
  system: {
    title: 'System',
    config: 'Config',
    operation: 'Operation',
    cache: 'Cache',
  },
  demo: {
    title: 'Demo',
    demo: 'Demo',
  },
};
