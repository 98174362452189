<template>
  <div class="n-panel-minimized-window">
    <NRow type="flex" style="align-items: center">
      <NCol style="flex: 1">
        <slot></slot>
      </NCol>
      <NCol>
        <NRow type="flex">
          <NCol>
            <div class="n-panel-menu-action" @click="onWindowShow">
              <i class="fas fa-reply" style="transform: rotateY(180deg)"></i>
              <!--              <i class="fas fa-external-link-alt"></i>-->
              <!--              <i class="fas fa-external-link-square-alt"></i>-->
            </div>
          </NCol>
          <NCol>
            <div class="n-panel-menu-action" @click.stop="onWindowClose">
              <i class="fas fa-times"></i>
            </div>
          </NCol>
        </NRow>
      </NCol>
    </NRow>
  </div>
</template>

<script setup>
import { usePanel } from '@/hooks';

const props = defineProps({
  id: String,
});
const emit = defineEmits(['on-window-close']);

const { setAttrs, moveToTop, closePanel } = usePanel();

function onWindowShow() {
  setAttrs({ id: props.id, minimized: false });
  moveToTop(props.id);
}

function onWindowClose() {
  closePanel(props.id);
  emit('on-window-close', props.id);
}
</script>

<script>
export default {
  name: 'NPanelMinimizedWindow',
};
</script>

<style lang="less">
.n-panel-minimized-window {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  width: 200px;
  height: 25px;
  border: 1px solid #aaa;
  border-bottom: 0;
  background-color: #f4f3f3;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  margin-right: 0.1rem;
  padding: 0 0.3rem;
}
</style>
