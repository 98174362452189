<template>
  <AForm v-bind="attributes" :class="{ vertical: layout === 'vertical' }" @validate="onValidate">
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </AForm>
</template>

<script setup>
import { isEmpty } from 'lodash-es';
import { Form as AForm } from 'ant-design-vue/es';
import { ref, provide, computed, getCurrentInstance } from 'vue';

const props = defineProps({
  layout: { type: String, default: 'horizontal' },
  labelCol: { type: Object, default: () => ({ span: 10, offset: 0 }) },
  wrapperCol: { type: Object, default: () => ({ span: 0, offset: 0 }) },
});

const instance = getCurrentInstance();

const form = ref();

const errorMap = ref({});

const attributes = computed(() => ({
  ref: form,
  layout: props.layout,
  labelCol: props.labelCol,
  wrapperCol: props.wrapperCol,
  class: 'n-form grid grid-cols-1 md:grid-cols-2',
}));

const validate = () => form.value.validate();

const validateFields = names => form.value.validateFields(names);

const clearValidate = names => {
  if (!isEmpty(names)) {
    names.forEach(name => {
      form.value.clearValidate([name]);
      delete errorMap.value[name];
    });
  } else form.value.clearValidate();
};

const onValidate = (name, status, errorMsgs) => (status ? delete errorMap.value[name] : (errorMap.value[name] = errorMsgs));

defineExpose({ validate, validateFields, clearValidate, errorMap });

provide('NForm', instance);
</script>

<style lang="less">
.n-modal .ant-modal-body .n-form {
  padding: 30px 42px 12px 24px;

  &.\!grid-cols-1 {
    padding: 30px 20px 12px 36px;
  }
}
</style>
