<template>
  <APopconfirm class="n-popconfirm" v-bind="attrs">
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </APopconfirm>
</template>

<script setup>
import { computed, useSlots } from 'vue';
import { useLanguage } from '@/hooks';
import { Popconfirm as APopconfirm } from 'ant-design-vue/es';
import { errorHandler } from '@/services';

const { tl, t, PREFIX } = useLanguage();
const slots = useSlots();

const props = defineProps({
  remove: Boolean,
  onConfirm: Function,
});

function onConfirm() {
  const promisifyFunc = async () => props.onConfirm();
  return promisifyFunc().catch(e => errorHandler(e, null, null));
}

const attrs = computed(() => ({
  // use slots title first, then title field
  title: slots.title ? undefined : props.remove ? t('title', PREFIX.CONFIRM, { action: tl('remove') }) : 'Are you sure ?',
  okText: tl('confirm'),
  cancelText: tl('cancel'),
  onConfirm,
}));
</script>
