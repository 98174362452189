import label from './label';
import enums from './enums';
import message from './message';
import selection from './selection';
import errorCode from './errorCode';

export default {
  label,
  enums,
  selection,
  errorCode,
  ...message,
};
