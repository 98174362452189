export default {
  ApprovalStatus: {
    SUBMIT: '提交',
    RETRACT: '撤回',
    APPROVED: '通过',
    REJECT: '驳回',
  },
  ConfigKey: {
    DEFAULT_AVATAR_PATH: '默认头像路径',
    KRAMPUS_URL: 'KRAMPUS URL',
    ADMIN_HOST: '管理画面地址',
    MIN_DEPOSIT: '最小存入限制（美元）',
    MIN_WITHDRAWAL: '最小取出限制（美元）',
    UPLOAD_PATH: '上传文件的系统根目录',
    FILE_PATH: '上传文件子目录',
    REGISTER_URL_BASE: 'REGISTER URL BASE',
    JWT_VERSION_FRONT: 'JWT FRONT版本',
    JWT_VERSION_ADMIN: 'JWT ADMIN版本',
    MAX_DEPOSIT: '最大充值金额（美元）',
    MAX_WITHDRAWAL: '最大提现金额（美元）',
    SENTRY_ENABLED: 'Sentry 启用',
    SENTRY_URL: 'Sentry URL',
  },
  Currency: {
    USDT: 'USDT',
    BRL: '雷亚尔',
    INR: '卢比',
    USD: '美元',
    EUR: '欧元',
    HKD: '港币',
  },
  CustomerField: {
    NAME: '姓名',
    MOBILE: '手机号',
    SEX: '性别',
    DOB: '生日',
    DISTRICT: '地区',
  },
  FlowChartType: {
    VERIFIED: '客户认证流程',
    DEPOSIT: '充值处理流程',
    WITHDRAWAL: '提现处理流程',
  },
  FlowInfoType: {
    VERIFIED: '客户认证',
    DEPOSIT: '入金处理',
    WITHDRAWAL: '出金处理',
  },
  IdentificationType: {
    ID_CARD: '身份证',
    PASSPORT: '护照',
    DRIVER_LICENSE: '驾照',
    SOCIAL_INSURANCE_CARD: '社保卡',
    OTHER: '其他',
  },
  ReceiveMethod: {
    BANK: '银行',
    USDT: 'USDT',
    NET_PAY: '网络支付',
  },
  ResourceType: {
    ID: '身份信息',
    CUSTOMER_AVATAR: '客户头像',
    IT_RECEIVE_AVATAR: '机构收款信息',
    PROCESS_FLOW: '流程图',
    TRANSFER_PROOF: '转账凭证',
  },
  Sex: {
    MALE: '男',
    FEMALE: '女',
    SECRET: '保密',
  },
  TransferTransactionStatus: {
    TRANSFERRING: '待转账',
    TRANSFERRED: '已转账',
    CONFIRMED: '已收款',
    CANCELLED: '已取消',
    FORCE_CONFIRMED: '已收款（强制）',
    FORCE_CANCELLED: '已取消（强制）',
    FAILED: '失败',
  },
  TransferRole: {
    CUSTOMER: '客户',
    IT: '机构',
  },
  DurationType: {
    MINUTES: '分钟',
    HOURS: '小时',
  },
  Lang: {
    EN: '英文',
    ZH_CN: '中文（简体）',
    ZH_HK: '中文（繁體）',
    JA: '日语',
    KO: '韩语',
    PT: '葡萄牙语',
  },
  CryptoNetwork: {
    TRC20: 'TRC20',
    ERC20: 'ERC20',
  },
  RoleType: {
    SUPER_USER: '超级用户',
    ROLE_IT: '机构管理员',
    ROLE_AGENT: '代理管理员',
  },
  Country: {
    SOUTH_KOREA: '韩国',
    JAPAN: '日本',
    BRAZIL: '巴西',
    INDIA: '印度',
    TAIWAN: '台湾',
  },
  QMaxInvestmentOption: {
    HKD_300000: '$300,000',
    HKD_100: '$100',
  },
  QTriggerOption: {
    AT_LATEST_PRICE: '我的仓位已按照最新可用价格平仓',
    STOP_LOSS_NOT_TRIGGERED: '止损不能关闭我的仓位',
  },
  AutoCloseOption: {
    INSUFFICIENT_CAPITAL: '市场沿着对我仓位不利的方向发展，我的账户没有足够的资本来维持我的头寸',
    FAVORABLE_DIRECTION: '市场正在转向我的有利位置',
  },
  QTradingCountOption: {
    COUNT_100: '51到100笔交易',
    COUNT_50: '20到50笔交易',
    COUNT_LESS_THAN_20: '少于二十笔交易',
    COUNT_NONE: '从未进行交易',
  },
  QOtcLeverageOption: {
    BETWEEN_1_50_AND_1_100: '在1:50-1:100之间',
    BETWEEN_1_25_AND_1_50: '在1:25-1:50之间',
    BETWEEN_1_2_AND_1_25: '在1:2-1:25之间',
    NO_LEVERAGE: '不使用杠杆（1:1）',
  },
  QFinanceEducationOption: {
    WORKED_IN_FINANCIAL: '曾在金融服务行业工作',
    PROFESSIONAL: '专业资质/或高等教育',
  },
  QOccupationOption: {
    EMPLOYEE: '雇员',
    SELF_EMPLOYED: '自由职业',
    RETIRED: '退休人员',
    STUDENT: '学生',
    PROPERTY: '物业',
  },
  QIndustryOption: {
    FINANCIAL_SERVICES: '金融服务行业',
    INFORMATION_TECHNOLOGY: '信息技术行业',
    HEALTHCARE: '医疗保健行业',
    RETAIL_AND_CONSUMER_GOODS: '零售和消费品行业',
    MANUFACTURING: '制造业',
    MEDIA_AND_ENTERTAINMENT: '媒体和娱乐行业',
    CONSTRUCTION_AND_REAL_ESTATE: '建筑和房地产行业',
    ENERGY_AND_UTILITIES: '能源和公用事业行业',
    CONSULTING_AND_PROFESSIONAL_SERVICES: '咨询和专业服务行业',
    OTHER: '其他',
  },
  QFinancialAmountOption: {
    FIVE_M_PLUS: '$5,000,000+',
    FIVE_M: '$2,000,000 - 5,000,000',
    TWO_M: '$1,000,000 - 2,000,000',
    ONE_M: '$500,000 - 1,000,000',
    FIVE_HUNDRED_K: '$200,000 - 500,000',
    TWO_HUNDRED_K: '$50,000 - 200,000',
    FIFTY_K: '$0 - 50,000',
  },
  QIncomeSourceOption: {
    EMPLOYMENT_INCOME: '在职/自由职业收入',
    PENSION_OR_GOVERNMENT_BENEFITS: '退休金或政府福利',
    INVESTMENT_INCOME: '投资收益',
    INHERITANCE: '遗产',
    PROPERTY_SALE: '物业出售',
    BUSINESS_SALE: '企业出售',
  },
  QTradingFrequencyOption: {
    DAILY: '每天',
    WEEKLY: '每个星期',
    MONTHLY: '每个月',
  },
  QDepositMethodOption: {
    CREDIT_CARD: '信用卡/借记卡',
    BANK_TRANSFER: '银行电汇',
  },
  PaymentGateway: {
    PPAY: 'ppay支付',
    CASH_PAY: 'cash pay支付',
    UAT_EPAY: 'UAT epay支付',
    PRO_EPAY: 'PRO epay支付',
    BETCAT_PAY: 'betcat pay支付',
    UAT_BETCAT_PAY: 'UAT betcat pay支付',
  },
  PayType: {
    RECEIVE: '充值',
    PAYMENT: '提现',
  },
  TransactionModel: {
    C2C: 'C2C',
    B2C: 'B2C',
    B2B: 'B2B',
    C2B: 'C2B',
  },
  TransactionCategory: {
    BANK: '银行',
    TRANSFER: '转账',
    IFSC: '印度支付-IFSC',
    PIX: '巴西支付-PIX',
  },
  FeeModel: {
    FIXED: '固定手续费',
    PERCENTAGE: '百分比手续费',
  },
  RateMode: {
    USD_TO_TARGET: '美金到目标币种',
    TARGET_TO_USD: '目标币种到美金',
  },
  PaymentOrderStatus: {
    FAILED: '处理失败',
    SUCCESS: '处理成功',
    PENDING: '未处理',
    CANCELED: '取消完成',
    PROCESSING: '处理中',
  },
  DepositPaymentOrderStatus: {
    FAILED: '充值失败',
    SUCCESS: '充值成功',
    PENDING: '未处理',
    CANCELED: '已取消',
    PROCESSING: '充值中',
  },
  WithdrawPaymentOrderStatus: {
    FAILED: '提现失败',
    SUCCESS: '提现成功',
    PENDING: '未处理',
    CANCELED: '已取消',
    PROCESSING: '提现中',
  },
  PaySystemOrderStatus: {
    GATEWAY_CREATE: 'GATEWAY_CREATE',
    CONFIRM: 'CONFIRM',
    CANCEL: 'CANCEL',
    FAIL: 'FAIL',
    SUCCESS: 'SUCCESS',
    CLOSE: 'CLOSE',
    FREEZE: 'FREEZE',
    WITHDRAWN: 'WITHDRAWN',
    NO_REFUND_OF_HANDLING_FEE: 'NO_REFUND_OF_HANDLING_FEE',
  },
  ProviderType: {
    GOOGLE: '谷歌账号',
    FACEBOOK: 'Facebook账号',
    APPLE: '苹果账号',
    PASSWORD: '密码',
  },
  PaymentChannel: {
    INDIA_UPI_1: 'PPAY-印度UPI一类',
    INDIA_NATIVE_1: 'PPAY-印度原生一类',
    INDIA_UPI_2: 'PPAY-印度UPI二类',
    INDIA_NATIVE_2: 'PPAY-印度原生二类',
    BRAZIL_PIX_1: 'PPAY-巴西PIX一类',
    BRAZIL_PIX_2: 'PPAY-巴西PIX二类',
    BRAZIL_PIX_FAST: 'PPAY-巴西PIX快杀',
    EPAY_BRAZIL_TS_B2C: 'EPAY-巴西-TS-B2C',
    EPAY_BRAZIL_TS_B2B: 'EPAY-巴西-TS-B2B',
    EPAY_INDIA_IY_B2C: 'EPAY-印度-IY',
    NONE: '无',
  },
  BankInfoExtType: {
    NORMAL: 'NORMAL',
    PIX_EXT: 'PIX_EXT',
    IFSC_EXT: 'IFSC_EXT',
    BANK_EXT: 'BANK_EXT',
  },
  PixType: {
    CPF: '个人身份识别号码',
    PHONE: '手机号码',
    EMAIL: '电子邮件地址',
    EVP: '虚拟支付地址',
  },
  EpayCountryCode: {
    BR: '巴西',
  },
  EpayGender: {
    MALE: '男',
    FEMALE: '女',
  },
  EpayIdType: {
    ID_CARD: '身份证',
    PASSPORT: '护照',
  },
  EpayPurposeOfRemittance: {
    CorporatePayment: '企业付款',
    Marketplace: '市场交易',
    ProfessionalServices: '专业服务',
    Payroll: '工资支付',
    ExpensesReimbursement: '费用报销',
    RepresentationExpenses: '企业付款',
    HiredPersonnel: '聘请人员',
    TraineesScholarships: '培训生/奖学金',
    ProductivityOrQualityPrizes: '生产力或质量奖项',
    LayOffPayment: '裁员支付',
    FamilySupport: '家庭支持',
    Education: '教育',
    GiftAndOtherDonations: '礼物和其他捐赠',
    MedicalTreatment: '医疗治疗',
    MaintenanceOrOtherExpenses: '维护或其他费用',
    CarDriver: '司机',
    SoftwareLicense: '软件许可',
    HotelAccommodation: '酒店住宿',
    AdvertisingAndOrPublicRelationsRelatedExpenses: '广告和/或公共关系相关费用',
    FeesForAdvisoryOrConsultingService: '咨询或顾问服务费',
    BusinessRelatedInsurancePayment: '商业相关保险支付',
    InsuranceClaimsPayment: '保险索赔支付',
  },
  Gender: {
    MALE: '男性',
    FEMALE: '女性',
    UNKNOWN: '未知',
    NOT_APPLICABLE: '不适用',
  },
  BeneficiaryRelationShip: {
    BOYFRIEND: '男朋友',
    BUSINESS_PARTNER: '合伙人',
    CHILDREN: '子女',
    COLLEAGUE: '同学',
    EX_SPOUSE: '前配偶',
    FIANCE: '未婚夫',
    FIANCEES: '未婚妻',
    FRIEND: '朋友',
    GIRLFRIEND: '女朋友',
    GRAND_PARENTS: '祖父母',
    PARENTS: '父母',
    RELATIVE_UNCLE_AUNTIE_COUSIN: '亲戚/叔叔/阿姨/表弟',
    SELF: '自己',
    SIBLING_BROTHER_SISTER: '同胞/兄弟/姐妹',
    SPOUSE: '配偶',
    SUBSIDIARY_COMPANY: '子公司',
  },
  PayPurpose: {
    DEFAULT: '默认',
    ENTRY_FEE: '比赛报名费',
    SERVICE_FEE: '平台服务费',
    OTHER: '其他',
  },
};
