let router;

// active route 相关的都放这里

function setRouter(r) {
  router = r;
}

function routerTo(path) {
  if (router.currentRoute.value.fullPath !== path) {
    return router.push(path).catch(err => console.log(err));
  }
}

function linkTo(route, params) {
  if (router.currentRoute.value.fullPath !== route?.url) {
    return router.push({ name: route.name, params }).catch(() => {});
  }
}

function routerReplace(path) {
  if (router.currentRoute.value.fullPath !== path) {
    return router.replace(path).catch(() => {});
  }
}

export function useRouter() {
  return {
    router,
    routerTo,
    linkTo,
    routerReplace,
    setRouter,
  };
}
