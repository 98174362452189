<template>
  <NFormItem v-bind="formItemBaseAttrs" class="n-input-keyword">
    <BaseInput class="n-input" v-bind="baseAttrs" @press-enter="onPressEnter" />
  </NFormItem>
</template>

<script setup>
import { isEmpty } from 'lodash-es';
import { useLanguage } from '@/hooks';
import { computed, useAttrs } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';

const attrs = useAttrs();
const emits = defineEmits(['pressEnter']);
const { tl } = useLanguage();

const props = defineProps({
  label: { type: String, default: 'searchKey' },
  placeholder: { type: Array, default: () => [] },
});

const { formItemBaseAttrs } = useFormItemBaseAttrs(props);

const baseAttrs = computed(() => {
  let placeholder = '';
  if (!isEmpty(props.placeholder)) placeholder = props.placeholder.join(' / ');
  return { ...attrs, placeholder, allowClear: true };
});

const onPressEnter = v => emits('pressEnter', v);
</script>

<script>
export default {
  name: 'NInputKeyword',
  inheritAttrs: false,
};
</script>

<style lang="less">
.n-input-keyword {
  input::-webkit-input-placeholder {
    font-size: 12px;
    letter-spacing: -0.05em;
  }
}
</style>
