<template>
  <NInput class="n-input-id" :value-pattern="inputableIdRegex" v-bind="componentAttrs" />
</template>

<script setup>
import { computed, useAttrs } from 'vue';
import { inputableIdRegex } from '@/constant';

const attrs = useAttrs();

const componentAttrs = computed(() => ({
  ...attrs,
  label: !attrs.label && !attrs.customLabel ? 'id' : attrs.label,
}));
</script>

<script>
export default {
  name: 'NInputId',
};
</script>
