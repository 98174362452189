export default {
  verify: 'Verify',
  transfer: 'Transfer',
  identification: 'Identification',
  withdrawal: 'Withdrawal',
  itInitiate: 'Initiate (Institution)',
  itProcessing: 'Processing (Institution)',
  itEdit: 'Edit (Institution)',
  itCancel: 'Cancel (Institution)',
  itConfirm: 'Confirm (Institution)',
  itForceCancel: 'Force Cancel (Institution)',
  itForceConfirm: 'Force Confirm (Institution)',
  customerForceCancel: 'Force Cancel (Customer)',
  customerForceConfirm: 'Force Confirm (Customer)',
  alert: {
    identification: 'Online verify of customer-initiated "Identification".',
    withdrawal: 'Online verify of customer-initiated "withdraw".',
    itInitiate: 'The current institution initiate transfer, match the customer withdrawals that meets the condition.',
    itProcessing: 'Processing of institution-initiated transfers.',
    itProcessingAction1: 'Step-1: Institution actually transfer money to customer.',
    itProcessingAction2: 'Step-2: Screenshot transfer proof.',
    itProcessingAction3: "Step-3: Upload transfer proof (status change to 'Transferred' after completed).",
    itEdit: 'Edit "Proof" or "Remark" of institution-initiated transfers.',
    itCancel: 'Cancel of institution-initiated transfers.',
    itConfirm: 'Confirm the institution has received the payments.',
    itForceCancel: 'Force to cancel of institution-initiated transfers.',
    itForceConfirm: 'Force to confirm the institution has received the payments.',
    customerForceCancel: 'Force to cancel of customer-initiated transfers.',
    customerForceConfirm: 'Force to confirm the customer has received the payments.',
  },
};
