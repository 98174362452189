export default {
  verify: '审核',
  transfer: '转账',
  identification: '身份',
  withdrawal: '提现',
  itInitiate: '发起（机构）',
  itProcessing: '处理（机构）',
  itEdit: '编辑（机构）',
  itCancel: '取消（机构）',
  itConfirm: '收款（机构）',
  itForceCancel: '强制取消（机构）',
  itForceConfirm: '强制收款（机构）',
  customerForceCancel: '强制取消（客户）',
  customerForceConfirm: '强制收款（客户）',
  alert: {
    identification: '在线审核客户发起的“身份认证”。',
    withdrawal: '在线审核客户发起的“提现申请”。',
    itInitiate: '当前机构发起转账，匹配满足条件的客户提现。',
    itProcessing: '处理机构发起的转账。',
    itProcessingAction1: '第一步：机构给客户实际转账',
    itProcessingAction2: '第二步：截图转账凭证',
    itProcessingAction3: '第三步：上传转账凭证（状态变成‘已转账’）',
    itEdit: '针对机构发起的转账，编辑“凭证”或“留言”。',
    itCancel: '取消机构发起的转账。',
    itConfirm: '确认机构已收到付款。',
    itForceCancel: '强制取消机构发起的转账。',
    itForceConfirm: '强制确认机构已收到付款。',
    customerForceCancel: '强制取消客户发起的转账。',
    customerForceConfirm: '强制确认客户已收到付款。',
  },
};
