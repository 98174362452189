export function isIP(str) {
  return /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/.test(str);
}

export function isObjectJSON(str) {
  try {
    const r = JSON.parse(str);
    return '[object Object]' === Object.prototype.toString.call(r);
  } catch (e) {
    // Do nothing
  }
  return false;
}

export function isArrayJSON(str) {
  try {
    const r = JSON.parse(str);
    return '[object Array]' === Object.prototype.toString.call(r);
  } catch (e) {
    // Do nothing
  }
  return false;
}
