export default {
  ADMIN_DUP_USERNAME: '該用戶名已經存在',
  DOB_IS_BEFORE_TODAY: '出生日期必須在當前日期之前',
  BANK_CARD_DUP_NUMBER: '該收款人賬號已經存在',
  PERMISSION_DENY_ADMIN_ONLY: '只有系統管理員才可以執行該操作',
  PERMISSION_DENY_ADMIN_OR_IT_ONLY: '只有系統管理員或者機構管理員才可以執行該操作',
  // 5.1 基础相关
  INVALID_ARGUMENT_BAD_AGENT: '賬戶信息錯誤[0010]',
  INVALID_ARGUMENT_MIN_DEPOSIT: '必須超過最小充值限額：{minDeposit}美元（或等值貨幣金額）',
  INVALID_ARGUMENT_MAX_DEPOSIT: '不能超過最大充值限額：{maxDeposit}美元（或等值貨幣金額）',
  INVALID_ARGUMENT_MIN_WITHDRAWAL: '必須超過最小取款限額：{minWithdrawal}美元（或等值貨幣金額）',
  INVALID_ARGUMENT_MAX_WITHDRAWAL: '不能超過最大取款限額：{maxWithdrawal}美元（或等值貨幣金額）',
  INVALID_ARGUMENT_MORE_THAN_2_SCALES: '小數位不能超過兩位',
  INVALID_ARGUMENT_RESOURCE_SAVE: '資源保存參數錯誤',
  INVALID_ARGUMENT_NULL_PASSWORD: '密碼不能為空',
  INVALID_ARGUMENT_CONFIRM_PASSWORD: '確認密碼不匹配',
  INVALID_ARGUMENT_FORMAT_PASSWORD: '必須至少包含：一個大寫字母、一個小寫字母、一個數字，並且密碼長度為8~32位字元',
  INVALID_ARGUMENT_PASSWORD_VER_MISMATCH: '密碼版本不匹配',
  INVALID_ARGUMENT_MISSING_PASSWORD_VER_CLAIM: '缺少密碼版本聲明',
  INVALID_ARGUMENT_CAN_NOT_DELETE_FOR_VERIFIED: '已認證數據不能刪除',
  INVALID_ARGUMENT_ENTER_BANK_NUM: '請輸入銀行卡號',
  INVALID_ARGUMENT_IT_DUP_ID: '機構ID已經存在',
  INVALID_ARGUMENT_IT_DUP_APP_KEY: '用戶名已經被使用，請更換用戶名',
  INVALID_REGISTER: '禁止註冊',
  INVALID_DATA_USED_CANNOT_BE_DELETED: '已使用的數據不能刪除',
  INVALID_ARGUMENT_CONFIG_BANK_NOT_NULL: 'BANK資訊不可為空,請去銀行卡管理頁面補充BANK信息',
  // 5.2 货币相关
  INVALID_ARGUMENT_CURRENCY_BASE_QUOTE_CAN_NOT_BE_THE_SAME: '兩個貨幣不能相同',
  INVALID_ARGUMENT_FX_RATE_CAN_BE_NULL: '匯率不能是空',
  INVALID_ARGUMENT_FX_RATE_MUST_BE_POSITIVE: '匯率不能是零或者負數',
  INVALID_ARGUMENT_CURRENCY_DUP_CURRENCY_PAIR: '相同的貨幣對已經存在',
  INVALID_ARGUMENT_CURRENCY_AMOUNT_NOT_MATCH: '匯率轉化金額有誤',
  INVALID_ARGUMENT_CURRENCY_FX_RATE_NOT_FOUND: '未找到貨幣對所對應的匯率',
  INVALID_ARGUMENT_CURRENCY_FX_RATE_TEMPLATE_NOT_FOUND: '未找到匯率模板',
  INVALID_ARGUMENT_CURRENCY_INVALID_FOR_THIS_BANK: '當前銀行不支持此貨幣',
  INVALID_ARGUMENT_CURRENCY_INVALID_FOR_THIS_RECEIVE_METHOD: '當前收款方式不支持此貨幣',
  // 5.3 用户相关
  INVALID_ARGUMENT_LOGIN_AUTH_BY_GOOGLE: '電子郵件已經獲得 GOOGLE 授權，請使用 GOOGLE 授權登入',
  INVALID_ARGUMENT_LOGIN_AUTH_BY_FACEBOOK: '電子郵件已經獲得 FACEBOOK 授權，請使用 FACEBOOK 授權登入',
  INVALID_ARGUMENT_LOGIN_AUTH_BY_APPLE: '電子郵件已經獲得 APPLE 授權，請使用 APPLE 授權登入',
  INVALID_ARGUMENT_LOGIN_AUTH_BY_PASSWORD: '電子郵件已使用密碼授權，請使用密碼登入',
  INVALID_ARGUMENT_CUSTOMER_FORMAT: '用戶名必須為郵箱地址',
  INVALID_ARGUMENT_CUSTOMER_DUP_USERNAME: '用戶名已激活',
  INVALID_ARGUMENT_CUSTOMER_DUP_MOBILE: '手機號已存在',
  INVALID_ARGUMENT_CUSTOMER_NOT_VERIFIED: '客戶信息未認證',
  INVALID_ARGUMENT_CUSTOMER_AUTH_NOT_EXIST: '客戶認證信息不存在',
  INVALID_ARGUMENT_CUSTOMER_NOT_EXIST: '客戶信息不存在',
  INVALID_ARGUMENT_EMAIL_CODE_NOT_EXIST: '鏈接不存在',
  INVALID_ARGUMENT_EMAIL_CODE_REPEAT: '鏈接已經失效',
  INVALID_ARGUMENT_EMAIL_CODE_TIMEOUT: '鏈接已經過期',
  INVALID_ARGUMENT_CUSTOMER_ACCOUNT_NOT_ACTIVATED: '客戶交易賬號未激活',
  INVALID_ARGUMENT_CUSTOMER_ID_EXPIRE_LIMIT: '身份信息過期日必須至少剩餘{days}天',
  INVALID_ARGUMENT_CHANGE_AGENT_CAN_NOT_CHANGE_IT: '只能更換為同一機構下的不同代理',
  INVALID_ARGUMENT_CHANGE_AGENT_SAME_AGENT: '更換前與更換後的代理相同',
  INVALID_ARGUMENT_CHANGE_AGENT_HAS_POSITION_OR_ACTIVE_ORDER: '當前賬戶存在持倉或掛單，不能更換代理',
  // 5.4 业务流程相关
  INVALID_ARGUMENT_UNABLE_TO_LOCK_WITHDRAWAL_AMOUNT: '無法鎖定額度，請刷新后再試',
  INVALID_ARGUMENT_PROCESS_SUBMIT_ILLEGAL_STATUS: '狀態已經是提交',
  INVALID_ARGUMENT_PROCESS_RETRACT_ILLEGAL_STATUS: '不能被撤回',
  INVALID_ARGUMENT_PROCESS_APPROVAL_ILLEGAL_STATUS:
    '審核狀態錯誤：審核狀態為提交時才允許駁回或通過操作（數據可能已發生變化，請重新檢索數據后再嘗試）',
  INVALID_ARGUMENT_PROCESS_APPROVAL_ILLEGAL_PROCESSED:
    '處理狀態錯誤：處理狀態為未處理時才允許駁回或通過操作（數據可能已發生變化，請重新檢索數據后再嘗試）',
  INVALID_ARGUMENT_PROCESS_APPROVED_FLOW_ILLEGAL_STATUS:
    '審核狀態錯誤：審核狀態為通過時才允許完成處理操作（數據可能已發生變化，請重新檢索數據后再嘗試）',
  INVALID_ARGUMENT_PROCESS_APPROVED_FLOW_ILLEGAL_PROCESSED:
    '處理狀態錯誤：處理狀態為未處理時才允許完成處理操作（數據可能已發生變化，請重新檢索數據后再嘗試）',
  INVALID_ARGUMENT_PROCESS_REJECT_FLOW_ILLEGAL_STATUS:
    '審核狀態錯誤：審核狀態為駁回時才允許完成處理操作（數據可能已發生變化，請重新檢索數據后再嘗試）',
  INVALID_ARGUMENT_PROCESS_ILLEGAL_STATUS: '狀態錯誤',
  // 5.5 KRAMPUS API相关
  INVALID_ARGUMENT_KRAMPUS_API_DUP_LOGIN: '交易系統用戶名已存在',
  INVALID_ARGUMENT_KRAMPUS_API_WRONG_KEY_OR_SECRET: '交易系統用戶名/密碼錯誤',
  INVALID_ARGUMENT_KRAMPUS_API_LOGIN_FAILED: '交易系統登錄失敗',
  INVALID_ARGUMENT_DEMO_KRAMPUS_API_DUP_LOGIN: '模擬交易系統用戶名已存在',
  INVALID_ARGUMENT_DEMO_KRAMPUS_API_WRONG_KEY_OR_SECRET: '模擬交易系統用戶名/密碼錯誤',
  INVALID_ARGUMENT_DEMO_KRAMPUS_API_LOGIN_FAILED: '模擬交易系統登錄失敗',
  INVALID_ARGUMENT_KRAMPUS_API_SYNC_IT_FAILED: '同步機構信息失敗，未找到交易系統ID為{itId}的機構，請查看許可權',
  INVALID_ARGUMENT_KRAMPUS_API_INVOKE_ERROR: '交易系統調用失敗',
  INVALID_ARGUMENT_KRAMPUS_API_INSUFFICIENT_FUNDS: '賬戶餘額不足',
  INVALID_ARGUMENT_KRAMPUS_API_PASSWORD_IS_EMPTY: '密碼為空，請先設置密碼',
  INVALID_ARGUMENT_KRAMPUS_API_TOKEN_FAILED: '登錄過期，請重新登錄',
  // token相关
  INVALID_ARGUMENT_INVALID_ISSUER_FORMAT: 'Issuer格式無效',
  INVALID_ARGUMENT_MISSING_LOGIN_CODE: '缺少登錄代碼',
  INVALID_ARGUMENT_CUSTOMER_NOT_FOUND: '找不到客戶',
  INVALID_ARGUMENT_MISSING_PASSWORD_TIME_CLAIM: '缺少密碼時間聲明',
  INVALID_ARGUMENT_PASSWORD_TIME_MISMATCH: '密碼時間不匹配',
  INVALID_ARGUMENT_TOKEN_EXPIRED: '令牌已過期',
  INVALID_ARGUMENT_METHOD_BANK_USDT_INVALID: '銀行的充值貨幣不能是USDT',
  INVALID_ARGUMENT_METHOD_BANK_BANK_CAN_NOT_NULL: '請選擇銀行',
  INVALID_ARGUMENT_METHOD_BANK_ACCOUNT_NAME_CAN_NOT_EMPTY: '銀行帳號名稱不可以為空',
  INVALID_ARGUMENT_METHOD_BANK_ACCOUNT_NUMBER_CAN_NOT_EMPTY: '銀行帳號號碼不可以為空',
  INVALID_ARGUMENT_METHOD_USDT_USDT_ONLY: 'USDT的充值貨幣必須是USDT',
  INVALID_ARGUMENT_METHOD_USDT_AT_LEAST_ONE_IS_REQUIRED: '地址和二維碼至少需要填寫一個',
  INVALID_ARGUMENT_METHOD_USDT_CRYPTO_NETWORK_REQUIRED: 'USDT的充值網路不能為空',
  INVALID_ARGUMENT_METHOD_WECHAT_CNY_ONLY: '微信的充值貨幣必須是人民幣',
  INVALID_ARGUMENT_METHOD_REQUIRE_QR_CODE: '當前充值方式必須上傳二維碼',
  // 5.7 入出金相关
  INVALID_ARGUMENT_IT_TRANSFER_CONFIG_ERROR: '通道配置錯誤',
  INVALID_ARGUMENT_IT_TRANSFER_CAN_NOT_BE_NULL: '機構轉賬不可為空',
  INVALID_ARGUMENT_IT_TRANSFER_AMOUNT_CAN_NOT_BE_NULL: '機構轉賬金額不可為空',
  INVALID_ARGUMENT_IT_TRANSFER_RECEIVE_CAN_NOT_BE_NULL: '機構轉賬帳號不可為空',
  INVALID_ARGUMENT_DEPOSIT_AMOUNT_TRANSFER_AMOUNT_NOT_MATCH: '存入金額與轉賬金額不匹配',
  INVALID_ARGUMENT_PAYMENT_SAME_TYPE_PAYMENT_CHANNEL_ONLY_ONE: '相同類型的支付通道只能選擇一個',
  INVALID_ARGUMENT_DUPLICATE_PAYMENT_CHANNEL_TYPE: '相同類型的支付網關只能選擇一個',
  INVALID_ARGUMENT_PAYMENT_INVOKE_ERROR: '調用支付API接口失敗',
  INVALID_ARGUMENT_PAYMENT_INVOKE_PARAM_ERROR: '調用支付參數錯誤',
  INVALID_ARGUMENT_TRANSACTION_AMOUNT: '入出金不滿足限定額度',
  INVALID_ARGUMENT_CLOSE_ACCOUNT_ERROR: '註銷賬戶失敗，必須清空持倉並且資金為零',
  INVALID_ARGUMENT_CONFIG_PIX_NOT_NULL: 'PIX資訊不得為空，請於銀行卡管理頁面更新您的PIX資訊。',
  INVALID_ARGUMENT_CONFIG_IFSC_NOT_NULL: 'IFSC資訊不得為空，請於銀行卡管理頁面更新您的IFSC資訊。',
  // 6. 操作校验
  UNSUPPORTED_OPERATION_NO_IT_RECEIVE_FOUND: '該機構不支持該貨幣的入金',
  // 7. 邮箱验证
  THIRD_PARTY_AUTH_USER_CANNOT_RESET_PASSWORD: '三方認證賬號無法重置密碼',
  INVALID_THIRD_PARTY_ACCOUNT_UNBINDING_FAILED: '三方賬號解除綁定失敗',
  INVALID_EMAIL_CHECK_INVALID_USER: '無效用戶',
  INVALID_EMAIL_CHECK_USER_NOT_EXIST: '用戶不存在',
  THIRD_PARTY_AUTH_USER_CANNOT_ACTIVE_EMAIL: '三方認證賬號無需激活賬戶',
  INVALID_ACCOUNT_ALREADY_EXISTS_AND_LOCKED: '該賬戶已存在且被鎖定',
  INVALID_EMAIL_CHECK_EMAIL_VERIFIED: '郵箱已經驗證',
  INVALID_EMAIL_CHECK_EMAIL_NOT_VERIFIED: '郵箱未經驗證',
  INVALID_EMAIL_CHECK_INVALID_REQUEST_PARAMETERS: '操作失敗,請求超時',
  INVALID_EMAIL_CHECK_REPEATED_REQUEST: '重複請求,請稍後嘗試',
  INVALID_ARGUMENT_INVALID_PROVIDERTYPE: '無效的三方登陸類型',
  INVALID_ARGUMENT_ORIGINAL_PASSWORD_MISMATCH: '修改密碼,原始密碼不匹配',
  INVALID_ARGUMENT_NEW_PASSWORD_SAME_AS_OLD: '新密碼不能和舊密碼一樣',
  INVALID_ARGUMENT_EXCLUSIVE_AGENT_LINK: '無法註冊，該鏈接屬於專屬代理,禁止多次註冊',
  // 注册
  REGISTER_DUP_USERNAME: '該用戶名已被註冊',
  REGISTER_DUP_MOBILE: '該手機號已被註冊',
  USERNAME_WRONG_FORMAT: '用戶名格式錯誤',
  MOBILE_WRONG_FORMAT: '手機號格式錯誤',
  ID_CARD_ID_WRONG_FORMAT: '身份證號碼格式錯誤',
  // 登录
  LOGIN_FAILED: '登錄失敗，用戶名或密碼有誤',
  LOGIN_NOT_ENABLED: '帳號已被禁用',
  LOGIN_LOCKED: '帳號已被鎖定',
  // 密码
  PASSWORD_WRONG: '密碼輸入錯誤',
  PASSWORD_WRONG_FORMAT: '密碼必須為6~16位字元，並且不能包含空格',
  PASSWORD_WRONG_TIME_LEFT: '密碼輸入錯誤，還剩{timeLeft}次機會',
  PASSWORD_CONFIRM_NOT_MATCH: '確認密碼不匹配',
  PASSWORD_WRONG_AND_LOCK: '連續{failedAttempt}次密碼輸入錯誤，帳號被鎖定',
  PASSWORD_NULL: '密碼不能為空',
  CONFIRM_PASSWORD_NOT_MATCH: '確認密碼不匹配',
  // 权限
  PERMISSION_DENY: '你沒有訪問許可權',
  // Jwt Token
  JWT_INVALID_TOKEN: '登錄過期，請重新登錄',
  JWT_VERSION_NOT_MATCHED: '版本不匹配',
  // 手机验证码
  SMS_VERIFY_CODE_NOT_MATCH: '驗證碼不匹配',
  CAPTCHA_VERIFY_CODE_NOT_MATCH: '驗證碼不匹配',
  // 参数校验
  INVALID_ARGUMENT: '參數錯誤',
  INVALID_ARGUMENT_NULL_POINT: '參數錯誤：參數不能為空',
  // 其他
  DATA_NOT_FOUND: '數據未找到',
  STALE_VERSION: '數據改變，請刷新后再試',
  INVALID_STATE: '無效狀態，數據可能已發生改變',
  INVALID_VALUE: '參數內容錯誤',
  INVALID_FORMAT: '參數格式錯誤',
  INTERNAL_ERROR: '內部錯誤，請聯繫管理員',
  MULTI_ARGS_TEST: 'arg1 {arg1}，arg2 {arg2}，arg3 {arg3}',
  INVALID_ARGUMENT_NOT_BLANK: '不能是空白字符',

  // 第三方
  access_denied: '訪問拒絕',
  invalid_token_response: '無效的令牌',
  authorization_request_not_found: '未找到認證請求',
};
