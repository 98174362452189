<template>
  <NDropdown v-if="isProxyMode" :trigger="['click']" placement="bottomRight" @visible-change="v => (v ? onOpen() : onClose())">
    <span class="n-channel-switch">
      <slot>
        <NChannelSwitchBtn :class="{ 'theme-white': theme === 'white' }" />
      </slot>
    </span>

    <template #overlay>
      <NMenu class="n-channel-switch-menu" @click="onClick">
        <NMenuItem
          v-for="(item, index) in options"
          :key="item.value"
          :disabled="item.disabled"
          :class="{ 'c-selected': curChannel.value === item.value }"
        >
          <div class="flex min-w-[100px]">
            <div class="flex-1">{{ item.label }}</div>
            <div v-if="curChannel.value === AUTO_CHANNEL || index !== 0" class="text-[12px]" :class="item.class">
              {{ item.extraLabel }}
            </div>
          </div>
        </NMenuItem>
      </NMenu>
    </template>
  </NDropdown>
</template>

<script setup>
import { ref, computed, onUnmounted } from 'vue';
import { minBy } from 'lodash-es';
import { useLanguage } from '@/hooks';
import { channels, curChannel, setChannel, isProxyMode, AUTO_CHANNEL } from '@/services';
import { pingChannel as ping } from '@/glue';
import NChannelSwitchBtn from './NChannelSwitchBtn.vue';

const props = defineProps({
  theme: String,
});
const emit = defineEmits(['update:value']);

const { tl } = useLanguage();

const pings = ref([]);
const pingTimers = [];
const pingDelay = 10000;
let requestId = 0;
const options = computed(() => {
  let hasAvailableChannel = false;
  const list = [
    ...channels.value.map((channel, index) => {
      const ping = pings.value[index];
      const disabled = !(ping > -1);
      hasAvailableChannel = hasAvailableChannel || !disabled;
      return {
        label: `${tl('channel')} ${index + 1}`,
        value: channel,
        ping,
        extraLabel: ping > -1 ? ping + 'ms' : '-',
        disabled,
        class: {
          'text-green-500': ping > 0 && ping <= 500, // [green,500,orange,2000,red]
          'text-orange-500': ping > 500 && ping <= 2000,
          'text-red-500': ping > 2000,
        },
      };
    }),
  ];
  const option = list.find(o => o.value === curChannel.url);
  list.unshift({ label: tl('autoChannel'), value: AUTO_CHANNEL, disabled: !hasAvailableChannel, extraLabel: option?.label });
  return list;
});

onUnmounted(() => {
  onClose();
});

function onClick({ key }) {
  let url = key;
  if (key === AUTO_CHANNEL) {
    url = minBy(
      options.value.filter(o => o.ping > 0),
      'ping',
    )?.value;
  }
  setChannel(key, url);
  onClose();
}

function clearPingTimer() {
  pingTimers.forEach(clearTimeout);
  pingTimers.length = 0;
}

function pingChannel(channel, index) {
  const curRequestId = requestId;
  ping(channel)
    .then(delay => {
      if (curRequestId === requestId) pings.value[index] = delay;
    })
    .catch(() => {
      if (curRequestId === requestId) pings.value[index] = -1;
    })
    .finally(() => {
      if (curRequestId === requestId) {
        pingTimers[index] = setTimeout(() => {
          pingChannel(channel, index);
        }, pingDelay);
      }
    });
}

function onOpen() {
  requestId++;
  clearPingTimer();
  pings.value.length = 0;
  channels.value.forEach((channel, index) => pingChannel(channel, index));
}

function onClose() {
  requestId++;
  clearPingTimer();
}
</script>

<script>
export default {
  name: 'NChannelSwitch',
};
</script>

<style lang="less">
.n-channel-switch {
  display: inline-block;
  &-menu {
    .c-selected {
      background: var(--ant-primary-1);
      font-weight: bold;
    }
  }
}
</style>
