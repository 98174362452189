import { computed } from 'vue';
import { isEmpty } from 'lodash-es';
import { useBootstrap } from '@admin/hooks';

export const useMenus = (menus = []) => {
  const { isSuperUser, isItUser, isAgentUser, userProfile } = useBootstrap();

  const curMenus = computed(() => {
    let curMenus = [];
    menus?.forEach(parent => {
      const curMenu = { ...parent };
      if (isSuperUser.value) curMenus.push(curMenu);
      else {
        let children = [];
        curMenu.children.forEach(m => {
          if (isEmpty(m.meta.hidden) && isEmpty(m.meta.lookupHidden)) children.push(m);
          else if (
            isItUser.value &&
            !m.meta.hidden?.find(o => o === 'ROLE_IT') &&
            !m.meta.lookupHidden?.find(o => userProfile.value[o] ?? false)
          )
            children.push(m);
          else if (
            isAgentUser.value &&
            !m.meta.hidden?.find(o => o === 'ROLE_AGENT') &&
            !m.meta.lookupHidden?.find(o => userProfile.value[o] ?? false)
          )
            children.push(m);
        });
        curMenu.children = children;
        if (!isEmpty(curMenu.children)) curMenus.push(curMenu);
      }
    });
    return curMenus ?? [];
  });

  const flatMenus = computed(() => {
    let flatMenus = [];
    curMenus.value?.forEach(menu => {
      const { name, path, icon } = menu;
      menu.children?.forEach(children => {
        flatMenus.push({
          ...children,
          path: `${menu.path}${children.path}`,
          firstLevel: { name, path, icon },
          isSub: Array.isArray(children.meta.cacheName),
        });
      });
    });
    return flatMenus?.map(o => {
      if (o.isSub) o.parent = flatMenus?.find(f => f.meta.cacheName === o.meta.cacheName[0]);
      return o;
    });
  });

  return { curMenus, flatMenus };
};
