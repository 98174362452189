import { ref, computed } from 'vue';

let _collapsed = ref(false);

export const useLayout = () => {
  const setCollapsed = collapsed => (_collapsed.value = collapsed);

  const collapsed = computed(() => _collapsed.value);

  return { collapsed, setCollapsed };
};
