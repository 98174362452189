<template>
  <div class="">
    <div class="ant-row ant-form-item">
    <NCheckbox v-bind="componentAttrs" class="n-labeled-checkbox">
      {{ label }}
    </NCheckbox>
    </div>
  </div>
</template>

<script setup>
import { computed, useAttrs } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';

const attrs = useAttrs();

const props = defineProps({
  label: String,
  name: [String, Array],
  customLabel: String,
});

const { componentBaseAttrs } = useFormItemBaseAttrs(props);

const componentAttrs = computed(() => ({
  ...componentBaseAttrs.value,
  ...attrs,
}));
</script>

<script>
export default {
  name: 'NLabeledCheckBox',
  inheritAttrs: false,
};
</script>

<style lang="less">
.n-labeled-checkbox {
  display: inline-flex !important;
}
</style>
