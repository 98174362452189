export default {
  rolePos: {
    ROLE_IT: '機構管理員',
    ROLE_AGENT: '代理管理員',
  },
  booleanOptions: {
    YES: '是',
    NO: '否',
  },
};
