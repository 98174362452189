import { find, maxBy, sortBy, forEach, findIndex } from 'lodash-es';
import { ref, reactive, computed } from 'vue';

let ID = 1;
const MAX_DISPLAY_ORDER = 100;

const POSITION_OFFSET = { left: 10, top: 10 };

const panelList = ref([]);

export function SetBoundingBox(config) {
  // Ken 2020-01-07 21:27 设置最小值 不判断>0的话会有NaN, undefined的问题 (Math.max(123, undefined) === NaN)
  if (config.minWidth > 0) {
    config.width = Math.max(config.width, config.minWidth);
  }
  if (config.minHeight > 0) {
    config.height = Math.max(config.height, config.minHeight);
  }
}

function getMaxDisplayOrder() {
  return maxBy(panelList.value, 'displayOrder')?.displayOrder ?? 0;
}

function resetDisplayOrder() {
  const list = sortBy(panelList.value, ['displayOrder'], ['asc']);
  forEach(list, (panel, index) => (panel.displayOrder = index + 1));
}

function checkDisplayOrder() {
  const maxDisplayOrder = getMaxDisplayOrder();
  if (maxDisplayOrder >= MAX_DISPLAY_ORDER) {
    resetDisplayOrder();
  }
}

function getShiftPosition(left, top) {
  const samePositionPanel = find(panelList.value, { left, top });
  if (samePositionPanel) {
    left += POSITION_OFFSET.left;
    top += POSITION_OFFSET.top;
    return getShiftPosition(left, top);
  } else {
    return { left, top };
  }
}

function createPanel(panelConfig) {
  checkDisplayOrder();

  const newConfig = {
    width: 800,
    height: 450,
    resizable: true,
    closeable: true,
    minimizable: true,
    minimized: false,
    ...panelConfig,
    id: Date.now() + '_' + ID++,
    displayOrder: getMaxDisplayOrder() + 1,
  };

  if (typeof newConfig.maximizable !== 'boolean') {
    newConfig.maximizable = newConfig.resizable;
  }

  SetBoundingBox(newConfig);
  let left = panelConfig.left ?? (document.body.clientWidth - newConfig.width) / 2;
  let top = panelConfig.top ?? Math.max(0, (document.body.clientHeight - newConfig.height) / 4);
  panelList.value.push({ ...newConfig, ...getShiftPosition(left, top) });
}

function closePanel(id) {
  const index = findIndex(panelList.value, { id });
  if (index > -1) {
    panelList.value.splice(index, 1);
  }
}

function moveToTop(id) {
  const panel = find(panelList.value, { id });
  if (panel) {
    const alreadyTop = panel === maxBy(panelList.value, 'displayOrder');
    if (alreadyTop) {
      return;
    }
    checkDisplayOrder();
    panel.displayOrder = getMaxDisplayOrder() + 1;
  } else {
    console.error('moveToTop error, no panel found, id = ', id);
  }
}

function setAttrs(panelConfig = {}) {
  const index = findIndex(panelList.value, { id: panelConfig.id });
  if (index > -1) {
    Object.assign(panelList.value[index], panelConfig);
    SetBoundingBox(panelList.value[index]);
  } else {
    console.error('setAttrs error, no panel found, id = ', panelConfig.id);
  }
}

function findPanel(options = {}) {
  return find(panelList.value, options);
}

function clearPanels() {
  panelList.value = [];
}

export function usePanel(config = {}) {
  return {
    panelList,
    createPanel,
    closePanel,
    moveToTop,
    setAttrs,
    findPanel,
    clearPanels,
  };
}
