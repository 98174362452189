<template>
  <span :class="className">
    <div v-if="valid">
      <CheckOutlined v-show="value" class="n-success" />
      <CloseOutlined v-show="!value" class="n-error" />
    </div>
    <div v-else>{{ replacement }}</div>
  </span>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  value: Boolean,
  align: { type: String, default: 'left' },
  replacement: { type: String, default: '-' },
});

const valid = computed(() => typeof props.value === 'boolean');

const className = computed(() => `n-text-${props.align}`);
</script>
