<template>
  <div v-if="visible" class="n-back-top">
    <div class="n-back-top-content">
      <div class="n-back-top-icon"></div>
    </div>
  </div>
</template>

<script>
import { useBackTop } from '@/hooks';
import { defineComponent, ref, nextTick, onMounted, onBeforeUnmount, onActivated, onDeactivated, computed } from 'vue';

export default defineComponent({
  name: 'NBackTop',

  inheritAttrs: false,

  props: {
    target: { type: Function, default: () => ({}) },
  },

  setup(props) {
    const visible = ref(false);

    const loaded = ref(false);

    let resizeFunc = undefined;

    let scrollTopTimer = undefined;

    const { backTopCoordinate, backTopVisibilityHeight, setBackTopCoordinate } = useBackTop();

    const target = computed(() => props.target() ?? {});

    onActivated(() => {
      if (loaded.value) return;
      initVisible(target);
    });

    onDeactivated(() => {
      loaded.value = false;
    });

    onMounted(() => {
      nextTick(() => {
        loaded.value = true;
        initVisible(target.value);
        target.value.addEventListener('scroll', handleScroll);
      });
    });

    onBeforeUnmount(() => {
      target.value.removeEventListener('scroll', handleScroll);
      if (resizeFunc) window.removeEventListener('resize', resizeFunc);
      if (scrollTopTimer) clearInterval(scrollTopTimer);
    });

    const initVisible = target => {
      visible.value = (target?.scrollTop ?? 0) >= backTopVisibilityHeight.value;
      if (!visible.value) return;
      nextTick(() => {
        handleVisible(target);
      });
    };

    const handleScroll = e => {
      let oldVisible = visible.value;
      if ((visible.value = e.target.scrollTop >= backTopVisibilityHeight.value) === oldVisible) return;
      nextTick(() => {
        if (visible.value) handleVisible(e.target);
        else handleInvisible();
      });
    };

    const handleVisible = target => {
      let el = target.getElementsByClassName('n-back-top')[0];

      setCoordinate(el);

      resizeFunc = () => setCoordinate(el);
      window.addEventListener('resize', resizeFunc);

      let isDragging = false;
      let isDragged = false;

      el.onmousedown = e => {
        if (e.button !== 0) return;

        document.body.classList.add('select-none');

        let offsetX = el.offsetWidth - (e.pageX - el.offsetLeft);
        let offsetY = el.offsetHeight - (e.pageY - el.offsetTop);

        let coordinate = undefined;

        let documentMousemoveFunc = e => {
          isDragging = true;
          coordinate = calcCoordinate({
            x: document.documentElement.offsetWidth - e.pageX - offsetX,
            y: document.documentElement.offsetHeight - e.pageY - offsetY,
          });
          el.style.right = `${coordinate.x}px`;
          el.style.bottom = `${coordinate.y}px`;
        };
        document.addEventListener('mousemove', documentMousemoveFunc);

        let documentMouseupFunc = () => {
          if (isDragging) setBackTopCoordinate(coordinate);
          isDragged = isDragging;
          isDragging = false;
          document.removeEventListener('mousemove', documentMousemoveFunc);
          document.removeEventListener('mouseup', documentMouseupFunc);
          document.body.classList.remove('select-none');
        };
        document.addEventListener('mouseup', documentMouseupFunc);

        el.onclick = () => {
          if (!isDragging && !isDragged) doBackTop(target);
        };
      };
    };

    const handleInvisible = () => {
      window.removeEventListener('resize', resizeFunc);
      resizeFunc = undefined;
    };

    const calcCoordinate = original => {
      return {
        x: Math.min(Math.max(original.x, 5), document.documentElement.clientWidth - 35),
        y: Math.min(Math.max(original.y, 5), document.documentElement.clientHeight - 35),
      };
    };

    const setCoordinate = el => {
      if (!visible.value) return;
      const coordinate = calcCoordinate(backTopCoordinate.value);
      el.style.right = `${coordinate.x}px`;
      el.style.bottom = `${coordinate.y}px`;
    };

    const doBackTop = target => {
      if (scrollTopTimer) return;
      scrollTopTimer = setInterval(() => {
        target.scrollTop -= 100;
        if (target.scrollTop <= 0) {
          target.scrollTop = 0;
          clearInterval(scrollTopTimer);
          scrollTopTimer = undefined;
        }
      }, 10);
    };

    return { visible };
  },
});
</script>

<style lang="less">
.n-back-top {
  position: fixed;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  z-index: 800;
  color: #515a6e;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & > .n-back-top-content {
    overflow: hidden;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 20px;

    &:hover {
      background-color: #515a6e;
    }

    & > .n-back-top-icon {
      width: 16px;
      height: 17px;
      margin: 7px auto;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC);
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: initial;
      background-origin: initial;
      background-clip: initial;
      background-color: initial;
    }
  }
}
</style>
