import { toArray } from 'lodash-es';

export const findNodeUpward = (node, className) => {
  if (toArray(node?.classList)?.includes(className)) return node;
  let target = node?.parentNode;
  while (target && !toArray(target.classList)?.includes(className)) {
    target = target?.parentNode;
  }
  return target;
};
