// import { NMessage } from '@/components';
import { mySentry } from '@/monitor';

export function isJsError(err) {
  return err instanceof Error;
}

// unexpected
export function jsErrorHandler(err, vm, info) {
  if (!isJsError(err)) return true;

  console.error(err, vm, info);
  mySentry?.captureException(err);

  // Internal Error, only show in development. WON'T show in production
  // import.meta.env.MODE === 'local_dev' && NMessage.error({ content: err.message } ?? { content: 'Unknown Error' });
  return false;
}
