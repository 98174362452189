import { storage } from './storage';
import { setAxiosConfig } from './axios';
import {ADJUST_KEY} from "@/constant";

// web service set token in cookie , front setToken is unnecessary
export function setToken(token) {
  setAxiosConfig({ headers: { token } });
  storage.set('token', token);
}

export function removeToken() {
  setAxiosConfig({ headers: { token: '' } });
  storage.remove('token');
  storage.remove(ADJUST_KEY);
}
