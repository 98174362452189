<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <NRadioGroup :value="value" class="n-boolean" v-bind="componentAttrs" v-on="componentListener">
      <NRadioButton :value="true">{{ nTrueLabel }}</NRadioButton>
      <NRadioButton :value="false">{{ nFalseLabel }}</NRadioButton>
    </NRadioGroup>
  </NFormItem>
</template>

<script>
import { useLanguage } from '@/hooks';
import { computed, defineComponent } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';

export default defineComponent({
  name: 'NBoolean',

  inheritAttrs: false,

  props: {
    name: [String, Array],
    label: String,
    customLabel: String,
    trueLabel: String,
    falseLabel: String,
    value: { type: Boolean, default: false },
  },

  setup(props, { attrs, emit }) {
    const { tl } = useLanguage();

    const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);

    const componentAttrs = computed(() => ({
      ...componentBaseAttrs.value,
      ...attrs,
    }));

    const componentListener = computed(() => ({
      onChange: e => emit('update:value', e.target.value),
    }));

    const nTrueLabel = computed(() => (props.trueLabel ? tl(props.trueLabel) : tl('yes')));

    const nFalseLabel = computed(() => (props.falseLabel ? tl(props.falseLabel) : tl('no')));

    return { formItemBaseAttrs, componentAttrs, componentListener, nTrueLabel, nFalseLabel };
  },
});
</script>

<style lang="less">
.n-boolean {
  width: 100%;

  & > label.ant-radio-button-wrapper {
    width: 50%;
    padding: 0;
    text-align: center;

    &.ant-radio-button-wrapper-checked {
      color: white;
      background: var(--ant-primary-color);

      &.ant-radio-button-wrapper-disabled {
        background: #c5c8ce;
      }
    }
  }
}
</style>
