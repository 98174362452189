export default {
  /* A */
  add: '添加',
  all: '全部',
  admin: '管理画面',
  apply: '应用',
  agent: '代理',
  agents: '代理',
  appKey: '用户名',
  avatar: '头像',
  amount: '金额',
  action: '操作',
  agentId: '代理',
  approve: '通过',
  activate: '激活',
  autoSync: '自动同步',
  approval: '审批',
  approved: '已通过',
  allCache: '所有缓存',
  activated: '已激活',
  accountId: '交易系统用户ID',
  appSecret: '密码',
  agentName: '代理名称',
  amountInfo: '金额信息',
  addressInfo: '地址信息',
  autoChannel: '自动',
  addressDetail: '地址',
  accountAmount: '账户金额',
  approvalStatus: '审核状态',
  accountCurrency: '账户货币',
  alreadyLoggedIn: '已登录',
  approvalProcess: '审批流程',
  accountActivate: '账户激活',
  accountActivated: '是否已激活',
  appliedDate: '申请时间',
  approvalComment: '审核备注',
  approveAndWithdraw: '通过并提现',
  alreadyHaveAccount: '已经有帐号?',
  // accountRiskLevel: '账户风险等级',
  allSelectedCount: '全部（{count}）',
  actualAccountAmount: '实际到账金额',
  actualDepositAmount: '实际充值金额',
  accountDepositAmount: '交易账户充值金额',
  accountWithdrawalAmount: '提现金额',
  apiResultMsg: '支付信息',
  android: '安卓',
  androidLink: '安卓链接',
  accountInfo: '账户信息',
  activateRealAccount: '激活真实交易账户',

  /* B */
  back: '返回',
  bank: '银行',
  branch: '分支',
  backTo: '返回登录',
  bankNo: '银行编号',
  backTop: '回到顶部',
  balance: '余额',
  bankCard: '银行卡',
  bordered: '边框',
  basicInfo: '基本信息',
  batchSize: '数量',
  backToLogin: '返回',
  backToPrevious: '返回上级',
  backToPreviousStep: '上一步',
  backToRegister: '返回',
  bankBranch: '开户支行名称',
  bankAccount: '银行账户',
  baseCurrency: '基础货币',
  branchAddress: '分行地址',
  bankAccountName: '银行账户名',
  bankNameOrNetwork: '银行名称 / 充值网络',
  bankCardInfo: '银行卡信息',
  bankCardId: '银行卡ID',

  /* C */
  csv: 'CSV',
  copy: '复制',
  check: '检查',
  close: '关闭',
  cancel: '取消',
  create: '创建',
  confirm: '确认',
  checked: '已选中',
  comment: '备注',
  countryOrRegion: '国家 / 地区',
  created: '已创建',
  channel: '线路',
  closeAll: '全部关闭',
  customer: '客户',
  copyLink: '复制链接',
  countries: '国家',
  createdBy: '创建者',
  childType: '子类型',
  cancelDate: '注销日期',
  cardholder: '持卡人',
  customerId: '客户ID',
  configName: '变量名',
  configDesc: '变量描述',
  cardDetail: '银行卡',
  conversion: '转换',
  changeAgent: '更换代理',
  cashflowKid: '交易系统流水ID',
  channelName: '渠道名称',
  configValue: '变量值',
  createdDate: '创建时间',
  currencyType: '是否本外币账户',
  channelError: '线路异常',
  customerInfo: '客户信息',
  customerData: '客户资料',
  currentCache: '当前缓存',
  closeAllTabs: '关闭所有标签页',
  createdBefore: '之前创建',
  cryptoNetwork: '充值网络',
  clearAllCache: '清空所有缓存',
  contactContent: '内容',
  forgotPassword: '忘记密码',
  changePassword: '修改密码',
  customerVerify: '客户认证',
  closeOtherTabs: '关闭其他标签页',
  closeRightTabs: '关闭右侧标签页',
  createAccounts: '创建账户',
  certificateNo: '证件号码',
  confirmationMin: '最小确认次数',
  confirmPassword: '确认密码',
  closeCurrentTab: '关闭当前标签页',
  chineseSimplified: '中文（简体）',
  changePwdSucceess: '密码修改成功',
  customerFeeAmount: '顾客手续费',
  customerCurrencies: '客户货币',
  customerDepositFlow: '充值流水',
  customerVerifyFlowId: '当前提审ID',
  customerIdentificationId: '身份ID',
  cancelTransaction: '取消转账',
  cancelTransactionPopupTitle: '此操作无法撤消，确定要放弃此笔转账?',
  captchaImageErrorMsg: '发生错误, 点我刷新',
  captchaText: '验证码',
  completed: '完成',
  confirmDetail: '确认明细',
  confirmProof: '确认凭证',
  confirmComplete: '确认完成',
  confirmRecvPopupTitle: '此操作无法撤消，请确认您已经收到了这笔转账',
  confirmTransaction: '确认转账',
  confirmTransactionPopupTitle: '此操作无法撤消，确定已经完成了此笔转账?',
  currentChannelNotAvailableAndSetAsAuto: '当前线路不可用, 已切换为自动',

  /*D*/
  dob: '出生日期',
  desc: '简介',
  detail: '详情',
  details: '详情',
  deposit: '充值',
  depositHistory: '充值履历',
  download: '下载',
  demoSystem: '虚拟系统',
  demoAppKey: '虚拟用户名',
  description: '描述',
  demoAppSecret: '虚拟密码',
  depositAmount: '充值金额',
  depositDetail: '充值 详情',
  depositVerify: '充值审核',
  depositRemark: '转账留言',
  depositFlowId: '充值ID',
  depositCurrency: '存入货币',
  depositApproval: '充值审核',
  depositInfo: '充值信息',

  /* E */
  en: 'English',
  env: '环境',
  edit: '编辑',
  enum: '枚举',
  empty: '空',
  enable: '启用',
  enabled: '已启用',
  existing: '存在',
  equalZero: '等于零',
  expandAll: '全部展开',
  emailDetail: '邮件',
  engFullName: '英文全称',
  engShortName: '英文简称',
  emailVerify: '邮箱认证',
  emailVerified: '邮箱认证',
  encryptedId: '上游商户单号',
  enableVerification: '启用认证',
  exclusiveAccountId: '专属代理账户ID',
  expectedWithdrawalAmount: '预期到账金额',

  /* F */
  fee: '手续费',
  force: '强制',
  fxRate: '汇率详细',
  failure: '失败',
  feeInfo: '手续费信息',
  flowInfo: '申请信息',
  fullName: '姓名',
  forceCancel: '强制取消',
  familyNameEn: '姓氏',
  forceConfirm: '强制收款',
  frozenAmount: '冻结金额',
  fxCurrencyPair: '外汇货币对',
  fxRateTemplate: 'FX汇率模板',
  fatcaSignedDate: 'FATCA签名日期',
  fxRateTemplateId: 'FX汇率模板ID',
  forgotNoticeTitle1: '密码找回链接已发送到邮箱',
  forgotNoticeTitle2: '请登录邮箱找回！',
  forgotNoticeTitle3: '已登录邮箱并点击连接?',

  /* G */
  goRegister: '立即创建',
  gotoVerify: '去认证',
  goDeposit: '去充值',
  goLogin: '请到应用平台去登录',
  giinTaxNo: 'GIIN税号',
  guestForbidden: '禁止访客登录',
  givenNameEn: '名字',
  greaterThan: '大于{number}',
  greaterThanZero: '大于零',
  gatewayLoginUsername: '网关登录用户名',
  gatewayFeeAmount: '网关估算手续费',

  /* H */
  hide: '隐藏',
  help: '帮助',
  hours: '小时',
  h5Link: 'H5 Trader 链接',
  hasRemainingAmount: '有剩余金额',

  /* I */
  id: 'ID',
  it: '机构',
  itId: '机构',
  items: '条',
  itName: '机构名称',
  inviter: '推荐人',
  identity: '身份',
  itReceive: '充值账号',
  invalidUrl: '网址无效',
  inputAmount: '输入金额',
  inputPaymentInfo: '收付款信息',
  inputPaymentMethod: '金额试算',
  itReceiveAccount: '机构收款账户',
  itReceiveId: '机构收款账号ID',
  internetUser: '互联网用户',
  institutionId: '机构ID',
  identifyDetail: '身份认证',
  identification: '身份',
  identityVerify: '身份认证',
  itReceiveMethod: '机构收款方式',
  itReceiveNumber: '机构收款账号',
  identificationId: '身份ID',
  identificationType: '证件类型',
  identificationInfo: '身份信息',
  identificationNumber: '证件号码',
  identificationStep1Title: '个人信息',
  identificationStep1Name: '请输入您的身份证上显示的姓名（拼音）',
  identificationStep2OnlyTaxInCountry: '我只在上述国家纳税',
  identificationStep2SameBirthNationality: '我的出生地和国籍与上述国家相同',
  identificationStep2NonUSResident: '我不是美国公民，也不是美国纳税居民',
  identificationStep4Title: '投资知识',
  identificationStep4Desc:
    '我们需要评估你对金融产品的了解程度。如果你提供的答案不正确，可能会导致你的评估不及格。请仔细考虑你的答案。',
  identificationStep4KlMaxInvestment: '您已注资$3,000。杠杆率为1:100。（使用杠杆后）您可以交易的最大头寸价值是多少 ?',
  identificationStep4KlTrigger: '如果触发止损指令',
  identificationStep4KlAutoClose: '我的未清仓可能会在以下情况下自动关闭（例如追加保证金）',
  identificationStep5Title1: '适当性评估',
  identificationStep5Title2: '交易经验',
  identificationStep5Desc:
    '我们需要评估你对金融产品的了解程度。如果你提供的答案不正确，可能会导致你的评估不及格。请仔细考虑你的答案。',
  identificationStep5AxTradingCount: '您在过去的一年有哪些交易经验?（未获得专业建议）股票、债权、商品',
  identificationStep5AxOtcCount: '场外交易衍生工具（杠杆CFDs、外汇、差价、点差交易）',
  identificationStep5AxOtcLeverage: '平均来说，用于交易上述OTC场外衍生品的杠杆是什么?',
  identificationStep6Title: '专业经验',
  identificationStep6ExpHasFinanceEducation:
    '您在过去3年中是否具有金融服务行业的在职教育、专业资质和/或工作经验（担任需要懂杠杆交易的工作）?',
  identificationStep6ExpFinanceEducation: '请提供更多关于您的教育背景、专业资质和/或金融服务行业的工作经验信息',
  identificationStep7Title: '就业信息',
  identificationStep7Occupation: '您的就业情况如何？',
  identificationStep7Industry: '您从事什么行业？',
  identificationStep8Title: '财务资料',
  identificationStep8finAnnualIncome: '年收入',
  identificationStep8finSavingsInvestment: '储蓄和投资（不包括物业）',
  identificationStep8finIncomeSource: '交易资金的主要来源',
  identificationStep8maxInvestmentLoss: '每年您可承担损失风险的用于交易大致金额',
  identificationStep9Title: '交易概况',
  identificationStep9TradingFrequency: '您预计多久交易一次？',
  identificationStep9DepositMethod: '您将如何为账户注资？',
  identificationStep9AcknowledgeTradingRisk:
    '我了解并确认，我在G.H.F交易的性质是买卖各种标的资产的差价合约产品。我明白查价格约并非适合所有人，只有像我这样具有高风险承受能力的人才可进行交易。',
  identificationStep10Title: '条款和条件',
  identificationStep10ConsentAgreement: '我已详细阅读并接受G.H.F的使用协议',
  identificationStep10Agreement1: '订单执行政策',
  identificationStep10Agreement2: '风险披露公告',
  identificationStep10Agreement3: '隐私政策',
  identificationStep10Agreement4: '关键信息文件',
  isExclusive: '专属代理',
  ios: 'IOS',
  iosLink: 'IOS 链接',
  ifsc: 'IFSC钥匙',

  /* J */
  jurisdiction: '管辖区',

  /* K */
  kv: '交易系统记录版本',
  key: '键',

  /* L */
  list: '列表',
  lock: '锁定',
  line1: '地址1',
  line2: '地址2',
  login: '登录',
  logout: '退出',
  locked: '已锁定',
  loginId: '登录ID',
  language: '语言',
  lockTime: '锁定时间',
  liveSystem: '实时系统',
  loginQuick: '快捷登录',
  loginUsername: '请输入您的电子邮箱',
  loginPassword: '请输入您的登录密码',
  lastModifiedBy: '最后修改者',
  lastModifiedDate: '更新时间',
  lastAutoSyncTime: '自动同步时间',
  lookBackInterval: '回溯时长',
  localDepositAmount: '充值金额（本币）',

  /* M */
  mark: '标记',
  more: '更多',
  mobile: '手机',
  minute: '分钟',
  minutes: '分钟',
  merchantName: '商户名',
  middleNameEn: '中间名',
  mobileDetail: '手机',
  manualRefresh: '手动刷新',
  myReceiveAccount: '我的收款账户',
  myReceiveBank: '我的收款银行',
  myReceiveNumber: '我的收款账号',
  maxAutoConfirmAmount: '最大自动确认金额',
  minTransactionAmount: '最小单笔交易限额',
  maxTransactionAmount: '最大单笔交易限额',
  macX86Desktop: 'Mac(x86) 桌面应用',
  macX86DesktopLink: 'Mac(x86) 桌面应用链接',
  macArmDesktop: 'Mac(Arm) 桌面应用',
  macArmDesktopLink: 'Mac(Arm) 桌面应用链接',

  /* N */
  no: '否',
  name: '名字',
  number: '账号',
  notEnabled: '未启用',
  notifyLang: '通知语言',
  notNecessary: '非必要',
  networkUrls: '网络地址',
  newPassword: '新密码',
  neverExpire: '长期有效',
  noDataFound: '暂无数据',
  notifyEmail: '通知邮箱',
  nextStep: '下一步',
  noAccount: '没有账号?',
  noTransactionHint: '暂无转账记录, 请耐心等待',
  noAvailableChannel: '暂无可用线路, 请稍后重试',

  /* O */
  on: '开启',
  or: '或者',
  off: '关闭',
  operator: '操作员',
  operation: '操作',
  oldPassword: '旧密码',
  openRealAccount: '开户真实交易账户',
  openVirtualAccount: '开户虚拟交易账户并充值',

  /* P */
  px: '像素',
  proof: '凭证',
  people: '人',
  profile: '用户',
  process: '审核',
  payType: '支付类型',
  pageSize: '每页条数',
  province: '省份',
  password: '密码',
  postCode: '邮编',
  processed: '已处理',
  processes: '处理',
  profession: '职业',
  plzRelogin: '请重新登录',
  phoneDetail: '固定电话',
  parentAgent: '父代理',
  preconditions: '前提条件',
  paymentChannel: '支付渠道',
  paymentGateway: '网关',
  paymentRouting: '支付渠道路由',
  processComplete: '完成处理',
  paymentRoutingId: '支付渠道路由ID',
  passwordResetNoticeTitle: '修改密码成功',
  passwordResetFailTitle: '跳转到',
  paymentCurrency: '付款货币',
  pleaseChooseItReceiveAccount: '收款方式',
  pleaseChoosePayment: '付款方式',
  pleaseSignIn: '请输入用户名和密码',
  pleaseSignUp: '请输入要注册的用户名和密码',
  pleaseVerifySoon: '请尽快完成认证',
  previousStep: '上一步',
  planDepositAmount: '预定充值金额',
  paymentFeeModel: '上游手续费模式',
  paymentFeeValue: '上游手续费设定值',
  paymentFeeCurrency: '上游手续费货币',
  plzReloginWithNewPwd: '请使用新密码重新登录',
  paymentFeeInfo: '上游手续费信息',
  paymentOrderStatus: '订单状态',
  providerType: '注册类型',
  pleaseSelectApprovalStatus: '请选择审核状态',
  paymentFeeAmount: '支付端估算手续费',
  processing: '处理中',
  pixType: 'PIX 类型',
  pixKey: 'PIX 密钥',
  payPurpose: '支付用途',

  /* Q */
  qrCode: '二维码',
  quoteCurrency: '报价货币',

  /* R */
  rate: '汇率',
  role: '角色',
  roles: '角色',
  reset: '重置',
  region: '地区',
  result: '结果',
  roleId: '角色',
  remove: '删除',
  reject: '驳回',
  reason: '原因',
  receive: '收款',
  refresh: '刷新',
  retract: '撤回',
  regDate: '注册日期',
  register: '注册',
  receiver: '接收者',
  resource: '资源',
  retryMail: '重新发送',
  receiverId: '接收者ID',
  refreshAll: '刷新全部',
  regCustomer: '账户注册',
  rateDetails: '汇率详细',
  receiverName: '收款人姓名',
  receiverRole: '接收者角色',
  receiveMethod: '收款方式',
  receiveNumber: '账号',
  resetPassword: '重置密码',
  receiverNumber: '收款人账号',
  receiverNumberCpf: '收款人账号',
  receiverNumberPhone: '电话号码',
  receiverNumberEmail: '电子邮件',
  receiverNumberEvp: '虚拟支付地址',
  receiverAccount: '收款账户',
  receiveCurrency: '收款货币',
  receiverNetwork: '充值网络',
  remainingAmount: '剩余金额',
  recoverPassword: '密码找回',
  receivingCurrency: '收款货币',
  registerNoAgentAlert: '请联系代理商提供注册链接',
  registerNoticeTitle: '请到邮箱激活账号后',
  registerVerifyTitle1: '注册成功!',
  registerVerifyTitle2: '请重新',
  resendActiveMail: '发送邮箱认证邮件',
  recoverPasswordEnter: '请输入要找回密码的邮箱',
  restoreDefaultSettings: '恢复默认设置',
  receiverNumberOrAddress: '收款人账号 / 地址',
  registerOtherWay: '或使用以下方式注册',
  registerAgreementTitle1: '我已阅读并同意',
  registerAgreementTitle2: '用户协议',
  registerPleaseAgree: '请同意用户协议',
  receiverExtConfig: '收款人信息扩展配置',
  relatedId: '关联单号',

  /* S */
  sex: '性别',
  show: '显示',
  step: '步骤',
  sync: '同步',
  send: '发送',
  synced: '已同步',
  source: '来源',
  status: '状态',
  search: '查询',
  sender: '发起者',
  social: '社交',
  signUp: '注册',
  safety: '安全',
  stopHinting: '不再提示',
  submit: '提交',
  setting: '设置',
  success: '成功',
  settings: '设置',
  senderId: '发起者ID',
  secretKey: '密钥',
  selectAll: '全部',
  superUser: '超级用户',
  shortName: '简称',
  searchKey: '关键字',
  shareCode: '分享码',
  subAgents: '子代理',
  syncAgent: '同步代理',
  senderRole: '发起者角色',
  signedDate: '签约日期',
  socialDetail: '社交',
  signedStatus: '签约状态',
  successMin: '最小成功个数',
  systemConfig: '系统变量',
  selectedCount: '已选择（{count}）',
  systemFeeModel: '系统手续费模式',
  systemFeeValue: '系统手续费设定值',
  syncInstitution: '同步机构',
  settlementCurrency: '结算货币',
  statusInfo: '状态信息',
  sourceAgentId: '源代理',
  senderExtConfig: '付款人信息扩展配置',

  /* T */
  tw: '中文（繁體）',
  tabs: '标签页',
  type: '类型',
  table: '列表',
  token: '令牌',
  total: '合计',
  target: '目标',
  taxNo: '税号',
  txHash: '交易 Hash',
  toRefId: '客户收款账号ID',
  tabLimit: '标签页限制',
  transfer: '转账',
  tinTaxNo: '税务识别号',
  toRefBank: '客户收款银行',
  testNetwork: '测试网络',
  tax: '税务识别',
  taxDetail: '税务识别',
  taxFormType: '税务表格类型',
  taxFormDate: '税务表格日期',
  taxNoExpireDate: '税务识别号过期日期',
  transaction: '交易',
  transactionProof: '转账凭证',
  tronScanUrl: 'Tron Scan 网址',
  toRefNumber: '客户收款账号',
  timedRefresh: '定时刷新',
  tokenExpired: '登录过期',
  targetAgentId: '目标代理',
  transferAmount: '转账金额',
  transferFromIt: '从机构转账',
  tradingPassword: '交易密码',
  transferConfirm: '收款',
  transferDetails: '转账明细',
  transactionModel: '支付模式',
  transactedAmount: '已提现金额',
  transactionCategory: '付款方式',
  transferNotifyTimeLimit: '转账通知时限',

  /* U */
  userId: '用户ID',
  upload: '上传',
  unitCny: '元',
  username: '用户名',
  unlocked: '未锁定',
  unverify: '未认证',
  unchecked: '未选中',
  unprocess: '未认证',
  uncreated: '未创建',
  unapprove: '未通过',
  usCitizen: '是否美国公民',
  usResident: '是否美国居民',
  usTaxPayers: '是否美国纳税人',
  usdFeeAmount: '系统手续费',
  usBeneficiary: '是否美国受益人',
  usCompany: '是否美国公司',
  unprocessed: '未处理',
  unactivated: '未激活',
  uploadImage: '上传图片',
  uploadProof: '上传凭证',
  uploadLimitation: '{types} 最大{maxSize}M',
  upstreamPaymentChannel: '上游渠道',
  unsupportedBrowserTitle: '不支持此浏览器',
  unsupportedBrowserContent1: '请升级到最新浏览器.',
  unsupportedBrowserContent2: '（建议: Chrome 90+, Safari 14+, Firefox 80+）',

  /* V */
  value: '值',
  verify: '认证',
  verify2: '审批',
  version: '版本',
  validUrl: '网址有效',
  verified: '已认证',
  verifyId: '认证ID',
  verifyInfo: '审核信息',
  verifyName: '审核姓名',
  verifyApproval: '审核认证',
  visibilityHeight: '可见高度',

  /* W */
  welcome: '欢迎您',
  withdraw: '提现',
  withdrawHistory: '提现履历',
  workflow: '工作流程',
  withdrawal: '提现',
  waiverType: '豁免类型',
  withdrawalVerify: '提现审批',
  withdrawalAmount: '提现金额',
  withdrawalAmountLocal: '提现金额（本币）',
  withdrawApproval: '提现审核',
  withdrawalDetail: '提现 详情',
  withdrawalFlowId: '提现ID',
  withdrawalInfo: '提现信息',
  windowsDesktop: 'Windows 桌面应用',
  windowsDesktopLink: 'Windows 桌面应用链接',
  web: 'Web Trader',
  webLink: 'Web Trader 链接',

  /* X */

  /* Y */
  yes: '是',
  youWillTransferForAboveAccountsSeparately: '您将分别为以上账户转账',

  /* Z */
  cn: '中文（简体）',

  /* sender info */
  receiverInfo: '收款人信息',
  surName: '姓',
  givName: '名',
  middleName: '中间名',
  otherName: '其他语言名称',
  address: '地址',
  area: '手机区号',
  phone: '电话号码',
  nationality: '国籍',
  idType: '证件类型',
  idNumber: '证件号码',
  locationId: '位置ID',
  bankId: '银行网点编号',
  bankName: '银行网点名称',
  bankBranchName: '分行名',
  bankBranchCode: '分行代码',
  bankBranchId: '分行ID',
  accountNo: '银行卡号',
  occupation: '职业',
  bankMobile: '银行预留手机号',
  issueDate: '证件签发日期',
  expireDate: '证件到期日期',
  licIssuAutho: '证件发证机关',
  remark: '备注',
  accountType: '账户类型',
  city: '城市',
  states: '州',
  zipCode: '邮编',
  country: '国家',
  email: '邮箱',
  accountNumber: '账户编号',
  phoneCountry: '手机号所属国家',
  accountCity: '账号所属城市',
  accountCountry: '账号所属国家',
  currency: '币种',
  userName: '用户名',
  birthday: '出生日期',
  postalCode: '邮编',
  provinceState: '州/省份',
  cardNumber: '卡编号',
  nativeFirstName: '原姓',
  nativeLastName: '原名',
  remitterType: '支付类型',
  ibanNo: '国际银行帐户号码',
  accountAddress: '账户地址',
  routingType: '路由类型',
  senderBusiness: '付款公司信息',
  registeredName: '公司注册名称',
  tradingName: '公司名称',
  msisdn: '公司联系电话',
  registrationNumber: '公司注册编码',
  dateOfIncorporation: '公司注册日期',
  purposeOfRemittance: '汇款目的',
  documentReferenceNumber: '文件参考编号',
  taxId: '税号',
  sourceOfFund: '资金来源',
  countryCode: '国家代码',
  transactionType: '交易模式',
  channelCode: '渠道编号',
  beneficiaryRelationShip: '汇款关系',
  gender: '性别',
  otherDownloads: '其他下载方式',
  verifyNotice1: '邮箱验证成功！',
  verifyNotice2:
    '感谢您注册我们的产品---GHF。您现在可以使用该帐号去登录界面登录。如果您有任何疑问，请随时联系我们的客户服务团队：https://wa.me/13234169593',
  verifyNotice3: '祝您用好 ！',
  depositNotice1: '提示:',
  depositNotice2: '至少充值',
  depositNotice3: '300雷亚尔',
  depositNotice4: '才能进行交易,其他小金额是为了补充资金',
  depositNotice5: ' WhatsApp:+5519995357056',
  liveRoom: '看直播学习如何赚钱',
};
