export default [
  {
    name: 'menu.role.title',
    path: '/role',
    icon: 'fas fa-user-cog',
    children: [
      {
        name: 'menu.role.user',
        path: '/user',
        meta: {
          keepAlive: true,
          cacheName: 'RoleUser',
          hidden: ['ROLE_AGENT'],
        },
        component: () => import('../views/index/role/user/User.vue'),
      },
      {
        name: 'menu.role.role',
        path: '/role',
        meta: {
          keepAlive: true,
          cacheName: 'RoleRole',
          hidden: ['ROLE_IT', 'ROLE_AGENT'],
        },
        component: () => import('../views/index/role/role/Role.vue'),
      },
      {
        name: 'menu.role.it',
        path: '/it',
        meta: {
          keepAlive: true,
          cacheName: 'RoleIt',
          hidden: ['ROLE_AGENT'],
        },
        component: () => import('../views/index/role/it/It.vue'),
      },
      {
        name: 'menu.role.agent',
        path: '/agent',
        meta: {
          keepAlive: true,
          cacheName: 'RoleAgent',
        },
        component: () => import('../views/index/role/agent/Agent.vue'),
      },
    ],
  },
];
