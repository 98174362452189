export default {
  success: {
    successfully: '{key}成功',
    copyToClipboard: '已複製到剪切板',
    approved: '{key}已獲批准',
    processed: '{key}處理已完成',
    uploaded: '{key}上傳已完成',
    register: '註冊成功, 請登陸',
  },
  warning: {
    rejected: '{key}已被駁回',
    removeFxRateTemplate: '注意：同時會從客戶、機構和代理中將使用「{name}」的模板移除。',
    transferFromIt: '你確定要將全部剩餘金額 [ 預估：{amount} ] 從 {from} 轉賬到 {to} 嗎？',
    clearBrowserCache: ' 此操作會清除瀏覽器緩存，並恢復為以下默認設置：',
    forcedRefresh: '*注意：當前頁面將會強制刷新！',
    resetPassword1: '即將發送郵件到',
    resetPassword2: '{email}',
    resetPassword3: '幫助您找回賬戶',
  },
  error: {
    required: '必填項',
    duplicatedBy: '不能與{target}相同',
    symbolExisted: '當前幣對已經存在',
    failed: '{key}失敗',
    invalidFormat: '格式無效',
    serverUnavailable: '伺服器當前不可用，請稍後再試',
    uploadFileType: '您只能上傳{types}文件',
    uploadFileSize: '容量不能超過{maxSize}MB',
    uploadFailed: '上傳失敗',
    underLength: '長度不足 [{min}]',
    overLength: '長度超出範圍 [{max}]',
    decimalOverLength: '長度超出範圍 [{precision}, {scale}]',
    underflow: '低於最小值 [{min}]',
    overflow: '超過最大值 [{max}]',
    invalidMailFormat: '無效的郵件格式',
    notOnlyNumber: '不能只有數字',
    notOnlyLetter: '不能只有字母',
    atLeastOneLowercase: '至少有一個小寫字母',
    atLeastOneUppercase: '至少有一個大寫字母',
    atLeastOneNumber: '至少有一個數字',
    onlyAlphanumerical: '必須是數字與字母組合',
    cannotBeSuperuser: '不能是超級用戶',
    mustBeSuperuser: '必須是超級用戶',
    mustBeGreaterThan: '必須大於{number}',
    ranges: '取值範圍:{min}~{max}',
    lengthLimit: '長度應為 {length}',
    notSame: '兩次密碼輸入不匹配',
    notBlank: '不能是空白字符',
    containsSpace: '不能含有空格',
  },
  confirm: {
    title: '你確定要{action}嗎？',
    resourceNotFoundTitle: '資源過時',
    resourceNotFoundContent: '你想要刷新頁面, 以更新最新的資源嗎?',
  },
  modal: {
    add: '添加 {name}',
    edit: '編輯 {name}',
    detail: '{name} 詳情',
  },
  pagination: {
    showTotal: `{start}-{end} 共 {total} 條數據`,
  },
  tooltip: {
    settings: '此設置保存在瀏覽器緩存中',
  },
  message: {
    itReceiveCheck: '機構收款賬號驗證通過。',
    needToCreateUsdReceiveAccount: '機構收款賬號驗證失敗，需要創建『美元』收款賬號。',
  },
};
