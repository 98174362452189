import { h } from 'vue';
import { merge } from 'lodash-es';
import { useLanguage } from '@/hooks';
import { Modal } from 'ant-design-vue/es';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

export function showConfirm(options = {}) {
  const { tl } = useLanguage();
  const mergedOptions = merge(
    {
      icon: h(ExclamationCircleOutlined),
      okText: tl('confirm'),
      cancelText: tl('cancel'),
    },
    options,
  );
  return Modal.confirm(mergedOptions);
}



export function showInfo(options = {}) {
    const mergedOptions = merge(
        {
            footer: null,
            centered: true,
            closable: true,
            width: 620,
            icon: null,
            destroyOnClose: true,
        },
        options,
    );
    return Modal.info(mergedOptions);
}