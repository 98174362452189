export default {
  rolePos: {
    ROLE_IT: 'Institution',
    ROLE_AGENT: 'Agent',
  },
  booleanOptions: {
    YES: 'Yes',
    NO: 'No',
  },
};
