<template>
  <NInput class="n-input-decimal" :value-pattern="mInputableRegex" v-bind="$attrs" />
</template>

<script setup>
import { computed } from 'vue';
import { inputableDecimalRegex } from '@/constant';
import { createDecimalInputRegexWithScale } from '@/utils';

const props = defineProps({
  maxInputScale: Number,
});

const mInputableRegex = computed(() => {
  if (props.maxInputScale > 0) return createDecimalInputRegexWithScale(props.maxInputScale);
  else return inputableDecimalRegex;
});
</script>

<script>
export default {
  name: 'NInputDecimal',
};
</script>
