import { Modal } from 'ant-design-vue/es';
import { NoAuthMessage } from '@/models';
import { useRouter, useLanguage } from '@/hooks';

export function noAuthMessageHandler(err, vm, info) {
  if (!(err instanceof NoAuthMessage)) return true;

  const { tl } = useLanguage();
  const { routerReplace } = useRouter();

  Modal.warning({
    title: tl('tokenExpired'),
    content: tl('plzRelogin'),
    keyboard: false,
    onOk: () => {
      routerReplace('/login');
    },
  });

  return false;
}
