<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <AInputPassword class="n-input-password" v-bind="componentAttrs" @press-enter="onPressEnter" />
  </NFormItem>
</template>

<script setup>
import { computed, useAttrs } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';
import { InputPassword as AInputPassword } from 'ant-design-vue/es';

const attrs = useAttrs();

const emits = defineEmits(['pressEnter']);

const props = defineProps({
  label: String,
  name: [String, Array],
  customLabel: String,
});

const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);

const componentAttrs = computed(() => ({
  ...componentBaseAttrs.value,
  ...attrs,
}));

const onPressEnter = v => emits('pressEnter', v);
</script>

<script>
export default {
  name: 'NInputSafePassword',
  inheritAttrs: false,
};
</script>

<style lang="less">
.n-input-password {
  .ant-input-group-addon {
    width: 32px;
    padding: 0;
    background-color: #fafafa;

    & > button.ant-btn {
      width: 32px;
      height: 100%;
      border: 0;
      background-color: #fafafa;

      span.anticon {
        font-size: 13px;
        margin-left: 0;
      }

      &[disabled] {
        color: @disabled-color;
        background-color: @disabled-bg;
      }
    }
  }
}

.ant-form-item-has-error .n-input-password .ant-input-disabled {
  color: @error-color !important;
  border-color: @error-color !important;
}
</style>
