<template>
  <div v-if="valid">
    <CheckOutlined v-show="value" class="n-success !align-middle" />
    <CloseOutlined v-show="!value" class="n-error !align-middle" />
  </div>
  <div v-else>-</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  value: Boolean,
});

const valid = computed(() => typeof props.value === 'boolean');
</script>
