<template>
  <AButton class="n-button" :loading="computedLoading" @click="onClick">
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </AButton>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Button as AButton } from 'ant-design-vue/es';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  noLoading: {
    type: Boolean,
    default: false,
  },
  onClick: {
    type: Function,
    default: () => {},
  },
});

const innerLoading = ref(props.loading);

const computedLoading = computed(() => (props.noLoading ? undefined : props.loading || innerLoading.value));

function onClick(event) {
  const promisifyFunc = async () => props.onClick(event);
  innerLoading.value = true;
  return promisifyFunc().finally(() => (innerLoading.value = false));
}
</script>

<script>
export default {
  name: 'NButton',
};
</script>

<style lang="less">
.n-button {
  &[disabled] {
    span.anticon {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
</style>
