import qs from 'qs';
import axios from 'axios';
import { merge } from 'lodash-es';
import { paramFilter } from '@/utils';
import { RESPONSE_RESULT } from '@/constant';
import { removeToken } from '@/services/token';
import { useLanguage, useRouter } from '@/hooks';
import { ErrorMessage, NavigatorMessage, NoAuthMessage } from '@/models';

export function setAxiosConfig(config = {}) {
  merge(axios.defaults, config);
}

setAxiosConfig({
  withCredentials: true,
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

const { CSV } = RESPONSE_RESULT;

async function onResolve(response) {
  let {
    headers,
    data: responseData,
    config: { url, baseURL },
  } = response;

  if (headers['content-type']?.includes(CSV)) return response;

  let { success, errorCode } = responseData;
  if (success === false) {
    if (
      errorCode === 'LOGIN_NOT_ENABLED' ||
      errorCode === 'INVALID_ARGUMENT_TOKEN_EXPIRED' ||
      errorCode === 'INVALID_ARGUMENT_PASSWORD_TIME_MISMATCH' ||
      errorCode === 'INVALID_ARGUMENT_MISSING_PASSWORD_VER_CLAIM' ||
      errorCode === 'INVALID_ARGUMENT_CUSTOMER_NOT_FOUND' ||
      errorCode === 'INVALID_ARGUMENT_MISSING_LOGIN_CODE' ||
      errorCode === 'INVALID_ARGUMENT_PASSWORD_VER_MISMATCH' ||
      errorCode === 'INVALID_ARGUMENT_INVALID_ISSUER_FORMAT' ||
      errorCode === 'INVALID_ARGUMENT_KRAMPUS_API_TOKEN_FAILED'
    ) {
      const { routerTo } = useRouter();
      removeToken();
      routerTo('/login');
    }
    throw createResponseErrorMessage(responseData);
  }

  return responseData;
}

async function onReject(error = {}) {
  let errorData;

  const { code, message, response } = error;

  if (message === 'Network Error') {
    errorData = new ErrorMessage({ content: message });
  } else if (code === 'ECONNABORTED') {
    errorData = null; // ignore
  } else if (400 === response?.status) {
    errorData = createResponseErrorMessage(response.data);
  } else if (401 === response?.status) {
    errorData = new NoAuthMessage({ data: response });
  } else if (404 === response?.status) {
    errorData = new ErrorMessage({ content: '404 NotFound' });
  } else if ([500, 502, 503, 504].includes(response?.status)) {
    errorData = new NavigatorMessage({ path: '/error', type: 'replace', data: response });
  } else if (response?.data) {
    errorData = response.data;
  } else {
    errorData = new NavigatorMessage({ path: '/login', type: 'replace', data: response });
  }

  if (errorData) {
    throw errorData;
  }
}

axios.interceptors.response.use(onResolve, onReject);

export function $fetch(url, params, strict = true) {
  if (strict) params = paramFilter(params);
  url = params ? `${url}?${qs.stringify(params)}` : url;
  return axios.get(url);
}

export function $post(url, data) {
  return axios.post(url, data);
}

export function $put(url, data) {
  return axios.put(url, data);
}

export function $delete(url, params) {
  return axios.delete(url, { params });
}

export function $upload(url, data) {}

export function createResponseErrorMessage(responseData) {
  const { errorCode, errorParamMap } = responseData;
  const { te, t, PREFIX } = useLanguage();
  let content = te(errorCode, PREFIX.ERROR_CODE)
    ? t(errorCode, PREFIX.ERROR_CODE, errorParamMap)
    : t('serverUnavailable', PREFIX.ERROR);
  return new ErrorMessage({ content }, responseData);
}
