<template>
  <NButton type="primary" class="n-download" ghost @click="onClick">
    <template #icon><DownloadOutlined /></template>
    {{ text }}
  </NButton>
</template>

<script setup>
import { download } from '@/utils';

const props = defineProps({
  text: String,
  url: String,
  params: Object,
});

const emit = defineEmits(['after-download']);

const onClick = () => {
  return download(props.url, props.params, { strict: true }).finally(() => emit('after-download'));
};
</script>

<style lang="less">
.n-download {
  &:hover {
    background: var(--ant-primary-color) !important;
    color: @white !important;
  }
}
</style>
