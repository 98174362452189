<template>
  <NFormItem v-bind="formItemBaseAttrs">
    <BaseDatePicker class="n-datepicker" v-bind="componentAttrs" />
  </NFormItem>
</template>

<script setup>
import { computed, useAttrs } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';

const attrs = useAttrs();

const props = defineProps({
  label: String,
  name: [String, Array],
  customLabel: String,
});

const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);

const componentAttrs = computed(() => ({
  ...componentBaseAttrs.value,
  ...attrs,
}));
</script>

<script>
export default {
  name: 'NDatePicker',
  inheritAttrs: false,
};
</script>

<style lang="less">
.n-datepicker {
  width: 100%;
}
</style>
