<template>
  <NSelect class="n-multiple-select" mode="multiple" v-bind="mAttrs" />
</template>

<script setup>
import { sortBy } from 'lodash-es';
import { SELECT } from './constant';
import { useLanguage } from '@/hooks';
import { computed, ref, useAttrs, watchEffect } from 'vue';

const attrs = useAttrs();

const { tl } = useLanguage();

const { ALL_OPTION_VALUE } = SELECT;

const emit = defineEmits(['select', 'deselect', 'update:value', 'change']);

const props = defineProps({
  value: { type: Array, default: () => [] },
  options: { type: Array, default: () => [] },
  maxTagCount: { type: Number, default: 1 },
});

const allOptionValues = computed(() => sortBy(props.options.map(o => o.value)));

const isAllSelected = computed(() => props.value.length === allOptionValues.value.length);

const selectedCount = computed(() => props.value.length);

const mMaxTagCount = computed(() => {
  const maxTagCount = props.options.length === 1 ? 0 : props.maxTagCount;
  return selectedCount.value <= maxTagCount ? maxTagCount : 0;
});

const maxTagPlaceholder = computed(() => {
  return isAllSelected.value
    ? tl('allSelectedCount', { count: props.options.length })
    : tl('selectedCount', { count: props.value.length });
});

const mValue = ref(props.value); // 内外隔离

watchEffect(() => {
  if (isAllSelected.value) {
    // 全选
    mValue.value = [ALL_OPTION_VALUE, ...props.value];
  } else {
    mValue.value = props.value;
  }
});

const mAttrs = computed(() => ({
  allOption: true,
  ...attrs,
  value: mValue.value,
  options: props.options,
  maxTagCount: mMaxTagCount.value,
  maxTagPlaceholder: maxTagPlaceholder.value,
  dropdownClassName: 'n-multiple-select-dropdown',
  'onUpdate:value'() {},
  onChange() {},
  onSelect(value, option) {
    let newValue = allOptionValues.value;
    if (value !== ALL_OPTION_VALUE) {
      newValue = sortBy([...props.value, value]);
    }
    emit('update:value', newValue);
    emit('change', newValue);
    emit('select', value, option);
  },
  onDeselect(value, option) {
    let newValue = [];
    if (value !== ALL_OPTION_VALUE) {
      newValue = sortBy(props.value.filter(v => v !== value));
    }
    emit('update:value', newValue);
    emit('change', newValue);
    emit('deselect', value, option);
  },
}));
</script>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style lang="less">
.n-multiple-select {
  .ant-select-selection-overflow-item {
    max-width: 96%;
  }
}

.n-multiple-select-dropdown {
  .ant-select-item-option-disabled {
    background-color: @select-multiple-disabled-background;
  }
}
</style>
