export const LANGUAGES = [
  {
    label: '中文（简体）',
    value: 'cn',
    flag: '/images/country/zh.png',
  },
  {
    label: '中文（繁體）',
    value: 'tw',
    flag: '/images/country/zh.png',
  },
  {
    label: 'English',
    value: 'en',
    flag: '/images/country/en.png',
  },
  {
    label: '日本語',
    value: 'ja',
    flag: '/images/country/ja.png',
  },
  {
    label: '한국인',
    value: 'ko',
    flag: '/images/country/ko.png',
  },
  {
    label: 'Português',
    value: 'pt',
    flag: '/images/country/pt.png',
  },

];
