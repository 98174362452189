<template>
  <div class="n-short-text">
    <n-span :value="maskText" :title="text" @dblclick="toClipboard(text)" />
    <copy-outlined v-if="!isShort" :title="$tl('copy')" @click="toClipboard(text)" />
  </div>
</template>

<script>
import { pad } from 'lodash-es';
import { useClipboard } from '@/hooks';
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'NShortText',

  inheritAttrs: false,

  props: {
    value: [String, Number],
    leadingDigit: { type: Number, default: 4 },
    starCount: { type: Number, default: 4 },
  },

  setup(props) {
    const { toClipboard } = useClipboard();

    const text = computed(() => props.value?.toString());

    const length = computed(() => text.value?.length ?? 0);

    const isShort = computed(() => length.value < props.leadingDigit);

    const maskText = computed(() => {
      if (!text.value) return undefined;
      return isShort.value ? text.value : `${text.value.substring(0, props.leadingDigit)}${pad('*', props.starCount, '*')}`;
    });

    return { text, isShort, maskText, toClipboard };
  },
});
</script>

<style lang="less">
.n-short-text {
  width: auto;

  .anticon-copy {
    margin-left: 4px;
    display: none;
  }

  &:hover .anticon-copy {
    display: inline-block;
  }
}
</style>
