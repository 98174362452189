export default {
  profile: '個人信息',
  customer: '客戶',
  totalPeopleQuantity: '合計：{quantity}人',
  verifiedPeopleQuantity: '已認證：{quantity}人',
  enabledPeopleQuantity: '已啟用：{quantity}人',
  unlockedPeopleQuantity: '未鎖定：{quantity}人',
  unverifiedPeopleQuantity: '未認證：{quantity}人',
  unenabledPeopleQuantity: '未啟用：{quantity}人',
  lockedPeopleQuantity: '已鎖定：{quantity}人',
  verify: '審核',
  identityVerify: '身份認證',
  withdrawalVerify: '提現審批',
  pendingApproval: '待審核',
  pendingProcess: '待處理',
  processed: '已處理',
};
