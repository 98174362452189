<template>
  <AProgress :percent="value" v-bind="$attrs" :stroke-linecap="strokeLinecap" :size="size" />
</template>

<script setup>
import { computed } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';
import { Progress as AProgress } from 'ant-design-vue/es';
import { isFunction } from 'lodash-es';

const props = defineProps({
  value: undefined,
  size: { type: String, default: 'small' },
  strokeColor: { type: String, default: 'blue' },
  strokeLinecap: { type: String, default: 'square' },
  type: { type: String, default: 'line' },
});

const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);

const componentAttrs = computed(() => ({
  ...componentBaseAttrs.value,
  ...attrs,
}));

const emits = defineEmits(['update:value']);
</script>

<script>
export default {
  name: 'NProgress',
  inheritAttrs: false,
};
</script>
