import { computed } from 'vue';
import { useLanguage } from '@/hooks/useLanguage';

const LANGUAGE_MAP = { cn: 'sc', en: 'en', ja: 'ja', ko: 'ko', pt: 'pt', tw: 'tc' };

export const useDoc = (config = {}) => {
  const { isItUser = false, domain = window.location.origin } = config;
  const { curLang } = useLanguage();

  const adminDocUrl = computed(() => `${domain}/help/${LANGUAGE_MAP[curLang.value]}/${isItUser.value ? 'cms_it' : 'cms_agent'}`);
  const frontDocUrl = computed(() => `${domain}/help/${LANGUAGE_MAP[curLang.value]}/cms_customer`);

  return { adminDocUrl, frontDocUrl };
};
