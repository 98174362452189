<template>
  <NInput
    :scale="scale"
    :value-pattern="valuePattern"
    :display-pattern="mDisplayPattern"
    v-bind="$attrs"
    class="n-input-percent"
  />
</template>

<script setup>
import { computed } from 'vue';
import { toPercent } from '@/utils';
import { inputableDecimalRegex } from '@/constant';

const props = defineProps({
  scale: { type: Number, default: 2 },
  valuePattern: { type: RegExp, default: inputableDecimalRegex },
  displayPattern: { type: Function, default: undefined },
});

const mDisplayPattern = computed(() => {
  return v => (!v && v !== 0 ? '' : toPercent(v, props.scale));
});
</script>

<script>
export default {
  name: 'NInputPercent',
};
</script>
