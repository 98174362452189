<template>
  <NForm class="n-search-form">
    <slot />
    <div v-if="hasBtn" class="n-search-form-last-item n-search-button-box">
      <n-button v-if="onSearch" type="primary" :loading="mLoading" @click="onBtnSearch">
        <template #icon><SearchOutlined /></template>
        {{ $tl('search') }}
      </n-button>
      <n-button v-if="onReset" class="ml-2" @click="onBtnReset">
        <template #icon><UndoOutlined /></template>
        {{ $tl('reset') }}
      </n-button>
    </div>
  </NForm>
</template>

<script setup>
import { ref, computed, useAttrs } from 'vue';
import { useLanguage } from '@/hooks';

const props = defineProps({
  loading: Boolean,
  onSearch: Function,
  onReset: Function,
});

const emit = defineEmits(['reset']);

const { tl } = useLanguage();
const attrs = useAttrs();
const searching = ref(false);
const mLoading = computed(() => props.loading || searching.value);

const hasBtn = computed(() => props.onSearch && props.onReset);

const onBtnSearch = () => {
  searching.value = true;
  return (async () => props.onSearch())().finally(() => {
    searching.value = false;
  });
};

const onBtnReset = () => emit('reset');
</script>

<script>
export default {
  name: 'NSearchForm',
};
</script>

<style lang="less">
.n-search-form {
  margin-bottom: 14px;
  padding: 16px 16px 4px 16px;
  position: sticky;
  left: 0;
  z-index: 200;
  display: flex;
  flex-wrap: wrap;
  background-color: #fafafa;
  border: 1px solid #e8eaec;

  & > .ant-form-item,
  & > .n-search-button-box {
    width: 25%;
  }

  .ant-form-item {
    margin-bottom: 12px;

    &.ant-form-item-label {
      max-width: 40%;
    }

    &.ant-form-item-control {
      max-width: 60%;
    }

    &:nth-child(4n - 3) .ant-form-item-label {
      text-align: left;
      padding-left: 10px;
    }
  }

  .ant-form-item-explain {
    display: none !important;
  }

  .n-search-form-last-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
  }

  .n-search-button-box {
    height: fit-content;
    margin-bottom: 12px;
  }
}

@media (max-width: 1535px) {
  .n-search-form {
    & > .ant-form-item,
    & > .n-search-button-box {
      width: 33.333333%;
    }

    .ant-form-item {
      &:nth-child(3n) .ant-form-item-label,
      &:nth-child(3n - 1) .ant-form-item-label {
        text-align: right;
        padding-left: 0;
      }
      &:nth-child(3n - 2) .ant-form-item-label {
        text-align: left;
        padding-left: 10px;
      }
    }
  }
}

@media (max-width: 1279px) {
  .n-search-form {
    & > .ant-form-item,
    & > .n-search-button-box {
      width: 50%;
    }

    .ant-form-item {
      &:nth-child(2n - 1) .ant-form-item-label {
        text-align: left;
        padding-left: 8px;
      }

      &:nth-child(2n) .ant-form-item-label {
        text-align: right;
        padding-left: 0;
      }
    }
  }
}

@media (max-width: 1023px) {
  .n-search-form {
    margin-bottom: 24px;

    & > .ant-form-item,
    & > .n-search-button-box {
      width: 100%;

      &.n-search-button-box {
        margin-top: 12px;
      }
    }

    .ant-form-item:nth-child(n) {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));

      .ant-form-item-label {
        max-width: 100%;
        padding: 0 0 0 8px;
        text-align: left;
        height: 40px;
        line-height: 40px;
      }

      .ant-form-item-control {
        max-width: 100%;
      }
    }
  }
}
</style>
