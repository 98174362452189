export default {
  ApprovalStatus: {
    SUBMIT: '提交',
    RETRACT: '撤回',
    APPROVED: '通过',
    REJECT: '駁回',
  },
  ConfigKey: {
    DEFAULT_AVATAR_PATH: '默認頭像路徑',
    KRAMPUS_URL: 'KRAMPUS URL',
    ADMIN_HOST: '管理畫面地址',
    MIN_DEPOSIT: '最小存入限制（美元）',
    MIN_WITHDRAWAL: '最小取出限制（美元）',
    UPLOAD_PATH: '上傳文件的系統根目錄',
    FILE_PATH: '上傳文件子目錄',
    REGISTER_URL_BASE: 'REGISTER URL BASE',
    JWT_VERSION_FRONT: 'JWT FRONT版本',
    JWT_VERSION_ADMIN: 'JWT ADMIN版本',
    MAX_DEPOSIT: '最大充值金額（美元）',
    MAX_WITHDRAWAL: '最大提現金額（美元）',
    SENTRY_ENABLED: 'Sentry 啟用',
    SENTRY_URL: 'Sentry URL',
  },
  Currency: {
    USDT: 'USDT',
    BRL: '雷亞爾',
    INR: '盧比',
    USD: '美元',
    EUR: '歐元',
    HKD: '港幣',
  },
  CustomerField: {
    NAME: '姓名',
    MOBILE: '手機號',
    SEX: '性別',
    DOB: '生日',
    DISTRICT: '地區',
  },
  FlowChartType: {
    VERIFIED: '客戶認證流程',
    DEPOSIT: '充值處理流程',
    WITHDRAWAL: '提現處理流程',
  },
  FlowInfoType: {
    VERIFIED: '客戶認證',
    DEPOSIT: '入金處理',
    WITHDRAWAL: '出金處理',
  },
  IdentificationType: {
    ID_CARD: '身份證',
    PASSPORT: '護照',
    DRIVER_LICENSE: '駕照',
    SOCIAL_INSURANCE_CARD: '社保卡',
    OTHER: '其他',
  },
  ReceiveMethod: {
    BANK: '銀行',
    USDT: 'USDT',
    NET_PAY: '網絡支付',
  },
  ResourceType: {
    ID: '身份信息',
    CUSTOMER_AVATAR: '客戶頭像',
    IT_RECEIVE_AVATAR: '機構收款信息',
    PROCESS_FLOW: '流程圖',
    TRANSFER_PROOF: '轉賬憑證',
  },
  Sex: {
    MALE: '男',
    FEMALE: '女',
    SECRET: '保密',
  },
  TransferTransactionStatus: {
    TRANSFERRING: '待轉賬',
    TRANSFERRED: '已轉賬',
    CONFIRMED: '已收款',
    CANCELLED: '已取消',
    FORCE_CONFIRMED: '已收款（強制）',
    FORCE_CANCELLED: '已取消（強制）',
    FAILED: '失敗',
  },
  TransferRole: {
    CUSTOMER: '客戶',
    IT: '機構',
  },
  DurationType: {
    MINUTES: '分鐘',
    HOURS: '小時',
  },
  Lang: {
    EN: '英文',
    ZH_CN: '中文（簡體）',
    ZH_HK: '中文（繁體）',
    JA: '日語',
    KO: '韓語',
    PT: '葡萄牙語',
  },
  CryptoNetwork: {
    TRC20: 'TRC20',
    ERC20: 'ERC20',
  },
  RoleType: {
    SUPER_USER: '超級用戶',
    ROLE_IT: '機構管理員',
    ROLE_AGENT: '代理管理員',
  },
  Country: {
    SOUTH_KOREA: '韓國',
    JAPAN: '日本',
    BRAZIL: '巴西',
    INDIA: '印度',
    TAIWAN: '台灣',
  },
  QMaxInvestmentOption: {
    HKD_300000: '$300,000',
    HKD_100: '$100',
  },
  QTriggerOption: {
    AT_LATEST_PRICE: '我的倉位已按照最新可用價格平倉',
    STOP_LOSS_NOT_TRIGGERED: '止損不能關閉我的倉位',
  },
  AutoCloseOption: {
    INSUFFICIENT_CAPITAL: '市場沿着對我倉位不利的方向發展，我的賬戶沒有足夠的資本來維持我的頭寸',
    FAVORABLE_DIRECTION: '市場正在轉向我的有利位置',
  },
  QTradingCountOption: {
    COUNT_100: '51到100筆交易',
    COUNT_50: '20到50筆交易',
    COUNT_LESS_THAN_20: '少於二十筆交易',
    COUNT_NONE: '從未進行交易',
  },
  QOtcLeverageOption: {
    BETWEEN_1_50_AND_1_100: '在1:50-1:100之間',
    BETWEEN_1_25_AND_1_50: '在1:25-1:50之間',
    BETWEEN_1_2_AND_1_25: '在1:2-1:25之間',
    NO_LEVERAGE: '不使用杠桿（1:1）',
  },
  QFinanceEducationOption: {
    WORKED_IN_FINANCIAL: '曾在金融服務行業工作',
    PROFESSIONAL: '專業資質/或高等教育',
  },
  QOccupationOption: {
    EMPLOYEE: '僱員',
    SELF_EMPLOYED: '自由職業',
    RETIRED: '退休人員',
    STUDENT: '學生',
    PROPERTY: '物業',
  },
  QIndustryOption: {
    FINANCIAL_SERVICES: '金融服務行業',
    INFORMATION_TECHNOLOGY: '信息技術行業',
    HEALTHCARE: '醫療保健行業',
    RETAIL_AND_CONSUMER_GOODS: '零售和消費品行業',
    MANUFACTURING: '製造業',
    MEDIA_AND_ENTERTAINMENT: '媒體和娛樂行業',
    CONSTRUCTION_AND_REAL_ESTATE: '建築和房地產行業',
    ENERGY_AND_UTILITIES: '能源和公用事業產業',
    CONSULTING_AND_PROFESSIONAL_SERVICES: '咨詢和專業服務行業',
    OTHER: '其他',
  },
  QFinancialAmountOption: {
    FIVE_M_PLUS: '$5,000,000+',
    FIVE_M: '$2,000,000 - 5,000,000',
    TWO_M: '$1,000,000 - 2,000,000',
    ONE_M: '$500,000 - 1,000,000',
    FIVE_HUNDRED_K: '$200,000 - 500,000',
    TWO_HUNDRED_K: '$50,000 - 200,000',
    FIFTY_K: '$0 - 50,000',
  },
  QIncomeSourceOption: {
    EMPLOYMENT_INCOME: '在職/自由職業收入',
    PENSION_OR_GOVERNMENT_BENEFITS: '退休金或政府福利',
    INVESTMENT_INCOME: '投資收益',
    INHERITANCE: '遺產',
    PROPERTY_SALE: '物業出售',
    BUSINESS_SALE: '企業出售',
  },
  QTradingFrequencyOption: {
    DAILY: '每天',
    WEEKLY: '每個星期',
    MONTHLY: '每個月',
  },
  QDepositMethodOption: {
    CREDIT_CARD: '信用卡/借記卡',
    BANK_TRANSFER: '銀行電匯',
  },
  PaymentGateway: {
    PPAY: 'ppay通道',
    CASH_PAY: 'cashpay通道',
    UAT_EPAY: 'UAT epay通道',
    PRO_EPAY: 'PRO epay通道',
    BETCAT_PAY: 'betcat pay通道',
    UAT_BETCAT_PAY: 'UAT betcat pay通道',
  },
  PayType: {
    RECEIVE: '充值',
    PAYMENT: '提現',
  },
  TransactionModel: {
    C2C: 'C2C',
    B2C: 'B2C',
    B2B: 'B2B',
    C2B: 'C2B',
  },
  TransactionCategory: {
    BANK: '銀行',
    TRANSFER: '轉賬',
    IFSC: '印度支付-IFSC',
    PIX: '巴西支付-PIX',
  },
  FeeModel: {
    FIXED: '固定手續費',
    PERCENTAGE: '百分比手續費',
  },
  RateMode: {
    USD_TO_TARGET: '美金到目標幣種',
    TARGET_TO_USD: '目標幣種到美金',
  },
  PaymentOrderStatus: {
    FAILED: '處理失敗',
    SUCCESS: '處理成功',
    PENDING: '未處理',
    CANCELED: '取消完成',
    PROCESSING: '處理中',
  },
  DepositPaymentOrderStatus: {
    FAILED: '儲值失敗',
    SUCCESS: '儲值成功',
    PENDING: '未處理',
    CANCELED: '取消完成',
    PROCESSING: '儲值中',
  },
  WithdrawPaymentOrderStatus: {
    FAILED: '提現失敗',
    SUCCESS: '提現成功',
    PENDING: '未處理',
    CANCELED: '取消完成',
    PROCESSING: '提現中',
  },
  PaySystemOrderStatus: {
    GATEWAY_CREATE: 'GATEWAY_CREATE',
    CONFIRM: 'CONFIRM',
    CANCEL: 'CANCEL',
    FAIL: 'FAIL',
    SUCCESS: 'SUCCESS',
    CLOSE: 'CLOSE',
    FREEZE: 'FREEZE',
    WITHDRAWN: 'WITHDRAWN',
    NO_REFUND_OF_HANDLING_FEE: 'NO_REFUND_OF_HANDLING_FEE',
  },
  ProviderType: {
    GOOGLE: '谷歌賬號',
    FACEBOOK: 'Facebook賬號',
    APPLE: '蘋果賬號',
    PASSWORD: '密碼',
  },
  PaymentChannel: {
    INDIA_UPI_1: 'PPAY-印度UPI一類',
    INDIA_NATIVE_1: 'PPAY-印度原生一類',
    INDIA_UPI_2: 'PPAY-印度UPI二類',
    INDIA_NATIVE_2: 'PPAY-印度原生二類',
    BRAZIL_PIX_1: 'PPAY-巴西PIX一類',
    BRAZIL_PIX_2: 'PPAY-巴西PIX二類',
    BRAZIL_PIX_FAST: 'PPAY-巴西PIX快殺',
    EPAY_BRAZIL_TS_B2C: 'EPAY-巴西-TS-B2C',
    EPAY_BRAZIL_TS_B2B: 'EPAY-巴西-TS-B2B',
    EPAY_INDIA_IY_B2C: 'EPAY-印度-IY',
    NONE: '无',
  },
  BankInfoExtType: {
    NORMAL: 'NORMAL',
    PIX_EXT: 'PIX_EXT',
    IFSC_EXT: 'IFSC_EXT',
    BANK_EXT: 'BANK_EXT',
  },
  PixType: {
    CPF: '個人身份識別號碼',
    PHONE: '手機號碼',
    EMAIL: '電子郵件地址',
    EVP: '虛擬支付地址',
  },
  EpayCountryCode: {
    BR: '巴西',
  },
  EpayGender: {
    MALE: '男',
    FEMALE: '女',
  },
  EpayIdType: {
    ID_CARD: '身份证',
    PASSPORT: '护照',
  },
  EpayPurposeOfRemittance: {
    CorporatePayment: '企業付款',
    Marketplace: '市場交易',
    ProfessionalServices: '專業服務',
    Payroll: '工資支付',
    ExpensesReimbursement: '費用報銷',
    RepresentationExpenses: '企業付款',
    HiredPersonnel: '聘請人員',
    TraineesScholarships: '培訓生/獎學金',
    ProductivityOrQualityPrizes: '生產力或品質獎項',
    LayOffPayment: '裁員支付',
    FamilySupport: '家庭支持',
    Education: '教育',
    GiftAndOtherDonations: '禮物和其他捐贈',
    MedicalTreatment: '醫療治療',
    MaintenanceOrOtherExpenses: '維修或其他費用',
    CarDriver: '司機',
    SoftwareLicense: '軟體授權',
    HotelAccommodation: '飯店住宿',
    AdvertisingAndOrPublicRelationsRelatedExpenses: '廣告和/或公共關係相關費用',
    FeesForAdvisoryOrConsultingService: '諮詢或顧問服務費',
    BusinessRelatedInsurancePayment: '商業相關保險支付',
    InsuranceClaimsPayment: '保險理賠支付',
  },
  Gender: {
    MALE: '男性',
    FEMALE: '女性',
    UNKNOWN: '未知',
    NOT_APPLICABLE: '不适用',
  },
  BeneficiaryRelationShip: {
    BOYFRIEND: '男朋友',
    BUSINESS_PARTNER: '合伙人',
    CHILDREN: '子女',
    COLLEAGUE: '同学',
    EX_SPOUSE: '前配偶',
    FIANCE: '未婚夫',
    FIANCEES: '未婚妻',
    FRIEND: '朋友',
    GIRLFRIEND: '女朋友',
    GRAND_PARENTS: '祖父母',
    PARENTS: '父母',
    RELATIVE_UNCLE_AUNTIE_COUSIN: '亲戚/叔叔/阿姨/表弟',
    SELF: '自己',
    SIBLING_BROTHER_SISTER: '同胞/兄弟/姐妹',
    SPOUSE: '配偶',
    SUBSIDIARY_COMPANY: '子公司',
  },
  PayPurpose: {
    DEFAULT: '默認',
    ENTRY_FEE: '比賽報名費',
    SERVICE_FEE: '平臺服務費',
    OTHER: '其他',
  },
};
