export default {
  verify: '審核',
  transfer: '轉賬',
  identification: '身份',
  withdrawal: '提現',
  itInitiate: '發起（機構）',
  itProcessing: '處理（機構）',
  itEdit: '編輯（機構）',
  itCancel: '取消（機構）',
  itConfirm: '收款（機構）',
  itForceCancel: '強制取消（機構）',
  itForceConfirm: '強制收款（機構）',
  customerForceCancel: '強制取消（客戶）',
  customerForceConfirm: '強制收款（客戶）',
  alert: {
    identification: '在線審核客戶發起的「身份認證」。',
    withdrawal: '在線審核客戶發起的「提現申請」。',
    itInitiate: '當前機構發起轉賬，匹配滿足條件的客戶提現。',
    itProcessing: '處理機構發起的轉賬。',
    itProcessingAction1: '第一步：機構給客戶實際轉賬',
    itProcessingAction2: '第二步：截圖轉賬憑證',
    itProcessingAction3: '第三步：上傳轉賬憑證（狀態變成『已轉賬』）',
    itEdit: '針對機構發起的轉賬，編輯「憑證」或「留言」。',
    itCancel: '取消機構發起的轉賬。',
    itConfirm: '確認機構已收到付款。',
    itForceCancel: '強制取消機構發起的轉賬。',
    itForceConfirm: '強制確認機構已收到付款。',
    customerForceCancel: '強制取消客戶發起的轉賬。',
    customerForceConfirm: '強制確認客戶已收到付款。',
  },
};
