export default {
  success: {
    successfully: '{key} Successfully',
    copyToClipboard: 'Copied to clipboard',
    approved: '{key} has been approved',
    processed: '{key} has been processed',
    uploaded: '{key} has been uploaded',
    register: 'Register Succeed, Please Login',
  },
  warning: {
    rejected: '{key} has been rejected',
    removeFxRateTemplate: "Note: Template using '{name}' will also be removed from institution, agencies and customers.",
    transferFromIt: 'Are you sure to transfer the all remaining amount [ Estimated: {amount} ] from {from} to {to}?',
    clearBrowserCache: 'This operation will clear the browser cache, and restore to the following default settings:',
    forcedRefresh: '*Note: The current page will be forced to refresh!',
    resetPassword1: 'Sending',
    resetPassword2: '{email}',
    resetPassword3: 'a mail to help recover your password',
  },
  error: {
    required: 'Required',
    duplicatedBy: 'Cannot be the same as {target}',
    symbolExisted: 'Current symbol already exists',
    failed: '{key} Failed',
    invalidFormat: 'Invalid Format',
    serverUnavailable: 'Server Unavailable, Please try again later',
    uploadFileType: 'You can only upload {types} file',
    uploadFileSize: 'Size not more than {maxSize}MB',
    uploadFailed: 'Upload Failed',
    underLength: 'Under Length [{min}]',
    overLength: 'Over Length [{max}]',
    decimalOverLength: 'Over Length [{precision}, {scale}]',
    underflow: 'Underflow [{min}]',
    overflow: 'Overflow [{max}]',
    invalidMailFormat: 'Invalid Mail Format',
    notOnlyNumber: 'Not Only Number',
    notOnlyLetter: 'Not Only Letter',
    atLeastOneLowercase: 'At lease one lower case',
    atLeastOneUppercase: 'At lease one upper case',
    atLeastOneNumber: 'At lease one number',
    onlyAlphanumerical: 'Only Alphanumerical',
    cannotBeSuperuser: 'Cannot be super user',
    mustBeSuperuser: 'Must be super user',
    mustBeGreaterThan: 'Must be greater than {number}',
    ranges: 'Ranges:{min}~{max}',
    lengthLimit: 'Lenght must be {length}',
    notSame: 'Enter passwords not match',
    notBlank: 'Not Blank',
    containsSpace: 'Cannot contain space',
  },
  confirm: {
    title: 'Are you sure to {action}?',
    resourceNotFoundTitle: 'Resource out of date',
    resourceNotFoundContent: 'Do you want to reload page to make resource up to date?',
  },
  modal: {
    add: 'Add {name}',
    edit: 'Edit {name}',
    detail: '{name} Detail',
  },
  pagination: {
    showTotal: `{start}-{end} of {total} items`,
  },
  tooltip: {
    settings: 'These settings are saved in the browser cache',
  },
  message: {
    itReceiveCheck: 'Institution receive account verification passed.',
    needToCreateUsdReceiveAccount: "Institution receive account has been check failed, need to create 'USD' receive account.",
  },
};
