<template>
  <NPopconfirm v-bind="popConfirmAttrs" class="n-confirm">
    <NButton v-bind="btnAttrs">
      <template #icon>
        <slot name="icon"><DeleteOutlined /></slot>
      </template>
      <slot />
    </NButton>
  </NPopconfirm>
</template>

<script>
import { useLanguage } from '@/hooks';
import { lowerCase } from 'lodash-es';
import { computed, ref, defineComponent } from 'vue';

export default defineComponent({
  name: 'NConfirm',

  inheritAttrs: false,

  props: {
    action: String,
    color: String,
    type: { type: String, default: undefined },
    size: { type: String, default: 'middle' },
    ghost: { type: Boolean, default: undefined },
    danger: { type: Boolean, default: true },
    isCircle: { type: Boolean, default: false },
    cancelText: { type: String, default: 'close' },
    placement: { type: String, default: 'topLeft' },
    disabled: { type: Boolean, default: false },
    btnClass: [String, Object],
  },

  setup(props, { attrs }) {
    const { tl, t, PREFIX, isEn } = useLanguage();

    const loading = ref(false);

    let { onConfirm } = attrs;

    const popConfirmAttrs = computed(() => {
      let action = tl(props.action);
      if (isEn.value) action = lowerCase(action);
      const title = t('title', PREFIX.CONFIRM, { action });
      const cancelText = tl(props.cancelText);
      const placement = props.placement;
      const disabled = props.disabled;
      return { ...attrs, title, cancelText, placement, disabled, onConfirm: confirm };
    });

    const btnAttrs = computed(() => {
      return {
        type: props.type ?? (props.isCircle ? 'text' : undefined),
        shape: props.isCircle ? 'circle' : undefined,
        size: props.size,
        danger: props.danger,
        ghost: props.ghost ?? !props.isCircle,
        loading: loading.value,
        style: { color: props.color, backgroundColor: props.bgColor },
        title: tl(props.action),
        disabled: props.disabled,
        class: props.btnClass,
      };
    });

    const confirm = () => {
      loading.value = true;
      return (async () => onConfirm())().finally(() => (loading.value = false));
    };

    return { popConfirmAttrs, btnAttrs };
  },
});
</script>

<style lang="less">
.n-confirm {
  &:not(:disabled) {
    &.ant-btn-background-ghost.ant-btn-dangerous:hover {
      background: var(--ant-error-color) !important;
      color: @white !important;
    }

    &.ant-btn-background-ghost.ant-btn-primary:hover {
      background: var(--ant-primary-color) !important;
      color: @white !important;
    }
  }
}
</style>
