import { ref } from 'vue';
import { ConfigProvider } from 'ant-design-vue/es';
import { ENV_COLOR } from '@/constant';

export const env = ref(import.meta.env.VITE_APP_ENV);

export function setEnv(_env, theme = ENV_COLOR[_env]) {
  theme && ConfigProvider.config({ theme });

  env.value = _env;

  const prefix = 'n-env-';
  // remove
  document.body.className = document.body.className.replace(new RegExp(prefix + '[a-z]+', 'g'), '');
  document.body.classList.add(prefix + _env.toLowerCase());
}

if (['LOCAL', 'DEV'].includes(import.meta.env.VITE_APP_ENV)) {
  window.setEnv = setEnv;
}
