<template>
  <NDivider class="step-divider" />
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useFormItemBaseAttrs } from './hooks/useFormItemBaseAttrs';

export default defineComponent({
  name: 'NSimpleDivider',

  inheritAttrs: false,

  props: {},

  setup(props, { attrs }) {
    const { formItemBaseAttrs, componentBaseAttrs } = useFormItemBaseAttrs(props);

    const componentAttrs = computed(() => ({
      ...componentBaseAttrs.value,
      ...attrs,
    }));

    return { formItemBaseAttrs, componentAttrs };
  },
});
</script>

<style lang="less">
.step-divider {
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #aaaaaa;
}
</style>
