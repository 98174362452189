import { computed, ref } from 'vue';
import { storage } from '@/services';
import { PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE, TABLE_KEY } from '@/constant';

const curBordered = ref();

const curPageSize = ref();

export const useTableConfig = () => {
  curBordered.value = storage.get(TABLE_KEY)?.bordered ?? false;

  curPageSize.value = storage.get(TABLE_KEY)?.pageSize ?? DEFAULT_PAGE_SIZE;

  const pageSizeOptions = computed(() => PAGE_SIZE_OPTIONS.map(o => ({ value: o, label: o })));

  const changeTable = _table => {
    curBordered.value = _table.bordered;
    curPageSize.value = _table.pageSize;
    storage.set(TABLE_KEY, { bordered: curBordered.value, pageSize: +curPageSize.value });
  };

  const clearTableCache = () => storage.remove(TABLE_KEY);

  return { curBordered, curPageSize, pageSizeOptions, changeTable, clearTableCache };
};
