export default [
  {
    name: 'menu.cash.title',
    path: '/cash',
    icon: 'fas fa-money-bill',
    children: [
      /*{
        name: 'menu.cash.itReceive',
        path: '/it-receive',
        meta: {
          keepAlive: true,
          cacheName: 'CashItReceive',
        },
        component: () => import('../views/index/cash/it_receive/ItReceive.vue'),
      },*/
      // {
      //   name: 'menu.cash.usdtErc20Config',
      //   path: '/usdt-erc20-config',
      //   meta: {
      //     keepAlive: true,
      //     cacheName: 'CashUsdtErc20Config',
      //     hidden: ['ROLE_AGENT'],
      //     lookupHidden: ['primarySystem'],
      //   },
      //   component: () => import('../views/index/cash/usdt_erc20_config/UsdtErc20Config.vue'),
      // },
      // {
      //   name: 'menu.cash.usdtTrc20Config',
      //   path: '/usdt-trc20-config',
      //   meta: {
      //     keepAlive: true,
      //     cacheName: 'CashUsdtTrc20Config',
      //     hidden: ['ROLE_AGENT'],
      //     lookupHidden: ['primarySystem'],
      //   },
      //   component: () => import('../views/index/cash/usdt_trc20_config/UsdtTrc20Config.vue'),
      // },
      {
        name: 'menu.cash.fxRateTemplate',
        path: '/fx-rate-template',
        meta: {
          keepAlive: true,
          cacheName: 'CashFxRateTemplate',
          // lookupHidden: ['primarySystem'],
        },
        component: () => import('../views/index/cash/fx_rate_template/FxRateTemplate.vue'),
      },
      {
        name: 'menu.cash.fxRateDetail',
        path: '/fx-rate-template/:itId/:fxRateTemplateId/details',
        meta: {
          keepAlive: true,
          cacheName: ['CashFxRateTemplate', 'CashFxRateDetails'],
        },
        component: () => import('../views/index/cash/fx_rate_template/FxRateDetails.vue'),
      },
      {
        name: 'menu.cash.customerDepositFlow',
        path: '/customer-deposit-flow',
        meta: {
          keepAlive: true,
          cacheName: 'CashCustomerDepositFlow',
        },
        component: () => import('../views/index/cash/customer_deposit_flow/CustomerDepositFlow.vue'),
      },
      {
        name: 'menu.cash.customerDepositDetails',
        path: '/customer-deposit-flow/:depositFlowId/details',
        meta: {
          keepAlive: true,
          cacheName: ['CashCustomerDepositFlow', 'CashCustomerDepositDetails'],
        },
        component: () => import('../views/index/cash/customer_deposit_flow/CustomerDepositFlowDetails.vue'),
      },
      {
        name: 'menu.cash.customerWithdrawalFlow',
        path: '/customer-withdrawal-flow',
        meta: {
          keepAlive: true,
          cacheName: 'CashCustomerWithdrawalFlow',
        },
        component: () => import('../views/index/cash/customer_withdrawal_flow/CustomerWithdrawalFlow.vue'),
      },
      {
        name: 'menu.cash.customerWithdrawalDetails',
        path: '/customer-withdrawal-flow/:withdrawalFlowId/details',
        meta: {
          keepAlive: true,
          cacheName: ['CashCustomerWithdrawalFlow', 'CashCustomerWithdrawalDetails'],
        },
        component: () => import('../views/index/cash/customer_withdrawal_flow/CustomerWithdrawalDetails.vue'),
      },
      {
        name: 'menu.cash.transferTransaction',
        path: '/transfer-transaction',
        meta: {
          keepAlive: true,
          cacheName: 'CashTransferTransaction',
        },
        component: () => import('../views/index/cash/transfer_transaction/TransferTransaction.vue'),
      },
      {
        name: 'menu.cash.paymentChannel',
        path: '/payment-channel',
        meta: {
          keepAlive: true,
          cacheName: 'CashPaymentChannel',
          hidden: ['ROLE_IT', 'ROLE_AGENT'],
        },
        component: () => import('../views/index/cash/payment_channel/PaymentChannel.vue'),
      },
      {
        name: 'menu.cash.paymentChannelDetails',
        path: '/payment-channel/:id/details',
        meta: {
          keepAlive: true,
          cacheName: ['CashPaymentChannel', 'CashPaymentChannelDetails'],
          hidden: ['ROLE_IT', 'ROLE_AGENT'],
        },
        component: () => import('../views/index/cash/payment_channel/PaymentChannelDetails.vue'),
      },
      {
        name: 'menu.cash.paymentGateway',
        path: '/payment-gateway',
        meta: {
          keepAlive: true,
          cacheName: 'CashPaymentGateway',
          hidden: ['ROLE_AGENT'],
        },
        component: () => import('../views/index/cash/payment_gateway/PaymentGateway.vue'),
      },
      {
        name: 'menu.cash.paymentGatewayDetails',
        path: '/payment-gateway/:id/details',
        meta: {
          keepAlive: true,
          cacheName: ['CashPaymentGateway', 'CashPaymentGatewayDetails'],
          hidden: ['ROLE_AGENT'],
        },
        component: () => import('../views/index/cash/payment_gateway/PaymentGatewayDetails.vue'),
      },
    ],
  },
];
