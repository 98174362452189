import BigNumber from 'bignumber.js';
import { isArrayJSON } from '@/utils';
import { useLanguage } from '@/hooks/useLanguage';
import { ErrorMessage, ValidationError } from '@/models';
import { isEmpty, isFunction, isEqualWith } from 'lodash-es';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { FILE_TYPE, MAX_UPLOAD_FILE_SIZE, mailRegex } from '@/constant';

/**
 * Form Validate
 *
 * @param config
 *   - formRef: Form实例
 *   - validation: 校验内容，使用计算属性定义，格式如下:
 *                 {
 *                   field1: [
 *                             { required: true },
 *                             { minLength: 1 },
 *                             { maxLength: 100 },
 *                             { decimalLength: { precision: 18, scale: 9 } },
 *                             { min: 1 },
 *                             { max: 100 }
 *                           ],
 *                   field2: ...
 *                 }
 *   - custom: 自定义校验 (如果validation和custom中含有相同字段规则，会使用custom中的规则)
 */
export const useValidate = (config = {}) => {
  const { curLanguage, t, PREFIX } = useLanguage();

  const { formRef = ref({}), validation = ref({}), custom = ref({}) } = config;

  const isValid = computed(() => isEmpty(formRef.value?.errorMap ?? {}));

  const ruleKeys = ref();

  /**
   * 根据validation和custom生成Antd Form使用格式的rules
   * @type {ComputedRef<{}>}
   */
  const rules = computed(() => {
    let rules = {};
    ruleKeys.value = {};
    Object.keys(validation.value).forEach(key => {
      let rule = [];
      let keys = [];
      const v = validation.value?.[key];
      if (v.required) {
        rule.push(requiredRule());
        keys.push('required');
      }
      if (v.arrayRequired) {
        rule.push(arrayRequiredRule());
        keys.push('arrayRequired');
      }
      if (v.isEmail) {
        rule.push(isEmailRule());
        keys.push('isEmail');
      }
      if (v.isJSON) {
        rule.push(isJsonRule());
        keys.push('isJSON');
      }
      if (v.notBlank) {
        rule.push(notBlankRule());
        keys.push('notBlank');
      }
      if (v.minLength) {
        rule.push(minLengthRule(v.minLength));
        keys.push('minLength');
      }
      if (v.maxLength) {
        rule.push(maxLengthRule(v.maxLength));
        keys.push('maxLength');
      }
      if (v.alphanumerical) {
        rule.push(alphanumericalRule());
        keys.push('alphanumerical');
      }
      if (v.decimalLength) {
        const { precision, scale } = v.decimalLength;
        rule.push(decimalLengthRule(precision, scale));
        keys.push('decimalLength');
      }
      if (v.min || v.min === 0) {
        rule.push(minRule(v.min));
        keys.push('min');
      }
      if (v.max || v.max === 0) {
        rule.push(maxRule(v.max));
        keys.push('max');
      }
      if (v.gt || v.gt === 0) {
        rule.push(gtRule(v.gt));
        keys.push('gt');
      }
      if (v.same) {
        rule.push(sameRule(v.same));
        keys.push('same');
      }
      if (v.noSpaces) {
        rule.push(noSpacesRule());
        keys.push('noSpaces');
      }
      if (!isEmpty(rule)) {
        rules[key] = rule;
        ruleKeys.value[key] = keys;
      }
    });
    Object.keys(custom.value).forEach(key => {
      rules[key] = custom.value?.[key];
      ruleKeys.value[key] = ['custom'];
    });
    return rules;
  });

  const clearValidate = names => formRef.value?.clearValidate(names);

  const validate = () => formRef.value?.validate();

  const validateFields = names => formRef.value?.validateFields(names);

  /**
   * Form校验 + 提交
   *   - Form校验失败: 显示各FormItem验证失败信息，不进行后续处理，不提交
   *   - Form校验成功: 进行后续处理并提交
   * @param submitFunc 提交函数
   * @param option model: 提交函数的参数, callback: 校验成功之后，提交之前的回调
   * @returns {Promise<*>}
   */
  const validateSubmit = async (submitFunc, option = {}) => {
    const { model = {}, callback = () => {} } = option;
    if (!isFunction(submitFunc)) throw 'useValidate.validateSubmit: submitFunc must be a function';
    await clearValidate();
    await validate().catch(() => Promise.reject(new ValidationError()));
    await callback();
    return submitFunc(model);
  };

  watch(
    () => curLanguage.value,
    async () => {
      if (!formRef.value || isEmpty(formRef.value)) return;
      const { errorMap } = formRef.value;
      const names = Object.keys(errorMap || {}) ?? [];
      if (!isEmpty(names)) {
        await clearValidate(names);
        await validateFields(names).catch(() => {});
      }
    },
  );

  onMounted(() => {
    /**
     * Antd ^3.2.3 bug修复:
     * 1. 页面联动删除校验规则时，没有处理页面已被验证的错误提示
     * 2. 不是通过当前控件改变value时，没有触发校验
     * @type {{}}
     */
    let ruleWatches = {};
    let valueWatches = {};
    // Step-1: 监听rules变化
    watch(
      () => ruleKeys.value,
      (to = {}, from = {}) => {
        // Step-1.1: 遍历修改前的rules
        Object.keys(from).forEach(async name => {
          // 如果修改前的field在修改后的rules中是空，证明该field校验规则被删除
          if (isEmpty(to[name])) {
            // 1.等待页面加载完成
            await nextTick();
            // 2.清除错误提示（已被删除的rule的错误提示可能被残留）
            clearValidate([name]);
            // 3.结束监听该field（结束监听 & 移除对象）
            ruleWatches[name]();
            delete ruleWatches[name];
            // 4.如果有value监听，结束监听该field的value（结束监听 & 移除对象）
            if (config.model) {
              valueWatches[name]();
              delete valueWatches[name];
            }
          }
        });
        // Step-1.2: 遍历修改后的rules
        Object.keys(to).forEach(name => {
          // 如果修改后的field在修改前的rules中是空，证明该field校验规则被添加
          if (isEmpty(from[name])) {
            // 1.追加 rule watch
            ruleWatches[name] = watch(
              () => ruleKeys.value[name],
              (_to = [], _from = []) => {
                _from.forEach(async fromKey => {
                  const toKey = _to.find(o => o === fromKey);
                  // 如果修改前rule的某个校验规则在修改后rule中是空，证明此校验规则被删除
                  if (isEmpty(toKey)) {
                    // 1.等待页面加载完成
                    await nextTick();
                    // 2.清除错误提示（已被删除的校验规则的错误提示可能被残留）
                    clearValidate([name]);
                    // 3.重新验证该field（可能有多个校验规则，删除其中一个，另一个规则仍不满足）
                    validateFields([name]);
                  }
                });
              },
              { deep: true, immediate: true },
            );

            // 2.追加 value watch
            if (config.model) {
              valueWatches[name] = watch(
                () => config.model[name],
                () => {
                  validateFields([name]);
                },
              );
            }
          }
        });
      },
      { deep: true, immediate: true },
    );
  });

  const requiredRule = (required = true) => {
    return { required, message: t('required', PREFIX.ERROR) };
  };

  const arrayRequiredRule = (required = true) => {
    return {
      required,
      validator: (_, value) => {
        clearValidate([_.field]);
        if (required && (isEmpty(value) || value?.length === 0)) return Promise.reject(new Error(t('required', PREFIX.ERROR)));
        return Promise.resolve();
      },
      trigger: ['blur', 'change'],
    };
  };

  const minLengthRule = min => {
    return {
      validator: (_, value) => {
        if (value && `${value}`.length < min) return Promise.reject(new Error(t('underLength', PREFIX.ERROR, { min })));
        return Promise.resolve();
      },
    };
  };

  const maxLengthRule = max => {
    return {
      validator: (_, value) => {
        if (value && `${value}`.length > max) return Promise.reject(new Error(t('overLength', PREFIX.ERROR, { max })));
        return Promise.resolve();
      },
    };
  };

  const decimalLengthRule = (precision, scale) => {
    return {
      validator: (_, value) => {
        const v = new BigNumber(value);
        if (v.sd(true) > precision || v.dp() > scale || v.toFixed(0, BigNumber.ROUND_DOWN).length > precision - scale)
          return Promise.reject(new Error(t('decimalOverLength', PREFIX.ERROR, { precision, scale })));
        return Promise.resolve();
      },
    };
  };

  const minRule = min => {
    return {
      validator: (_, value) => {
        const v1 = new BigNumber(value);
        const v2 = new BigNumber(min);
        if (v1.lt(v2)) return Promise.reject(new Error(t('underflow', PREFIX.ERROR, { min: v2.toString() })));
        return Promise.resolve();
      },
    };
  };

  const maxRule = max => {
    return {
      validator: (_, value) => {
        const v1 = new BigNumber(value);
        const v2 = new BigNumber(max);
        if (v1.gt(v2)) return Promise.reject(new Error(t('overflow', PREFIX.ERROR, { max: v2.toString() })));
        return Promise.resolve();
      },
    };
  };

  const alphanumericalRule = () => {
    return {
      alphanumerical: true,
      validator: (_, value) => {
        if (value && !/[a-z].*$/.test(value)) {
          return Promise.reject(new Error(t('atLeastOneLowercase', PREFIX.ERROR)));
        }
        if (value && !/[A-Z].*$/.test(value)) {
          return Promise.reject(new Error(t('atLeastOneUppercase', PREFIX.ERROR)));
        }
        if (value && !/[0-9].*$/.test(value)) {
          return Promise.reject(new Error(t('atLeastOneNumber', PREFIX.ERROR)));
        }
        return Promise.resolve();
      },
    };
  };

  const gtRule = gt => {
    return {
      validator: (_, value) => {
        const v1 = new BigNumber(value);
        const v2 = new BigNumber(gt);
        if (v1.isLessThanOrEqualTo(v2))
          return Promise.reject(new Error(t('mustBeGreaterThan', PREFIX.ERROR, { number: v2.toString() })));
        return Promise.resolve();
      },
    };
  };

  const sameRule = str => {
    return {
      validator: (_, value) => {
        if (value && !isEqualWith(str, value)) return Promise.reject(new Error(t('notSame', PREFIX.ERROR)));
        return Promise.resolve();
      },
    };
  };

  const noSpacesRule = () => {
    return {
      validator: (_, value) => {
        if (value && (value.includes(' ') || value.includes('　')))
          return Promise.reject(new Error(t('containsSpace', PREFIX.ERROR)));
        return Promise.resolve();
      },
    };
  };

  const isEmailRule = () => {
    return {
      validator: (_, value) => {
        if (value && !mailRegex.test(value)) return Promise.reject(new Error(t('invalidMailFormat', PREFIX.ERROR)));
        return Promise.resolve();
      },
    };
  };

  const isJsonRule = () => {
    return {
      validator: (_, value) => {
        if (value && !isArrayJSON(value)) return Promise.reject(new Error(t('invalidFormat', PREFIX.ERROR)));
        return Promise.resolve();
      },
    };
  };

  const checkFileSuffixAndSize = (file, option = {}) => {
    const { suffixes = [FILE_TYPE.JPG, FILE_TYPE.PNG], maxSize = MAX_UPLOAD_FILE_SIZE } = option;
    const { t, PREFIX } = useLanguage();
    if (!isEmpty(suffixes) && !suffixes.includes(file.type)) {
      const types = Object.keys(FILE_TYPE)
        .filter(key => suffixes.includes(FILE_TYPE[key]))
        .join('/');
      throw new ErrorMessage({ content: t('uploadFileType', PREFIX.ERROR, { types }) });
    }
    if (maxSize && file.size / 1024 / 1024 > maxSize)
      throw new ErrorMessage({ content: t('uploadFileSize', PREFIX.ERROR, { maxSize }) });
    return true;
  };

  const notBlankRule = () => {
    return {
      validator: (_, value) => {
        if (value && value.trim().length === 0) return Promise.reject(new Error(t('notBlank', PREFIX.ERROR)));
        return Promise.resolve();
      },
    };
  };

  return {
    isValid,
    rules,
    clearValidate,
    validate,
    isEmailRule,
    validateFields,
    requiredRule,
    minLengthRule,
    maxLengthRule,
    sameRule,
    notBlankRule,
    alphanumericalRule,
    decimalLengthRule,
    checkFileSuffixAndSize,
    validateSubmit,
  };
};
