<template>
  <ASwitch class="n-switch" />
</template>

<script setup>
import { Switch as ASwitch } from 'ant-design-vue/es';
</script>

<script>
export default {
  name: 'NSwitch',
};
</script>

<style lang="less">
.n-switch {
  width: 80px;
  height: 30px;

  .ant-switch-handle {
    top: 5px;
    left: 56px;
  }

  .ant-switch-inner {
    margin: 0 25px 0 30px;
  }

  &:not(.ant-switch-checked) .ant-switch-handle {
    left: 6px;
  }

  &.ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 10px;
  }
}
</style>
