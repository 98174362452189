export default {
  /* A */
  add: '添加',
  all: '全部',
  admin: '管理畫面',
  apply: '應用',
  agent: '代理',
  agents: '代理',
  appKey: '用戶名',
  avatar: '頭像',
  amount: '金額',
  action: '操作',
  agentId: '代理',
  approve: '通過',
  activate: '激活',
  autoSync: '自動同步',
  approval: '審批',
  approved: '已通過',
  allCache: '所有緩存',
  activated: '已激活',
  accountId: '交易系統用戶ID',
  appSecret: '密碼',
  agentName: '代理名稱',
  amountInfo: '金額信息',
  addressInfo: '地址信息',
  autoChannel: '自動',
  addressDetail: '地址',
  accountAmount: '賬戶金額',
  approvalStatus: '審核狀態',
  accountCurrency: '賬戶貨幣',
  alreadyLoggedIn: '已登錄',
  approvalProcess: '審批流程',
  accountActivate: '賬戶激活',
  accountActivated: '是否已激活',
  approveAndWithdraw: '通過並提現',
  alreadyHaveAccount: '已經有帳號?',
  appliedDate: '申請時間',
  approvalComment: '審核備註',
  // accountRiskLevel: '賬戶風險等級',
  allSelectedCount: '全部（{count}）',
  actualAccountAmount: '實際到賬金額',
  actualDepositAmount: '實際充值金額',
  accountDepositAmount: '交易賬戶充值金額',
  accountWithdrawalAmount: '交易賬戶提現金額',
  apiResultMsg: '支付信息',
  android: '安卓',
  androidLink: '安卓鏈接',
  accountInfo: '賬戶信息',
  activateRealAccount: '激活真實交易賬戶',

  /* B */
  back: '返回',
  bank: '銀行',
  branch: '分支',
  backTo: '返回登錄',
  bankNo: '銀行編號',
  backTop: '回到頂部',
  balance: '餘額',
  bankCard: '銀行卡',
  bordered: '邊框',
  basicInfo: '基本信息',
  batchSize: '數量',
  backToLogin: '返回',
  backToPrevious: '返回上級',
  backToPreviousStep: '上一步',
  backToRegister: '返回',
  bankBranch: '開戶支行名稱',
  bankAccount: '銀行賬戶',
  baseCurrency: '基礎貨幣',
  branchAddress: '分行地址',
  bankAccountName: '銀行賬戶名',
  bankNameOrNetwork: '銀行名稱 / 充值網路',
  bankCardInfo: '銀行卡信息',
  bankCardId: '銀行卡ID',

  /* C */
  csv: 'CSV',
  copy: '複製',
  check: '檢查',
  close: '關閉',
  cancel: '取消',
  create: '創建',
  confirm: '確認',
  checked: '已選中',
  comment: '備註',
  countryOrRegion: '國家 / 地區',
  created: '已創建',
  channel: '線路',
  closeAll: '全部關閉',
  customer: '客戶',
  copyLink: '複製鏈接',
  countries: '國家',
  createdBy: '創建者',
  childType: '子類型',
  cancelDate: '註銷日期',
  cardholder: '持卡人',
  customerId: '客戶ID',
  configName: '變數名',
  configDesc: '變數描述',
  cardDetail: '銀行卡',
  conversion: '轉換',
  changeAgent: '更換代理',
  cashflowKid: '交易系統流水ID',
  channelName: '渠道名稱',
  configValue: '變數值',
  createdDate: '創建時間',
  currencyType: '是否本外幣賬戶',
  channelError: '線路異常',
  customerInfo: '客戶信息',
  customerData: '客戶資料',
  currentCache: '當前緩存',
  closeAllTabs: '關閉所有標籤頁',
  createdBefore: '之前創建',
  cryptoNetwork: '充值網路',
  clearAllCache: '清空所有緩存',
  contactContent: '內容',
  forgotPassword: '忘記密碼',
  changePassword: '修改密碼',
  customerVerify: '客戶認證',
  closeOtherTabs: '關閉其他標籤頁',
  closeRightTabs: '關閉右側標籤頁',
  createAccounts: '創建賬戶',
  certificateNo: '證件號碼',
  confirmationMin: '最小確認次數',
  confirmPassword: '確認密碼',
  closeCurrentTab: '關閉當前標籤頁',
  chineseSimplified: '中文（简体）',
  changePwdSucceess: '密碼修改成功',
  customerFeeAmount: '顧客手續費',
  customerCurrencies: '客戶貨幣',
  customerDepositFlow: '充值流水',
  customerVerifyFlowId: '當前提審ID',
  customerIdentificationId: '身份ID',
  cancelTransaction: '取消轉賬',
  cancelTransactionPopupTitle: '此操作無法撤消，確定要放棄此筆轉賬?',
  captchaImageErrorMsg: '發生錯誤, 點我刷新',
  captchaText: '驗證碼',
  completed: '完成',
  confirmDetail: '確認明細',
  confirmProof: '確認憑證',
  confirmComplete: '確認完成',
  confirmRecvPopupTitle: '此操作無法撤銷，請確認您已經收到了這筆轉賬',
  confirmTransaction: '確認轉賬',
  confirmTransactionPopupTitle: '此操作無法撤消，確定已經完成了此筆轉賬?',
  currentChannelNotAvailableAndSetAsAuto: '當前線路不可用, 已切換為自動',

  /*D*/
  dob: '出生日期',
  desc: '簡介',
  detail: '詳情',
  details: '詳情',
  deposit: '充值',
  depositHistory: '儲值履歷',
  download: '下載',
  demoSystem: '虛擬系統',
  demoAppKey: '虛擬用戶名',
  description: '描述',
  demoAppSecret: '虛擬密碼',
  depositAmount: '充值金額',
  depositDetail: '充值 詳情',
  depositVerify: '充值審核',
  depositRemark: '轉賬留言',
  depositFlowId: '充值ID',
  depositCurrency: '存入貨幣',
  depositApproval: '充值審核',
  depositInfo: '充值信息',

  /* E */
  en: 'English',
  env: '環境',
  edit: '編輯',
  enum: '枚舉',
  empty: '空',
  enable: '啟用',
  enabled: '已啟用',
  existing: '存在',
  equalZero: '等於零',
  expandAll: '全部展開',
  emailDetail: '郵件',
  engFullName: '英文全称',
  engShortName: '英文簡稱',
  emailVerify: '郵箱認證',
  emailVerified: '郵箱認證',
  encryptedId: '上游商戶單號',
  enableVerification: '啟用認證',
  exclusiveAccountId: '專屬代理賬戶ID',
  expectedWithdrawalAmount: '預期到賬金額',

  /* F */
  fee: '手續費',
  force: '強制',
  fxRate: '匯率詳細',
  failure: '失敗',
  feeInfo: '手續費信息',
  flowInfo: '申請信息',
  fullName: '姓名',
  forceCancel: '強制取消',
  familyNameEn: '姓氏',
  forceConfirm: '強制收款',
  frozenAmount: '凍結金額',
  fxCurrencyPair: '外匯貨幣對',
  fxRateTemplate: 'FX匯率模板',
  fatcaSignedDate: 'FATCA簽名日期',
  fxRateTemplateId: 'FX匯率模板ID',
  forgotNoticeTitle1: '密碼找回鏈接已發送到郵箱',
  forgotNoticeTitle2: '請登錄郵箱找回！',
  forgotNoticeTitle3: '已登錄郵箱並點擊連接?',

  /* G */
  goRegister: '立即創建',
  gotoVerify: '去認證',
  goDeposit: '去充值',
  goLogin: '請到應用程式平台登入',
  giinTaxNo: 'GIIN稅號',
  guestForbidden: '禁止訪客登入',
  givenNameEn: '名字',
  greaterThan: '大於{number}',
  greaterThanZero: '大於零',
  gatewayLoginUsername: '網關登錄用戶名',
  gatewayFeeAmount: '網關估算手續費',

  /* H */
  hide: '隱藏',
  help: '幫助',
  hours: '小時',
  h5Link: 'H5 Trader 鏈接',
  hasRemainingAmount: '有剩餘金額',

  /* I */
  id: 'ID',
  it: '機構',
  itId: '機構',
  items: '條',
  itName: '機構名稱',
  inviter: '推薦人',
  identity: '身份',
  itReceive: '充值賬號',
  invalidUrl: '網址無效',
  inputAmount: '輸入金額',
  inputPaymentInfo: '收付款信息',
  inputPaymentMethod: '金額試算',
  itReceiveAccount: '機構收款賬戶',
  itReceiveId: '機構收款賬號ID',
  internetUser: '互聯網用戶',
  institutionId: '機構ID',
  identifyDetail: '身份認證',
  identification: '身份',
  identityVerify: '身份認證',
  itReceiveMethod: '機構收款方式',
  itReceiveNumber: '機構收款賬號',
  identificationId: ' 身份ID',
  identificationType: '證件類型',
  identificationInfo: '身份信息',
  identificationNumber: '證件號碼',
  identificationStep1Title: '個人信息',
  identificationStep1Name: '請輸入您的身份證上顯示的姓名（拼音）',
  identificationStep2OnlyTaxInCountry: '我只在上述國家納稅',
  identificationStep2SameBirthNationality: '我的出生地和國籍與上述國家相同',
  identificationStep2NonUSResident: '我不是美國公民，也不是美國納稅居民',
  identificationStep4Title: '投资知识',
  identificationStep4Desc:
    '我们需要评估你对金融产品的了解程度。如果你提供的答案不正确，可能会导致你的评估不及格。请仔细考虑你的答案。',
  identificationStep4KlMaxInvestment: '您已注资$3,000。杠杆率为1:100。（使用杠杆后）您可以交易的最大头寸价值是多少 ?',
  identificationStep4KlTrigger: '如果触发止损指令',
  identificationStep4KlAutoClose: '我的未清仓可能会在以下情况下自动关闭（例如追加保证金）',
  identificationStep5Title1: '適當性評估',
  identificationStep5Title2: '交易經驗',
  identificationStep5Desc:
    '我們需要評估你對金融產品的了解程度。如果你提供的答案不正確，可能會導致你的評估不及格。請仔細考慮你的答案。',
  identificationStep5AxTradingCount: '您在過去的一年有哪些交易經驗?（未獲得專業建議）股票、債權、商品',
  identificationStep5AxOtcCount: '場外交易衍生工具（杠桿CFDs、外匯、差價、點差交易）',
  identificationStep5AxOtcLeverage: '平均來說，用於交易上述OTC場外衍生品的杠桿是什麼?',
  identificationStep6Title: '專業經驗',
  identificationStep6ExpHasFinanceEducation:
    '您在過去3年中是否具有金融服務行業的在職教育、專業資質和/或工作經驗（擔任需要懂杠桿交易的工作）?',
  identificationStep6ExpFinanceEducation: '請提供更多關於您的教育背景、專業資質和/或金融服務行業的工作經驗信息',
  identificationStep7Title: '就業信息',
  identificationStep7Occupation: '您的就業情況如何？',
  identificationStep7Industry: '您從事什麼行業？',
  identificationStep8Title: '財務資料',
  identificationStep8finAnnualIncome: '年收入',
  identificationStep8finSavingsInvestment: '儲蓄和投資（不包括物業）',
  identificationStep8finIncomeSource: '交易資金的主要來源',
  identificationStep8maxInvestmentLoss: '每年您可承擔損失風險的用於交易大致金額',
  identificationStep9Title: '交易概況',
  identificationStep9TradingFrequency: '您預計多久交易一次？',
  identificationStep9DepositMethod: '您將如何為賬戶注資？',
  identificationStep9AcknowledgeTradingRisk:
    '我了解並確認，我在G.H.F交易的性質是買賣各種標的資產的差價合約產品。我明白查價格約並非適合所有人，只有像我這樣具有高風險承受能力的人才可進行交易。',
  identificationStep10Title: '條款和條件',
  identificationStep10ConsentAgreement: '我已詳細閱讀並接受G.H.F的使用協議',
  identificationStep10Agreement1: '訂單執行政策',
  identificationStep10Agreement2: '風險披露公告',
  identificationStep10Agreement3: '隱私政策',
  identificationStep10Agreement4: '關鍵信息文件',
  isExclusive: '專屬代理',
  ios: 'IOS',
  iosLink: 'IOS 鏈接',
  ifsc: 'IFSC鑰匙',

  /* J */
  jurisdiction: '管轄區',

  /* K */
  kv: '交易系統記錄版本',
  key: '鍵',

  /* L */
  list: '列表',
  lock: '鎖定',
  line1: '地址1',
  line2: '地址2',
  login: '登錄',
  logout: '退出',
  locked: '已鎖定',
  loginId: '登錄ID',
  language: '語言',
  lockTime: '鎖定時間',
  liveSystem: '實時系統',
  loginQuick: '快捷登录',
  loginUsername: '請輸入您的電子郵件',
  loginPassword: '請輸入您的登錄密碼',
  lastModifiedBy: '最後修改者',
  lastModifiedDate: '更新時間',
  lastAutoSyncTime: '自動同步時間',
  lookBackInterval: '回溯時長',
  localDepositAmount: '充值金額（本幣）',

  /* M */
  mark: '標記',
  more: '更多',
  mobile: '手機',
  minute: '分鐘',
  minutes: '分鐘',
  merchantName: '商戶名',
  middleNameEn: '中間名',
  mobileDetail: '手機',
  manualRefresh: '手動刷新',
  myReceiveAccount: '我的收款賬戶',
  myReceiveBank: '我的收款銀行',
  myReceiveNumber: '我的收款賬號',
  maxAutoConfirmAmount: '最大自動確認金額',
  minTransactionAmount: '最小單筆交易限額',
  maxTransactionAmount: '最大單筆交易限額',
  macX86Desktop: 'Mac(x86) 桌面應用',
  macX86DesktopLink: 'Mac(x86) 桌面應用鏈接',
  macArmDesktop: 'Mac(Arm) 桌面應用',
  macArmDesktopLink: 'Mac(Arm) 桌面應用鏈接',

  /* N */
  no: '否',
  name: '名字',
  number: '賬號',
  notEnabled: '未啟用',
  notifyLang: '通知語言',
  notNecessary: '非必要',
  networkUrls: '網路地址',
  newPassword: '新密碼',
  neverExpire: '長期有效',
  noDataFound: '暫無數據',
  notifyEmail: '通知郵箱',
  nextStep: '下一步',
  noAccount: '沒有賬號?',
  noTransactionHint: '暫無轉賬記錄, 請耐心等待',
  noAvailableChannel: '暫無可用線路, 請稍後重試',

  /* O */
  on: '開啟',
  or: '或者',
  off: '關閉',
  operator: '操作員',
  operation: '操作',
  oldPassword: '舊密碼',
  openRealAccount: '開戶真實交易賬戶',
  openVirtualAccount: '開戶虛擬交易賬戶並充值',

  /* P */
  px: '像素',
  proof: '憑證',
  people: '人',
  profile: '用戶',
  process: '審核',
  payType: '支付類型',
  pageSize: '每頁條數',
  province: '省份',
  password: '密碼',
  postCode: '郵編',
  processed: '已處理',
  processes: '處理',
  profession: '職業',
  plzRelogin: '請重新登錄',
  phoneDetail: '固定電話',
  parentAgent: '父代理',
  preconditions: '前提條件',
  paymentChannel: '支付渠道',
  paymentGateway: '網關',
  paymentRouting: '支付渠道路由',
  processComplete: '完成處理',
  paymentRoutingId: '支付渠道路由ID',
  passwordResetNoticeTitle: '修改密碼成功',
  passwordResetFailTitle: '跳轉到',
  paymentCurrency: '付款貨幣',
  pleaseChooseItReceiveAccount: '收款方式',
  pleaseChoosePayment: '付款方式',
  pleaseSignIn: '請輸入用戶名和密碼',
  pleaseSignUp: '請輸入要註冊的用戶名和密碼',
  pleaseVerifySoon: '請儘快完成認證',
  previousStep: '上一步',
  planDepositAmount: '預定充值金額',
  paymentFeeModel: '上游手續費模式',
  paymentFeeValue: '上游手續費設定值',
  paymentFeeCurrency: '上游手續費貨幣',
  plzReloginWithNewPwd: '請使用新密碼重新登錄',
  paymentFeeInfo: '上游手續費信息',
  paymentOrderStatus: '訂單狀態',
  providerType: '註冊類型',
  pleaseSelectApprovalStatus: '請選擇審核狀態',
  paymentFeeAmount: '支付端估算手續費',
  processing: '處理中',
  pixType: 'PIX 類型',
  pixKey: 'PIX 密鑰',
  payPurpose: '支付用途',

  /* Q */
  qrCode: '二維碼',
  quoteCurrency: '報價貨幣',

  /* R */
  rate: '匯率',
  role: '角色',
  roles: '角色',
  reset: '重置',
  region: '地區',
  result: '結果',
  roleId: '角色',
  remove: '刪除',
  reject: '駁回',
  reason: '原因',
  receive: '收款',
  refresh: '刷新',
  retract: '撤回',
  regDate: '註冊日期',
  register: '註冊',
  receiver: '接收者',
  resource: '資源',
  retryMail: '重新發送',
  receiverId: '接收者ID',
  refreshAll: '刷新全部',
  regCustomer: '賬戶註冊',
  rateDetails: '匯率詳細',
  receiverName: '收款人姓名',
  receiverRole: '接收者角色',
  receiveMethod: '收款方式',
  receiveNumber: '賬號',
  resetPassword: '重置密碼',
  receiverNumber: '收款人賬號',
  receiverNumberCpf:'收款人賬號',
  receiverNumberPhone: '電話號碼',
  receiverNumberEmail: '信箱',
  receiverNumberEvp: '虛擬支付地址',
  receiverAccount: '收款賬戶',
  receiveCurrency: '收款貨幣',
  receiverNetwork: '充值網絡',
  remainingAmount: '剩餘金額',
  recoverPassword: '密碼找回',
  receivingCurrency: '收款貨幣',
  registerNoAgentAlert: '請聯絡代理商提供註冊連結',
  registerNoticeTitle: '請到郵箱激活賬號后',
  registerVerifyTitle1: '註冊成功!',
  registerVerifyTitle2: '請重新',
  resendActiveMail: '發送郵箱認證郵件',
  recoverPasswordEnter: '請輸入要找回密碼的郵箱',
  restoreDefaultSettings: '恢復默認設置',
  receiverNumberOrAddress: '收款人賬號 / 地址',
  registerOtherWay: '或使用以下方式註冊',
  registerAgreementTitle1: '我已閱讀並同意',
  registerAgreementTitle2: '用戶協議',
  registerPleaseAgree: '請同意用戶協議',
  receiverExtConfig: '收款人信息擴展配置',
  relatedId: '關聯單號',

  /* S */
  sex: '性別',
  show: '顯示',
  step: '步驟',
  sync: '同步',
  send: '發送',
  synced: '已同步',
  source: '來源',
  status: '狀態',
  search: '查詢',
  sender: '發起者',
  social: '社交',
  signUp: '註冊',
  safety: '安全',
  stopHinting: '不再提示',
  submit: '提交',
  setting: '設置',
  success: '成功',
  settings: '設置',
  senderId: '發起者ID',
  secretKey: '密鑰',
  selectAll: '全部',
  superUser: '超級用戶',
  shortName: '簡稱',
  searchKey: '關鍵字',
  shareCode: '分享碼',
  subAgents: '子代理',
  syncAgent: '同步代理',
  senderRole: '發起者角色',
  signedDate: '簽約日期',
  signedStatus: '簽約狀態',
  socialDetail: '社交',
  successMin: '最小成功個數',
  systemConfig: '系統變數',
  selectedCount: '已選擇（{count}）',
  systemFeeModel: '系統手續費模式',
  systemFeeValue: '系統手續費設定值',
  syncInstitution: '同步機構',
  settlementCurrency: '結算貨幣',
  statusInfo: '狀態信息',
  sourceAgentId: '源代理',
  senderExtConfig: '付款人信息擴展配置',

  /* T */
  tw: '中文（繁體）',
  tabs: '標籤頁',
  type: '類型',
  table: '列表',
  token: '令牌',
  total: '合計',
  target: '目標',
  taxNo: '稅號',
  txHash: '交易 Hash',
  toRefId: '客戶收款賬號ID',
  tabLimit: '標籤頁限制',
  transfer: '轉賬',
  tinTaxNo: '稅務識別號',
  toRefBank: '客戶收款銀行',
  testNetwork: '測試網路',
  tax: '稅務識別',
  taxDetail: '稅務識別',
  taxFormType: '稅務表格類型',
  taxFormDate: '稅務表格日期',
  taxNoExpireDate: '稅務識別號過期日期',
  transaction: '交易',
  transactionProof: '轉賬憑證',
  tronScanUrl: 'Tron Scan 網址',
  toRefNumber: '客戶收款賬號',
  timedRefresh: '定時刷新',
  tokenExpired: '登錄過期',
  targetAgentId: '目標代理',
  transferAmount: '轉賬金額',
  transferFromIt: '從機構轉賬',
  tradingPassword: '交易密碼',
  transferConfirm: '收款',
  transferDetails: '轉賬明細',
  transactionModel: '支付模式',
  transactedAmount: '已提現金額',
  transactionCategory: '付款方式',
  transferNotifyTimeLimit: '轉賬通知時限',

  /* U */
  userId: '用戶ID',
  upload: '上傳',
  unitCny: '元',
  username: '用戶名',
  unlocked: '未鎖定',
  unverify: '未認證',
  unchecked: '未選中',
  unprocess: '未認證',
  uncreated: '未創建',
  unapprove: '未通過',
  usCitizen: '是否美國公民',
  usResident: '是否美國居民',
  usTaxPayers: '是否美國納稅人',
  usdFeeAmount: '系統手續費',
  usBeneficiary: '是否美國受益人',
  usCompany: '是否美國公司',
  unprocessed: '未處理',
  unactivated: '未激活',
  uploadImage: '上傳圖片',
  uploadProof: '上傳憑證',
  uploadLimitation: '{types} 最大{maxSize}M',
  upstreamPaymentChannel: '上游渠道',
  unsupportedBrowserTitle: '不支持此瀏覽器',
  unsupportedBrowserContent1: '請升級到最新瀏覽器.',
  unsupportedBrowserContent2: '（建議: Chrome 90+, Safari 14+, Firefox 80+）',

  /* V */
  value: '值',
  verify: '認證',
  verify2: '審批',
  version: '版本',
  validUrl: '網址有效',
  verified: '已認證',
  verifyId: '認證ID',
  verifyInfo: '審核信息',
  verifyName: '審核姓名',
  verifyApproval: '審核認證',
  visibilityHeight: '可見高度',

  /* W */
  welcome: '歡迎您',
  withdraw: '提現',
  withdrawHistory: '提現履歷',
  workflow: '工作流程',
  withdrawal: '提現',
  waiverType: '豁免類型',
  withdrawalVerify: '提現審批',
  withdrawalAmount: '提現金額',
  withdrawApproval: '提現審核',
  withdrawalDetail: '提現 詳情',
  withdrawalFlowId: '提現ID',
  withdrawalAmountLocal: '提現金額（本幣）',
  withdrawalInfo: '提現信息',
  windowsDesktop: 'Windows 桌面應用',
  windowsDesktopLink: 'Windows 桌面應用鏈接',
  web: 'Web Trader',
  webLink: 'Web Trader 鏈接',

  /* X */

  /* Y */
  yes: '是',
  youWillTransferForAboveAccountsSeparately: '您將分別為以上賬戶轉賬',

  /* Z */
  cn: '中文（简体）',

  /* sender info */
  receiverInfo: '收款人資訊',
  surName: '姓',
  givName: '名',
  middleName: '中間名',
  otherName: '其他語言名稱',
  address: '地址',
  area: '手機區號',
  phone: '電話號碼',
  nationality: '國籍',
  idType: '證件類型',
  idNumber: '證件號碼',
  locationId: '位置ID',
  bankId: '銀行分行編號',
  bankName: '銀行分行名稱',
  bankBranchName: '分行名稱',
  bankBranchCode: '分行代碼',
  bankBranchId: '分行ID',
  accountNo: '銀行卡號',
  occupation: '職業',
  bankMobile: '銀行預留手機號碼',
  issueDate: '證件簽發日期',
  expireDate: '證件到期日',
  licIssuAutho: '證件發證機關',
  remark: '備註',
  accountType: '帳戶類型',
  city: '城市',
  states: '州',
  zipCode: '郵編',
  country: '國家',
  email: '信箱',
  accountNumber: '帳戶編號',
  phoneCountry: '手機號碼所屬國家',
  accountCity: '帳號所屬城市',
  accountCountry: '帳號所屬國家',
  currency: '幣種',
  userName: '使用者名稱',
  birthday: '出生日期',
  postalCode: '郵編',
  provinceState: '州/省份',
  cardNumber: '卡編號',
  nativeFirstName: '原姓',
  nativeLastName: '原名',
  remitterType: '付款類型',
  ibanNo: '國際銀行帳號號碼',
  accountAddress: '帳戶地址',
  routingType: '路由類型',
  senderBusiness: '付款公司資訊',
  registeredName: '公司註冊名稱',
  tradingName: '公司名稱',
  msisdn: '公司聯絡電話',
  registrationNumber: '公司註冊編碼',
  dateOfIncorporation: '公司註冊日期',
  purposeOfRemittance: '匯款目的',
  documentReferenceNumber: '文件參考編號',
  taxId: '稅號',
  sourceOfFund: '資金來源',
  countryCode: '國家代碼',
  transactionType: '交易模式',
  channelCode: '渠道編號',
  beneficiaryRelationShip: '匯款關係',
  gender: '性別',
  otherDownloads: '其他下載方式',
  verifyNotice1: '郵箱驗證成功！',
  verifyNotice2:
    '感謝您註冊我們的產品---GHF。現在您可以使用該帳號登入登入介面。如果您有任何疑問，請隨時聯絡我們的客戶服務團隊：https://wa.me/13234169593',
  verifyNotice3: '祝您用好 ！',
    depositNotice1: '提示:',
    depositNotice2: '最低投資額為',
    depositNotice3: 'R$300.',
    depositNotice4: '如有任何疑問請聯絡客服 WhatsApp:+5519995357056',
};
