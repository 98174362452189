<template>
  <NViewContainer class="n-pdf-viewer">
    <NNavBar :title="title" left-text="返回" left-arrow @clickLeft="onBtnBack()" />

    <div class="mx-4 !my-6">
      <NSlider v-model="scale" class="" :min="0.2" :step="0.2" :max="3" />
    </div>

    <NPagination v-if="pages > 0" v-model="currentPage" :total-items="pages" :items-per-page="5" />

  </NViewContainer>
</template>

<script setup>
import { ref } from 'vue';
// import { VuePDF, usePDF } from '@tato30/vue-pdf'

const emit = defineEmits(['back']);

const props = defineProps({
  filename: { type: String },
  title: { type: String, default: 'PDF' },
});


const currentPage = ref(1);

const scale = ref(1);

function onBtnBack() {
  emit('back');
}
</script>

<script>
export default {
  name: 'NPdfViewer',
};
</script>

<style lang="less">
.n-pdf-viewer {
  background-color: var(--default-bg-color);
  @apply flex flex-col justify-start z-10 fixed inset-0 !m-0;
}
</style>
