export class SentryMessage {
  constructor({ message, level = 'info', extra }) {
    this.message = message;
    this.level = level;
    this.extra = extra;
  }
}

export class DebugSentryMessage extends SentryMessage {
  constructor(message, extra) {
    super({ message, level: 'debug', extra });
  }
}

export class InfoSentryMessage extends SentryMessage {
  constructor(message, extra) {
    super({ message, level: 'info', extra });
  }
}

export class WarnSentryMessage extends SentryMessage {
  constructor(message, extra) {
    super({ message, level: 'warning', extra });
  }
}

export class ErrorSentryMessage extends SentryMessage {
  constructor(message, extra) {
    super({ message, level: 'error', extra });
  }
}

export class CriticalSentryMessage extends SentryMessage {
  constructor(message, extra) {
    super({ message, level: 'critical', extra });
  }
}

export class FatalSentryMessage extends SentryMessage {
  constructor(message, extra) {
    super({ message, level: 'fatal', extra });
  }
}
