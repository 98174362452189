<template>
  <AFormItem class="n-form-item" v-bind="formItemAttrs">
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </AFormItem>
</template>

<script setup>
import { useInjectModalData } from '@/hooks';
import { inject, useAttrs, computed } from 'vue';
import { FormItem as AFormItem } from 'ant-design-vue/es';

const props = defineProps({
  labelCol: Object,
  span2: Boolean,
});

const form = inject('NForm', null);

const attrs = useAttrs();

const modalData = useInjectModalData();

const fullSpan = computed(() => (modalData ? 22 : 24));

const formLabelColSpan = computed(() => {
  const v = form?.props?.labelCol?.span;
  if (v % 2) throw 'labelCol span must be even';
  return v;
});

const labelColSpan = computed(() => {
  if (!attrs.label) return undefined;
  return {
    xs: { span: formLabelColSpan.value },
    sm: { span: props.span2 ? formLabelColSpan.value / 2 : formLabelColSpan.value },
  };
});

const wrapperColSpan = computed(() => {
  if (!attrs.label) return undefined;
  const span = fullSpan.value - formLabelColSpan.value;
  return {
    sm: { span },
    md: { span: props.span2 ? fullSpan.value + 1 - formLabelColSpan.value / 2 : span },
  };
});

const formItemAttrs = computed(() => ({
  colon: false,
  labelAlign: 'right',
  labelCol: labelColSpan.value,
  wrapperCol: wrapperColSpan.value,
  class: `${attrs.class ? attrs.class : ''} ${props.span2 ? 'md:col-span-2' : ''}`,
}));
</script>

<style lang="less">
.n-form-item {
  .ant-form-item-label {
    display: inline-block;

    &.ant-col-md-8 {
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }

    &.ant-col-md-10 {
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }

    &.ant-col-md-12 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &.md\:col-span-2 {
    .ant-form-item-label {
      &.ant-col-md-4 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
      }

      &.ant-col-md-5 {
        flex: 0 0 20.83333333%;
        max-width: 20.83333333%;
      }

      &.ant-col-md-6 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }

  .ant-form-item-control {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (max-width: 767px) {
  .ant-form-item {
    display: block;

    .ant-form-item-label {
      display: block;
      max-width: 100% !important;
      padding: 0 0 8px;
      text-align: left;
    }

    .ant-form-item-control {
      display: block;
      max-width: 100%;
    }
  }
}

.n-form.vertical {
  .ant-form-item-label {
    display: block !important;
    max-width: 100% !important;
    text-align: left !important;
  }

  .ant-form-item-control {
    display: block !important;
    max-width: 100% !important;
  }
}
</style>
